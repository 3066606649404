<template>
  <div>
    <vk-modal v-if="isGoals" class="modal" :show.sync="showIndividualGoals">
      <div class="modal-header">
        <h2>Metrics/Performance<span v-if="isEos"> for {{ periodLetter }}{{ selectedPeriod + 1 }}</span></h2>
        <vk-modal-close @click="() => { showIndividualGoals = false }"></vk-modal-close>
      </div>
      <div class="modal-body">
        <div v-for="(goal, index) in goals" :key="`goal-index-${index}`">
          <h4 class="goal-title">{{ goal.task }}</h4>
          <table class="goal-table">
            <thead>
            <tr>
              <th>Performance Scale</th>
              <th>Weight</th>
              <th>Final</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><div v-html="goal.notes"></div></td>
              <td>{{ goal.weight | percent(2) }}</td>
              <td>
                <template v-if="goal.achievement">{{ goal.achievement | percent(2) }}</template>
                <template v-else>TBD</template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <table class="modal-footer">
        <tbody>
          <tr>
            <td><h3>Performance Total</h3></td>
            <td>{{ individual.performance.goals.weight_total | percent(2) }}</td>
            <td>
              <template v-if="!individual.performance.goals.tbd">
                {{ individual.performance.goals.performance_total | percent(2) }}
              </template>
              <template v-else>TBD</template>
            </td>
          </tr>
        </tbody>
      </table>
    </vk-modal>

    <vk-modal class="modal" v-if="hasOrganizationUnits" :show.sync="showUnitDetails">
      <div class="modal-header">
        <h2>Metrics/Performance</h2>
        <vk-modal-close @click="() => { showUnitDetails = false; selectedUnit = null }"></vk-modal-close>
      </div>
      <div class="modal-body">
        <template v-if="selectedUnit">
          <template v-if="selectedUnit.mode_category === 'metrics'">
            <OrgUnitRating :unit="selectedUnit" :organization="organization.types[selectedUnit.type_id]"/>
          </template>
          <template v-else-if="selectedUnit.mode_category === 'goals'">
            <OrgUnitGoals :unit="selectedUnit" :organization="organization.types[selectedUnit.type_id]"/>
          </template>
        </template>
      </div>
      <div class="modal-footer">
      </div>
    </vk-modal>

    <section class="plan-metrics">
      <div class="metrics-header">
        <h3>
          <QuestionBadge :id="'pendo-statement-metrics-performance'">METRICS/PERFORMANCE</QuestionBadge>
        </h3>
      </div>
      <plan-card>
        <div class="metrics-tabs">
          <div v-if="hasOrganizationUnits">
            <button class="metrics-tab" @click="activeTab = 'org'"
                    :class="[activeTab === 'org' ? 'active-tab' : '']">Organization
            </button>
          </div>
          <div v-if="isGoals || isRating">
            <button class="metrics-tab" @click="activeTab = 'ind'"
                    :class="[activeTab === 'ind' ? 'active-tab' : '']">Individual
              <template v-if="isRating">Rating</template>
              <template v-else>Goals</template>
            </button>
            <button v-if="isGoals" class="view-details-link"
                    @click="() => { showIndividualGoals = !showIndividualGoals }">View Details
            </button>
          </div>
        </div>
        <div class="period-tabs grid" :class="[{'grid-cols-2': payoutCount === 2}, {'grid-cols-3': payoutCount === 3}, {'grid-cols-4': payoutCount === 4}]" v-if="isEos">
          <template v-for="(distribution, index) in distributions">
            <button :key="distribution.employee_id" class="period-tab grid__item" :class="{'selected': selectedPeriod === index}" @click="selectedPeriod = index">{{ periodLetter }}{{ index + 1 }}</button>
          </template>
        </div>
        <div class="metrics-content">
          <template v-if="hasOrganizationUnits && activeTab === 'org'">
            <template v-if="isEos">
              <template v-for="(unitPeriods, index) in Object.values(organization?.units)">
                <div class="org-unit-row" :key="`org-metric-${index}`">
                  <span>{{ unitPeriods[selectedPeriod].label }}</span>
                  <button class="view-details-link" @click="handleUnitDetails(unitPeriods[selectedPeriod])">View Details</button>
                </div>
              </template>
            </template>
            <template v-else>
              <template v-for="(unit, index) in Object.values(organization?.units)">
                <div class="org-unit-row" :key="`org-metric-${index}`">
                  <span>{{ unit.label }}</span>
                  <button class="view-details-link" @click="handleUnitDetails(unit)">View Details</button>
                </div>
              </template>
            </template>
          </template>

          <template v-if="activeTab === 'ind'">
            <div v-if="isGoals" class="goal-table">
              <template v-for="(item, index) in goals">
                <div :key="`ind-metric-${index}`" class="goal-table-row">
                  <span>{{ item.task }}</span><span><b>Weight:</b> {{ item.weight | percent }}</span>
                </div>
              </template>
              <div class="goal-table-footer">
                <b>Total</b><span>{{ individual.performance.goals.weight_total | percent }}</span>
              </div>
            </div>

            <div>
              <div v-if="isRating" class="rating-header">
                <div></div>
                <div>Rating Scale</div>
                <div>%&nbsp;of Award&nbsp;Earned</div>
              </div>
              <div v-if="isRating" class="rating-table">
                <div class="rating-background">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>

                <div class="rating-rows">
                  <template v-for="(rating, index) in individual.performance.rating.scale">
                    <div :key="`current-${index}`">
                      <template v-if="rating.achieved">
                        <span>Current</span>
                        <svg width="39" height="12" viewBox="0 0 39 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path d="M39 6L29 0.2265L29 11.7735L39 6ZM-8.2075e-08 7L30 7L30 5L8.2075e-08 5L-8.2075e-08 7Z" fill="#1F3A4D"/>
                        </svg>
                      </template>
                    </div>
                    <div :key="`green-${index}`">{{ rating.label }}</div>
                    <div :key="`values-${index}`"> = {{ rating.value | percent }}</div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </plan-card>
    </section>
  </div>
</template>

<script>
import PlanCard from "@/components/plans/global/PlanCard.vue"
import OrgUnitRating from "@/components/plans/global/OrgUnitRating.vue"
import OrgUnitGoals from "@/components/plans/global/OrgUnitGoals.vue"
import QuestionBadge from "@/components/pendo/QuestionBadge.vue";

export default {
  name: "PlanDetails",

  components: {
    QuestionBadge,
    PlanCard,
    OrgUnitRating,
    OrgUnitGoals,
  },

  props: {
    individual: {
      type: [Object, null],
      default: function () {
        return null
      }
    },
    organization: {
      type: [Object, null],
      default: function () {
        return null
      }
    },
    plan: {
      type: Object,
      required: false,
    },
    distributions: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      activeTab: 'ind',
      selectedPeriod: null,
      showIndividualGoals: false,
      showUnitDetails: false,
      selectedUnit: null,
    }
  },

  computed: {
    isEos() {
      return !!this?.plan?.isEos;
    },
    payoutCount() {
      return this.plan?.holdback.payout_count
    },
    periodLetter() {
      if(this.payoutCount === 2) return 'H'
      else if(this.payoutCount === 3) return 'T'
      else return 'Q'
    },
    goals() {
      if(this.isEos) {
        if(this.selectedPeriod) {
          return this.individual.performance.goals.list[this.selectedPeriod]
        }
        else {
          return this.individual.performance.goals.list[0]
        }
      }
      else return this.individual.performance.goals.list
    },
    isGoals() {
      return this.individual.performance.goals !== null && typeof this.individual.performance.goals !== 'undefined'
    },
    isRating() {
      return this.individual.performance.rating !== null && typeof this.individual.performance.rating !== 'undefined'
    },
    hasOrganizationUnits() {
      return this.organization?.units && Object.values(this.organization?.units).length
    },
  },

  methods: {
    handleUnitDetails(unit) {
      this.selectedUnit = unit
      this.showUnitDetails = true
    }
  },

  mounted() {
    if(this.distributions) this.selectedPeriod = this.distributions.length - 1;
  },

}
</script>

<style lang="scss" scoped>
@import "../../../../scss/_variables.scss";

button {
  all: unset;
  cursor: pointer;
}

.plan-metrics {

  .metrics-header {
    display: flex;
    justify-content: space-between;

    h3 {
      font-weight: bold;
      font-size: $text-md;
    }
  }

  .period-tabs {
    height: 50px;
    color: $charcoal-tint-50;

    border-top: thin solid $charcoal-tint-20;

    .period-tab {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .selected {
      font-weight: 700;
      color: $charcoal;
    }
  }

  .metrics-tabs {
    display: flex;

    height: 50px;
    width: 100%;

    color: $charcoal-tint-50;

    & > div {
      width: 100%;
      display: flex;

      padding: 0 1.25em;

      &:nth-child(2) {
        border-left: thin solid $charcoal-tint-20;
      }

      .view-details-link {
        padding-left: 0.5em;

        font-size: $text-sm;
        text-align: right;

        color: $emerald;

        &:hover {
          color: $emerald-tint-80;
        }
      }

      .metrics-tab {
        font-weight: 800;
        font-family: $font-secondary;
      }
    }

    .active-tab {
      color: $charcoal;
    }
  }

  .metrics-content {
    font-size: $text-sm;

    b {
      font-weight: 800;
      font-family: $font-secondary;
    }

    .org-unit-row,
    .goal-table .goal-table-row,
    .goal-table .goal-table-footer,
    {
      display: flex;
      justify-content: space-between;
      align-items: center;

      height: 50px;
      padding: 0 1.25em;

      border-top: thin solid $charcoal-tint-20;
    }

    .rating-header {
      border-top: thin solid $charcoal-tint-20;

      display: grid;
      grid-template-columns: repeat(3, 1fr);

      column-gap: 1em;

      padding: 0.5em 0.5em 0 0.5em;

      div {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-align: center;

        font-weight: 800;
        font-family: $font-secondary;
      }
    }

    .rating-table {
      position: relative;

      padding: 0.5em 0;

      .rating-background {
        position: absolute;
        z-index: 10;

        top: 0;
        left: 0;

        padding: 0.5em 0;

        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        column-gap: 1em;

        & > div {
          width: 100%;
          height: 100%;

          &:nth-child(2) {
            border-radius: 4px;
            background: linear-gradient(0deg, var(--brand-color-tint) 0%, var(--brand-color) 100%);
          }
        }
      }

      .rating-rows {
        position: relative;
        z-index: 50;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        column-gap: 1em;

        & > div {
          display: flex;
          justify-content: center;
          align-items: center;

          height: 2.8rem;

          &:nth-child(3n - 1) {
            border-top: 2px solid white;

            color: var(--foreground-color);
            font-weight: bold;
          }

          &:nth-child(2) {
            border-top: none;
          }

          &:nth-child(3n) {
            font-weight: bold;
            justify-content: center;
          }

          &:first-child {
            justify-content: flex-end;
          }

          &:nth-child(3n - 2) span {
            margin-right: 0.1em;
            color: var(--highlight-color);
            font-weight: bold;
          }

          &:nth-child(3n - 2) svg {
            height: 0.5rem;
          }
        }
      }
    }
  }

  .grid {
    --gap: 2rem;
    --line-offset: calc(var(--gap) / 2);
    --line-thickness: 1px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    overflow: hidden;
    gap: var(--gap);
  }

  .grid__item {
    position: relative;
  }

  .grid__item::before {
    content: '';
    position: absolute;
    background-color: $charcoal-tint-20;
    z-index: 1;
  }

  .grid__item::before {
    inline-size: var(--line-thickness);
    block-size: 100vh;
    inset-inline-start: calc(var(--line-offset) * -1);
  }

  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.modal {
  z-index: 50000;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5em;

    h2 {
      font-weight: bold;
      font-size: 2rem;

      margin: 0;
    }
  }

  .modal-body {
    width: 100%;

    .goal-title {
      font-size: $text-base;
      font-weight: bold;

      margin: 0;
    }

    .goal-table {
      table-layout: fixed;
      width: 100%;
      border-collapse: collapse;

      margin-bottom: 1.5em;

      thead {
        th {
          border: 1px solid $charcoal-tint-20;

          font-size: $text-base;
          font-weight: bold;
          color: $charcoal-tint-50;

          &:nth-child(1) {
            width: 42%;
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 29%;
          }
        }
      }

      td {
        border: 1px solid $charcoal-tint-20;
        text-align: center;

        padding: 0.5em 1em;

        &:first-child {
          text-align: right;
          padding: 0.5em 2.5em;
        }

        &:nth-child(2),
        &:nth-child(3) {
          font-size: $text-md;
          font-weight: bold;

          color: var(--highlight-color);
        }
      }

      th:first-of-type {
        border-top-left-radius: 5px;
      }

      th:last-of-type {
        border-top-right-radius: 5px;
      }

      tr:last-of-type td:first-of-type {
        border-bottom-left-radius: 5px;
      }

      tr:last-of-type td:last-of-type {
        border-bottom-right-radius: 5px;
      }
    }
  }

  .modal-footer {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: none;

    tbody {
      td {
        &:nth-child(1) {
          width: 42%;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 29%;

          text-align: center;

          font-size: $text-md;
          font-weight: bold;

          color: var(--highlight-color);
        }
      }

      h3 {
        font-size: $text-lg;
        font-weight: bold;
        margin: 0;
      }
    }
  }
}
</style>