export default {
    methods: {
        exists(value) {
            return value !== null && typeof value !== 'undefined'
        },
        RGBToHex(r, g, b) {
            let hex_r = Math.round(r).toString(16).padStart(2, '0')
            let hex_g = Math.round(g).toString(16).padStart(2, '0')
            let hex_b = Math.round(b).toString(16).padStart(2, '0')

            return `#${hex_r}${hex_g}${hex_b}`
        },
        RGBTint(r, g, b, t) {
            let r_tint = r + (t * (255 - r))
            let g_tint = g + (t * (255 - g))
            let b_tint = b + (t * (255 - b))

            return { r: r_tint, g: g_tint, b: b_tint }
        },
        RGBShade(r, g, b, s) {
            let r_shade = r * s;
            let g_shade = g * s;
            let b_shade = b * s;

            return { r: r_shade, g: g_shade, b: b_shade }
        },
    },
    computed: {
        clientIsDiy() {
            return this.$dataDirectory.userData.client_status === 'diy' || this.$dataDirectory.userData.client_status === 'DIY';
        }
    }
}
