<template>
  <div class="ltip-chart">
    <div class="header">
      <h3>Forecasted Total Plan Value*</h3>
<!--      <div v-if="!clientIsDiy" class="legend-container">-->
<!--        <div class="legend-wrap"><div class="legend-base" :style="`background-color:${clientColors.headerShade};`"></div>Base</div>-->
<!--        <div class="legend-wrap"><div class="legend-target" :style="`background-color:${clientColors.header};`"></div>Target</div>-->
<!--        <div class="legend-wrap"><div class="legend-superior" :style="`background-color:${clientColors.headerTint};`"></div>Superior</div>-->
<!--      </div>-->
    </div>
    <div v-if="userDataLoaded" class="body">
      <GChart class="chart" type="LineChart" :data="chartData" :options="chartOptions"/>
    </div>
    <div class="footer"><p>*Total Plan Value projections are based on a hypothetical forecast of future financial performance. This projection is not intended to provide guaranteed predictions of the future payment value of awards.</p></div>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'
import UtilsMixin from "@/mixins/UtilsMixin";

export default {
  name: "LongTermLineChart",

  mixins: [ UtilsMixin ],

  components: {
    GChart,
  },

  props: {
    onlyTarget: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      clientColors: {
        header: null,
        headerTint: null,
        headerShade: null,
      },

      chartData: [],

      chartOptions: {
        backgroundColor: {fill: 'transparent'},
        chartArea: {right: 26, width: `84%`, height: '90%'},

        interpolateNulls: true,

        legend: {position: 'none'},

        lineWidth: 20,

        colors: ['#071820'],

        allowContainerBoundaryTextCutoff: true,

        series: {},

        hAxis: {
          ticks: [],
          gridlines: {color: 'transparent'},
          textStyle: { color: '#1F3A4D', fontName: 'Lexend Deca', fontSize: 15, bold: true },
          textPosition: 'out',
          minTextLines: '2', slantedText: false,
        },

        vAxis: {
          // ticks: [],
          viewWindow: {
            min: null,
            max: null,
          },
          baselineColor: 'transparent',
          format: '$#,###',
          gridlines: { color: '#cdd1d2' },
          textStyle: { color: '#1F3A4D', fontName: 'Lexend Deca', fontSize: 15, bold: true },
          textPosition: 'out',
        },
      },
    }
  },

  computed: {
    userDataLoaded() {
      return this.$dataDirectory?.userDataLoaded
    },
    clientStyles() {
      if (this.userDataLoaded && this.$dataDirectory?.userData?.client_styles) return JSON.parse(this.$dataDirectory.userData.client_styles)
      else return null
    },
  },

  methods: {
    buildChartOptions() {
      let current_history = this.history
      if(current_history.length > 10) current_history = current_history.slice((current_history.length - 10), current_history.length)

      let min = current_history[0].target
      let max = current_history[0].target

      let v_ticks = new Set()
      let h_ticks = new Set()

      current_history.forEach((item) => {
        if (item.target < min) min = item.target
        if (max < item.target) max = item.target

        h_ticks.add(item.year)
      })

      // Series
      this.chartOptions.series[0] = {targetAxisIndex: 0, lineDashStyle: [6, 4], pointSize: 0, lineWidth: 2}
      this.chartOptions.series[1] = {targetAxisIndex: 0, pointSize: 12, lineWidth: 0, color: this.clientColors.header}

      // if(this.clientIsDiy) {
      //   this.history.forEach((item) => {
      //     if (item.target < min) min = item.target
      //     if (max < item.target) max = item.target
      //
      //     h_ticks.add(item.year)
      //   })
      //
      //   // Series
      //   this.chartOptions.series[0] = {targetAxisIndex: 0, lineDashStyle: [6, 4], pointSize: 0, lineWidth: 2}
      //   this.chartOptions.series[1] = {targetAxisIndex: 0, pointSize: 12, lineWidth: 0, color: this.clientColors.header}
      // }
      // else {
      //   this.history.forEach((item) => {
      //     if (item.base < min) min = item.base
      //     if (max < item.superior) max = item.superior
      //
      //     v_ticks.add(item.base)
      //     v_ticks.add(item.target)
      //     v_ticks.add(item.superior)
      //     h_ticks.add(item.year)
      //   })
      //
      //   // Series
      //   this.chartOptions.series[0] = {targetAxisIndex: 0, lineDashStyle: [6, 6], pointSize: 0, lineWidth: 2}
      //   this.chartOptions.series[1] = {targetAxisIndex: 0, pointSize: 10, lineWidth: 0, color: this.clientColors.headerShade}
      //   this.chartOptions.series[2] = {targetAxisIndex: 0, lineDashStyle: [6, 4], pointSize: 0, lineWidth: 2}
      //   this.chartOptions.series[3] = {targetAxisIndex: 0, pointSize: 10, lineWidth: 0, color: this.clientColors.header}
      //   this.chartOptions.series[4] = {targetAxisIndex: 0, lineDashStyle: [6, 2], pointSize: 0, lineWidth: 2}
      //   this.chartOptions.series[5] = {targetAxisIndex: 0, pointSize: 10, lineWidth: 0, color: this.clientColors.headerTint}
      // }

      // v_ticks set creation
      let increment = Math.round(max / 10)
      let current = min
      for(let index = 0; index <= 10; index++) {
        v_ticks.add(current)
        current += increment
      }

      let adjusted_min = min - (increment * 0.25)
      let adjusted_max = max

      this.chartOptions.vAxis.viewWindow.min = adjusted_min / 100
      this.chartOptions.vAxis.viewWindow.max = adjusted_max / 100

      h_ticks.forEach((tick) => {
        this.chartOptions.hAxis.ticks.push({v: tick, f: `${tick}`})
      })
    },

    buildChartData() {
      let current_history = this.history
      if(current_history.length > 10) current_history = current_history.slice((current_history.length - 10), current_history.length)

      this.chartData.push(['date', 'target', 'target', {'type': 'string', 'role': 'tooltip'}])

      current_history.forEach((item) => {
        let value = item.target / 100

        this.chartData.push([item.year, value, value, `Year: ${item.year}, Total Plan Value: ${value}`])
      })

      // if(this.clientIsDiy) {
      //   this.chartData.push(['date', 'target', 'target', {'type': 'string', 'role': 'tooltip'}])
      //
      //   this.history.forEach((item) => {
      //     let value = item.target / 100
      //
      //     this.chartData.push([item.year, value, value, `Year: ${item.year}, Total Plan Value: ${value}`])
      //   })
      // }
      // else {
      //   this.chartData.push(['date', 'base', 'base', 'target', 'target', 'superior', 'superior'])
      //
      //   this.history.forEach((item) => {
      //     let base = item.base / 100
      //     let target = item.target / 100
      //     let superior = item.superior / 100
      //
      //     this.chartData.push([item.year, base, base, target, target, superior, superior])
      //   })
      // }
    },

    headerColor() {
      if(this.clientStyles && (
          typeof this.clientStyles.heading_background_red !== 'undefined' ||
          typeof this.clientStyles.heading_background_green !== 'undefined' ||
          typeof this.clientStyles.heading_background_blue !== 'undefined'
      )) {
        let r = this.clientStyles.heading_background_red
        let g = this.clientStyles.heading_background_green
        let b = this.clientStyles.heading_background_blue

        let color = this.RGBToHex(r, g, b)

        return `${color}`
      }
      return `#241b89`
    },
    headerTintColor() {
      if(this.clientStyles && (
          typeof this.clientStyles.heading_background_red !== 'undefined' ||
          typeof this.clientStyles.heading_background_green !== 'undefined' ||
          typeof this.clientStyles.heading_background_blue !== 'undefined'
      )) {
        let r = this.clientStyles.heading_background_red
        let g = this.clientStyles.heading_background_green
        let b = this.clientStyles.heading_background_blue

        let tint = this.RGBTint(r, g, b, 0.4)
        let color = this.RGBToHex(tint.r, tint.g, tint.b)

        return `${color}`
      }
      return `#8782be`
    },
    headerShadeColor() {
      if(this.clientStyles && (
          typeof this.clientStyles.heading_background_red !== 'undefined' ||
          typeof this.clientStyles.heading_background_green !== 'undefined' ||
          typeof this.clientStyles.heading_background_blue !== 'undefined'
      )) {
        let r = this.clientStyles.heading_background_red
        let g = this.clientStyles.heading_background_green
        let b = this.clientStyles.heading_background_blue

        let shade = this.RGBShade(r, g, b, 0.6)
        let color = this.RGBToHex(shade.r, shade.g, shade.b)

        return `${color}`
      }
      return `#64608d`
    },

    getClientColors() {
      this.clientColors.header = this.headerColor();
      this.clientColors.headerTint = this.headerTintColor();
      this.clientColors.headerShade = this.headerShadeColor();

      this.buildChartOptions();
      this.buildChartData();
    },
  },

  mounted() {
    this.getClientColors();
  },

  watch: {
    clientStyles: {
      handler: function () {
        this.getClientColors();
      },
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables";

.ltip-chart {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;

    margin-bottom: 1rem;

    h3 {
      font-size: $text-base;
      margin-left: 3rem;
    }
  }

  .footer {
    margin-top: 2rem;

    p {
      font-size: $text-xs;
    }
  }

  .body {
    display: flex;

    flex-grow: 1;

    width: 100%;
    height: 100%;

    .chart {
      flex-grow: 1;

      width: 100%;
      height: 100%;
    }
  }
}

.legend-base {
  background-color: red;
}

.legend-target {
  background-color: blue;
}

.legend-superior {
  background-color: green;
}

.legend-base,
.legend-target,
.legend-superior {
  border-radius: 100%;
  height: 1rem;
  width: 1rem;

  margin-right: 0.4rem;
}

.legend-wrap {
  display: flex;
  align-items: center;

  margin-right: 1.5rem;
}

.legend-container {
  display: flex;

}
</style>