<template>
    <div>
        <div class="breakdown-header">
            <div class="section-header">
                <QuestionBadge :id="'pendo-statement-calculation-breakdown'">
                  <h2>CALCULATION BREAKDOWN</h2>
                </QuestionBadge>
            </div>
            <div class="company-performance">
                <span class="label">Company Performance</span>
                <span>{{ client.performance.position.label }}</span>
            </div>
            <div class="plan-funding">
                <span class="label">Potential Award</span>
                <span class="value">{{ employee.funding | currency }}</span>
            </div>
        </div>

        <div class="breakdown-content">
            <slot name="content">
                <organization-units v-if="hasOrganizationUnits" :client="client" :employee="employee"
                                    :organization="organization"/>
                <individual-modifier v-if="showIndividualGoals" :tbd="goalsTBD"
                                     :message="employee.performance.goals.performance_label"
                                     :value="employee.performance.goals.performance_total"
                />
                <individual-modifier v-if="showIndividualRating" :tbd="ratingTBD"
                                     :message="ratingMessage()"
                                     :value="employee.performance.rating.achieved_value"
                />
            </slot>
        </div>
        <div class="breakdown-footer">
            <slot name="footer">
                <div class="forecasted-bonus-wrap">
                    <div class="bonus">
                        <span class="label">Forecasted Award</span>
                        <span v-if="forecast.adjustment !== 0" class="value">{{ forecast.modified | currency }}</span>
                        <span v-else class="value">{{ forecast.adjusted | currency }}</span>
                    </div>
                    <div v-if="forecast.adjustment !== 0" class="adjustments-wrap">
                        <div class="adjustments">
                            <span class="label">Adjustment</span>
                            <span class="value">{{ forecast.adjustment | currency }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="forecast.adjustment !== 0" class="total-award">
                    <span class="label">Total Award</span>
                    <span class="value">{{ forecast.adjusted | currency }}</span>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
import IndividualModifier from "@/components/plans/short-term/calculation/IndividualModifier.vue";
import OrganizationUnits from "@/components/plans/short-term/calculation/OrganizationUnits.vue";
import QuestionBadge from "@/components/pendo/QuestionBadge.vue";

export default {
    name: "PlanBreakdown",

    components: {
        QuestionBadge,
        OrganizationUnits,
        IndividualModifier,
    },

    props: {
        forecast: {
            type: Object,
            required: true,
        },
        client: {
            type: Object,
            required: true,
        },
        employee: {
            type: Object,
            required: true
        },
        organization: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {}
    },

    computed: {
        showIndividualGoals() {
            return this.employee.performance?.goals && this.employee.performance?.mode === "as-modifier"
        },
        showIndividualRating() {
            return this.employee.performance?.rating && this.employee.performance?.mode === "as-modifier"
        },
        hasOrganizationUnits() {
            return this.organization?.units && Object.values(this.organization?.units).length
        },
        ratingTBD() {
            return this.employee.performance.rating.achieved_label === null || this.employee.performance.rating.achieved_label === ""
        },
        goalsTBD() {
            return this.employee.performance.goals.tbd
        },
    },

    methods: {
        ratingMessage() {
            if (this.employee.performance.rating.achieved_label) return `${this.employee.performance.rating.performance_label} - ${this.employee.performance.rating.achieved_label}`
            return `${this.employee.performance.rating.performance_label} - TBD`
        },
    },

}
</script>

<style lang="scss" scoped>
@import "../../../../scss/_variables.scss";

.section-header {
    margin-bottom: 0.5rem;
}

.label {
    font-family: $font-primary;
    font-size: $text-base;
}

.value {
    font-size: $text-lg;
}

.breakdown-header {
    display: flex;
    flex-direction: column;

    padding-top: 2.4rem;
    padding-bottom: 1.4rem;

    .company-performance {
        display: flex;
        justify-content: space-between;

        margin-bottom: 0.4rem;
    }

    .plan-funding {
        display: flex;
        justify-content: space-between;

        .value {
            color: var(--highlight-color);
            font-size: $text-base;
            font-family: $font-secondary;
            font-weight: 800;
        }
    }
}

.breakdown-footer {

    .forecasted-bonus-wrap {
        padding-block: 0.5rem;

        .bonus {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .value {
                color: var(--highlight-color);
                font-size: $text-lg;
                font-family: $font-secondary;
                font-weight: 800;
            }
        }

        .adjustments-wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .adjustments {
                display: grid;
                grid-template-columns: min-content min-content;
                grid-template-rows: min-content;

                grid-column-gap: 2rem;

                justify-items: flex-end;
                align-items: center;

                .label {
                    padding-block: 0.4rem;

                    font-size: $text-sm;
                    font-weight: normal;
                    font-family: $font-primary;
                    color: $charcoal-tint-50;
                }

                .value {
                    color: var(--highlight-color);
                    font-size: $text-base;
                    font-family: $font-secondary;
                    font-weight: 800;
                }
            }
        }
    }

    .total-award {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-block: 0.5rem;

        border-top: 2px solid $cloud;

        .value {
            color: var(--highlight-color);
            font-size: $text-lg;
            font-family: $font-secondary;
            font-weight: 800;
        }
    }
}
</style>