<template>
	<div>
		<p v-if="!isEditable">{{ value.name }}</p>
		<div v-if="isEditable" class="beneficiary-form">
			<div class="delete-this-beneficiary-wrapper"><img src="/img/grayX.svg" height=26 width=26 class="delete-this-beneficiary" title="delete this beneficiary" @click="deleteThisBeneficiary"></div>
			<div>
				<input-field
					:editable="true"
					v-model="beneficiary.name"
					@input="updateThisBeneficiary"
				>
					Name *
				</input-field>
			</div>
			<div>
				<input-field
					:editable="true"
					v-model="beneficiary.street"
					@input="updateThisBeneficiary"
				>
					Street Address
				</input-field>
			</div>
			<div>
				<input-field
					:editable="true"
					v-model="beneficiary.cityStateZip"
					@input="updateThisBeneficiary"
				>
					City, State, Zip
				</input-field>
			</div>
			<div>
				<date-field
					:editable="true"
					v-model="beneficiary.dateOfBirth"
					@input="updateThisBeneficiary"
				>
					Date of Birth *
				</date-field>
			</div>
			<div>
				<input-field
					:editable="true"
					v-model="beneficiary.relationship"
					@input="updateThisBeneficiary"
				>
					Relationship *
				</input-field>
			</div>
			<div>
				<input-field
					:editable="true"
					type="number"
					v-model="beneficiary.percentage"
					@input="updateThisBeneficiary"
				>
					Percentage *
				</input-field>
			</div>
		</div>
	</div>
</template>

<script>
import InputField from "@/components/elements/InputField";
import DateField from "@/components/elements/DateField";
export default {
	name: "BeneficiaryForm",
	components:{
		InputField,
		DateField
	},
	props:{
		value: {
			type: Object,
			default: function(){
				return {
					name:"",
					street:"",
					cityStateZip:"",
					dateOfBirth:"",
					relationship:"",
					percentage:""
				}
			}
		},
		isEditable: {
			type: Boolean,
			default: false
		}
	},
	emits: ['deleted'],
	data() {
		return {
			beneficiary: {
				name: '',
				street: '',
				cityStateZip: '',
				dateOfBirth: '',
				relationship: '',
				percentage: ''
			}
		}
	},
	mounted() {
		this.beneficiary.name = this.value.name;
		this.beneficiary.street = this.value.street;
		this.beneficiary.cityStateZip = this.value.cityStateZip;
		this.beneficiary.dateOfBirth = this.value.dateOfBirth;
		this.beneficiary.relationship = this.value.relationship;
		this.beneficiary.percentage = this.value.percentage;
	},
	methods: {
		updateThisBeneficiary(){
			this.$emit('updated', this.beneficiary);
		},
		deleteThisBeneficiary(){
			this.$emit('deleted');
		}
	}
}
</script>
<style lang="scss" scoped>
.beneficiary-form{
	border-bottom: thin solid $color-divider;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
.delete-this-beneficiary-wrapper{
	margin-bottom: 10px;
	text-align: right;
}
.delete-this-beneficiary{
	cursor: pointer;
}
</style>
