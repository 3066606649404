<template>
	<div class="terms">
		<h1>Terms of Use</h1>
		<div class="tou-row">
			<div class="tou-col-s12">
				<div class="tou-card-panel">
					<div class="tou-row">
						<div id="terms-of-use-pdf-wrapper">
							<object data="/site/terms-of-use.pdf" type="application/pdf" id="terms-of-use-pdf-object">This browser does not support the object tag.</object>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tou-clear">
		</div>
	</div>
</template>

<script>
export default {
	name: "BrTerms",
	components:{
	},
	data() {
		return {};
	},
	mounted() {
	},
	methods: {
	},
	computed:{
	}
}
</script>
<style lang="scss" scoped>
.terms {
	margin: 0 auto;
	max-width: 1280px;
	width: 90%;

	h1{
		text-align: center;
		color: #ffffff !important;
		font-family: $font-primary;
	}
}

.tou-row {
	margin-left: -0.75rem;
	margin-right: -0.75rem;
}

.tou-col-s12 {
	width: 100%;
	margin-left: auto;
	left: auto;
	right: auto;
	float: left;
	padding: 0 0.75rem;
	min-height: 1px;
}

.tou-card-panel {
	padding: 20px;
	margin: 0.5rem 0 1rem 0;
	border-radius: 2px;
	background-color: #fff;
}

.tou-clear {
	clear: both;
}

#terms-of-use-pdf-wrapper {
	text-align: center;
	background: #cccccc;
}

#terms-of-use-pdf-object {
	width: 60%;
	height: 548px;
}
</style>
