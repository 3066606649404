<template>
    <div>
        <div class="org-unit-header">
            <h3><span>{{ unit.label }}</span> <span>{{ organization.label }}</span></h3>
            <div>Current: {{ unit.allocation.modifier | percent }}</div>
        </div>
        <template v-if="unit.mode === '1x3' || unit.mode === '1x5'">
            <div class="rating-header">
                <div></div>
                <div>{{ unit.metrics.y.label }}</div>
                <div>%&nbsp;of Award&nbsp;Earned</div>
            </div>
            <div class="rating-table">
                <div class="rating-background">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <div class="rating-rows">
                    <template v-for="(level, index) in unit.metrics.grid[0]">
                        <div :key="`current-${index}`">
                            <template v-if="unit.metrics.achievement && unit.metrics.position[1] === index">
                                <span>Current</span>
                                <svg width="39" height="12" viewBox="0 0 39 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M39 6L29 0.2265L29 11.7735L39 6ZM-8.2075e-08 7L30 7L30 5L8.2075e-08 5L-8.2075e-08 7Z"
                                        fill="#1F3A4D"/>
                                </svg>
                            </template>
                        </div>
                        <div :key="`green-${index}`">
                            <template v-if="unit.metrics.y.unit === 'Dollars'">
                                {{ unit.metrics.y.points[index] | currency }}
                            </template>
                            <template v-else-if="unit.metrics.y.unit === 'Percent'">
                                {{ unit.metrics.y.points[index] | percent }}
                            </template>
                            <template v-else>{{ unit.metrics.y.points[index] }}</template>
                        </div>
                        <div :key="`values-${index}`"> = {{ level | percent }}</div>
                    </template>
                </div>
            </div>
        </template>
        <template v-else-if="unit.mode === '3x3' || unit.mode === '5x5'">
            <div class="org-unit-grid">
                <template v-for="(column, x_index) in unit.metrics.grid">
                    <!-- Y Axis -->
                    <div v-if="x_index === 0" class="org-unit-column" :key="`column-${x_index}`">
                        <div class="org-unit-cell"><span>{{ unit.metrics.y.label }}</span></div>
                        <template v-for="(point, point_index) in unit.metrics.y.points">
                            <div class="org-unit-cell" :key="`y-point-${point_index}`">
                                <span>
                                    <template v-if="unit.metrics.y.unit === 'Percent'">{{ point | percent }}</template>
                                    <template v-else-if="unit.metrics.y.unit === 'Dollars'">{{
                                            point | currency
                                        }}</template>
                                    <template v-else>{{ point }}</template>
                                </span>
                            </div>
                        </template>
                        <div class="org-unit-cell"><span>{{ unit.metrics.x.label }}</span></div>
                    </div>

                    <div class="org-unit-column" :key="`column-${x_index + 1}`">
                        <div class="org-unit-cell" :key="`y-axis-spacer-${x_index}`"></div>
                        <template v-for="(cell, y_index) in column">

                            <!-- Grid Cell -->
                            <div class="org-unit-cell" :class="{'current-cell': isCurrentPosition(x_index, y_index)}"
                                 :key="`grid-cell-${x_index}-${y_index}`"><span>{{ cell | percent }}</span></div>

                            <!-- X Axis -->
                            <div v-if="column.length === y_index + 1" class="org-unit-cell"
                                 :key="`x-axis-${x_index}-${y_index}`">
                                <span>
                                    <template v-if="unit.metrics.x.unit === 'Percent'">{{
                                            unit.metrics.x.points[x_index] | percent
                                        }}</template>
                                    <template v-else-if="unit.metrics.x.unit === 'Dollars'">{{
                                            unit.metrics.x.points[x_index] | currency
                                        }}</template>
                                    <template v-else>{{ unit.metrics.x.points[x_index] }}</template>
                                </span>
                            </div>
                        </template>
                    </div>
                </template>
            </div>

            <div class="org-unit-legend">
                <span class="legend-current"></span> Current Rating
            </div>
        </template>
    </div>
</template>

<script>
import organizationUnits from "../short-term/calculation/OrganizationUnits.vue";

export default {
    name: "RatingScale",

    props: {
        unit: {
            type: Object,
            required: true,
        },
        organization: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {}
    },

    computed: {
        organizationUnits() {
            return organizationUnits
        },
    },

    methods: {
        isCurrentPosition(x, y) {
            return x === this.unit.metrics.position[0] && y === this.unit.metrics.position[1]
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/_variables.scss";

.org-unit-header {
    display: flex;
    justify-content: space-between;

    font-size: 1.125em;
}

.org-unit-grid {
    display: flex;
}

.org-unit-column {
    display: flex;
    flex-direction: column;

    width: 100%;

    border-right: dashed 0.125em $charcoal-tint-50;
}

.org-unit-cell {
    padding: 0.125em;

    height: 2.25em;
    width: 100%;

    border-bottom: dashed 0.125em $charcoal-tint-50;

    span {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
        width: 100%;
    }
}

.current-cell > span {
    background-color: var(--highlight-color);
}

.org-unit-legend {
    display: flex;
    align-items: center;

    margin-top: 1.5em;

    .legend-current {
        height: 1.25em;
        width: 1.25em;

        background-color: var(--highlight-color);

        border-radius: 100%;

        margin-right: 0.875em;
    }
}

.rating-header {
    border-top: thin solid $color-divider;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    column-gap: 1em;

    padding: 0.5em 0.5em 0 0.5em;

    div {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-align: center;
        font-family: $font-primary;
    }
}

.rating-table {
    position: relative;

    padding: 0.5em 0;

    .rating-background {
        position: absolute;
        z-index: 10;

        top: 0;
        left: 0;

        padding: 0.5em 0;

        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        column-gap: 1em;

        & > div {
            width: 100%;
            height: 100%;

            &:nth-child(2) {
                border-radius: 4px;
                background: linear-gradient(0deg, var(--brand-color-tint) 0%, var(--brand-color) 100%);
            }
        }
    }

    .rating-rows {
        position: relative;
        z-index: 50;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        column-gap: 1em;

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;

            height: 2.8rem;

            &:nth-child(3n - 1) {
                border-top: 2px solid white;

                color: var(--foreground-color);
                font-weight: bold;
            }

            &:nth-child(2) {
                border-top: none;
            }

            &:nth-child(3n) {
                font-weight: bold;
                justify-content: center;
            }

            &:first-child {
                justify-content: flex-end;
            }

            &:nth-child(3n - 2) span {
                margin-right: 0.1em;
                color: var(--highlight-color);
                font-weight: bold;
            }

            &:nth-child(3n - 2) svg {
                height: 0.5rem;
            }
        }
    }
}
</style>