<template>
	<div class="card" :class="{blue:blue, orange:orange}">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "BrCard",
	props:{
		blue:{
			type: Boolean,
			default: false
		},
		orange:{
			type: Boolean,
			default: false
		},
	},
	data() {
		return {}
	},
	mounted() {

	},
	methods: {}
}
</script>
<style lang="scss" scoped>
	.card{
		border: thin solid #dddddd;
		border-radius: 15px;
		padding: 18px;
		background-color: $color-background;
		&.orange{
			border-color: $color-card-border-orange;
		}
		&.blue{
			border-color: $color-card-border-blue;
		}
	}
</style>