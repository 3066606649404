<template>
  <div class="plan-card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PlanCard"
}
</script>

<style lang="scss" scoped>
@import "../../../scss/_variables.scss";

.plan-card {
  background-color: $pure-white;
  border: thin solid $charcoal-tint-20;
  border-radius: 8px;
}
</style>