<template>
    <div v-if="selected" class="annual-plan" ref="annual-plan">
        <plan-header class="plan-header" :title="selected.plan.name" :salary="selected.employee.salary"
                     :dates="selected.plan.dates" :message="summary_message"
                     :is-eos="!!selected.plan.isEos" :link="selected.spd_link"
        />
        <plan-toolbar class="plan-toolbar" :date="selected.plan.dates.statement" :pdf="selected.pdf_link" />
        <plan-overview class="plan-overview" :selected="selected"/>
        <plan-scale class="plan-scale" :selected="selected" />
        <plan-calculation :selected="selected" />
        <plan-summary :summary="selected.plan.summary" :individual="selected.employee"
                      :organization="selected.organization" :plan="selected.plan"
                      :distributions="selected.distributions"
        />
    </div>
</template>

<script>
import PlanHeader from "@/components/plans/short-term/PlanHeader.vue";
import PlanToolbar from "@/components/plans/global/PlanToolbar.vue";
import PlanOverview from "@/components/plans/short-term/PlanOverview.vue";
import PlanScale from "@/components/plans/short-term/scale/PlanScale.vue";
import PlanCalculation from "@/components/plans/short-term/calculation/PlanCalculation.vue";
import PlanSummary from "@/components/plans/short-term/summary/PlanSummary.vue";
import UtilsMixin from "@/mixins/UtilsMixin";
import ColorsMixin from "@/mixins/ColorsMixin";

export default {
    name: "ShortTermPlanView",

    mixins: [ UtilsMixin, ColorsMixin ],

    components: {
        PlanSummary,
        PlanHeader,
        PlanToolbar,
        PlanOverview,
        PlanScale,
        PlanCalculation,
    },

    props: {
        plan: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            selected: null,
            userData: null,
        }
    },

    computed: {
        current() {
            return this.plan?.current
        },
        previous() {
            return this.plan?.previous
        },
        clientStyles() {
            if (this.userData?.client_styles) return JSON.parse(this.userData.client_styles)
            else return null
        },
        summary_message() {
          return this.current?.summary_message
        },
    },

    methods: {
      updateClientColors() {
        // TODO: rework this once a state management system is in place
        let highlight = this.getHighlightColor();

        let root_element = document.querySelector(':root')
        root_element.style.setProperty('--highlight-color', highlight)

        let tint = 0.8

        let brandColor = this.getBrandColor();
        let brandColorTint = this.getBrandTintColor(tint);

        root_element.style.setProperty('--brand-color', brandColor)
        root_element.style.setProperty('--brand-color-tint', brandColorTint)

        let foregroundColor = this.getForegroundColor();

        root_element.style.setProperty('--foreground-color', foregroundColor)
      },
    },

    watch: {
        clientStyles: {
            handler: function () {
                this.updateClientColors()
            },
            deep: true
        }
    },

    mounted() {
        this.selected = this.plan?.current.statement_data

        this.updateClientColors()

        this.userData = this.$dataDirectory.userData
        console.log(this.$dataDirectory, this.$dataDirectory.userData)
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

.annual-plan {
    .h1, .h2, .h3, .h4, .h5, .h6, div, p, span {
        color: $charcoal;
    }
}

.plan-header {
    margin-bottom: 1rem;
}

.plan-toolbar {
    margin-bottom: 1rem;
}

.plan-overview {
    margin-bottom: 1.125rem;
}

.plan-scale {
    margin-bottom: 4.2667rem;
}
</style>