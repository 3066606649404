import UtilsMixin from "@/mixins/UtilsMixin";

export default {
    mixins: [ UtilsMixin ],

    computed: {
        clientStyles() {
            if (this.userData?.client_styles) return JSON.parse(this.userData.client_styles)
            else return null
        },
    },

    methods: {
        getHighlightColor() {
            let highlight = `rgb(255 125 1)`

            if(this.clientStyles) {
                if(this.exists(this.clientStyles?.highlight_red)
                    && this.exists(this.clientStyles?.highlight_green)
                    && this.exists(this.clientStyles?.highlight_blue))
                {
                    let r = this.clientStyles.highlight_red
                    let g = this.clientStyles.highlight_green
                    let b = this.clientStyles.highlight_blue

                    highlight = `rgb(${r} ${g} ${b})`
                }
            }

            return highlight;
        },

        getBrandColor() {
            let brandColor = `rgb(36 27 137)`

            if(this.clientStyles) {
                if(this.exists(this.clientStyles?.highlight_red)
                    && this.exists(this.clientStyles?.highlight_green)
                    && this.exists(this.clientStyles?.highlight_blue))
                {
                    let r = this.clientStyles.heading_background_red
                    let g = this.clientStyles.heading_background_green
                    let b = this.clientStyles.heading_background_blue

                    brandColor = `rgb(${r} ${g} ${b})`

                }
            }

            return brandColor;
        },

        getBrandTintColor(tint = 0.8) {
            let brandColorTint = `rgb(${36 + ((255 - 36) * tint)} ${27 + ((255 - 27) * tint)} ${137 + ((255 - 137) * tint)})`

            if(this.clientStyles) {
                let tint = 0.8

                if(this.exists(this.clientStyles?.highlight_red)
                    && this.exists(this.clientStyles?.highlight_green)
                    && this.exists(this.clientStyles?.highlight_blue))
                {
                    let r = this.clientStyles.heading_background_red
                    let g = this.clientStyles.heading_background_green
                    let b = this.clientStyles.heading_background_blue

                    brandColorTint = `rgb(${r + ((255 - r) * tint)} ${g + ((255 - g) * tint)} ${b + ((255 - b) * tint)})`

                }
            }

            return brandColorTint;
        },

        getForegroundColor() {
            let foregroundColor = `rgb(0 0 0})`
            if(this.clientStyles) {
                if(this.exists(this.clientStyles?.heading_foreground_red)
                    && this.exists(this.clientStyles?.heading_foreground_green)
                    && this.exists(this.clientStyles?.heading_foreground_blue))
                {
                    let r = this.clientStyles.heading_foreground_red
                    let g = this.clientStyles.heading_foreground_green
                    let b = this.clientStyles.heading_foreground_blue

                    foregroundColor = `rgb(${r} ${g} ${b})`
                }
            }

            return foregroundColor;
        }
    },
}