<template>
	<div class="deferral-wrapper">
		<h3 class="deferral-heading">{{ headingText }}</h3>
		<div class="deferral-type-wrapper">
			<div class="deferral-type-choice-wrapper">
				<input type="radio" id="deferral-type-none" value="-1" v-model="deferralType" />
				<label for="deferral-type-none">Keep maturity on {{ yearLabels[0] }} {{ fixedTimingDesc }}</label>
			</div>
			<div class="deferral-type-choice-wrapper">
				<input type="radio" id="deferral-type-years" value="1" v-model="deferralType" />
				<label for="deferral-type-years">Defer maturity for a number of years {{ fixedTimingDesc }}</label>
			</div>
			<div class="deferral-type-choice-wrapper" v-if="plan.maxDeferralYears === 0">
				<input type="radio" id="deferral-type-separation" value="2" v-model="deferralType" />
				<label for="deferral-type-separation">Defer maturity until separation {{ separationTimingDesc }}</label>
			</div>
		</div>
		<div class="deferral-years-wrapper" v-if="deferralType === '1'">
			<h4 class="deferral-years-heading">Please select the maturity date you would like.</h4>
			<select v-model="deferralYears">
				<option value="0">{{ yearLabels[0] }}</option>
				<option v-for="n in yearLimit" :value="n.toString()" :key="n.toString()">{{ yearLabels[n] }}</option>
			</select>
                </div>
		<div class="deferral-disclaimer-wrapper">
			<h4 class="deferral-disclaimer">The payment you will receive is determined by the Unit Value as of the most recently completed fiscal year end prior to your chosen election date.</h4>
                </div>
		<div class="deferral-buttons-wrapper">
			<div class="deferral-error" v-if="errorMessage !== null">{{ errorMessage }}</div>
			<standard-button :is-light="true" style="margin-right: 8px;" @click="doCancel">{{ cancelText }}</standard-button>
			<standard-button @click="doSave">Save</standard-button>
		</div>
	</div>
</template>

<script>
import StandardButton from "@/components/elements/StandardButton";
export default {
	name: "BrDeferral",
	components: {
		StandardButton
	},
	props: {
		plan: {
			type: Object,
			required: true
		},
		certificate: {
			type: Object,
			required: true
		},
		headingText: {
			type: String,
			required: true
		},
		cancelText: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			deferralType: '0',
			deferralYears: '0',
			yearLimit: 0,
			yearLabels: [],
			errorMessage: null,
			fixedTimingDesc: '',
			separationTimingDesc: '',
			monthList: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			]
		}
	},
	mounted() {
		this.deferralYears = '0';
		if (this.certificate.deferralType === 1) {
			if (this.certificate.deferralYears === 0) {
				this.deferralType = '-1';
			} else {
				this.deferralType = '1';
				this.deferralYears = this.certificate.deferralYears.toString();
			}
		} else {
			this.deferralType = this.certificate.deferralType.toString();
		}
		this.yearLimit = this.plan.maxDeferralYears === 0 ? 15 : this.plan.maxDeferralYears;
		this.yearLabels = [];
		for (let n = 0; n <= this.yearLimit; ++n) {
			let temp_obj = new Date(this.certificate.maturityDate + 'T00:00:00Z');
			if (n !== 0) {
				temp_obj.setUTCFullYear(temp_obj.getUTCFullYear() + n);
			}
			this.yearLabels.push(temp_obj.getUTCDate() + ' ' + this.monthList[temp_obj.getUTCMonth()] + ' ' + temp_obj.getUTCFullYear());
		}
		let petx = 'fixed-date';
		if (this.certificate.groupIndex !== 0) {
			petx += '.' + this.certificate.groupIndex.toString();
		}
		if (this.plan.paymentTiming[petx] === 'installments') {
			this.fixedTimingDesc = 'paid in ' + this.plan.otherInstallmentCount.toString() + ' installments';
		} else {
			this.fixedTimingDesc = 'paid as a lump sum';
		}
		if (this.plan.paymentTiming['voluntary-separation'] === 'installments') {
			if (this.plan.paymentTiming['involuntary-separation'] === 'installments') {
				this.separationTimingDesc = 'paid in ' + this.plan.otherInstallmentCount.toString() + ' installments';
			} else {
				this.separationTimingDesc = 'paid in ' + this.plan.otherInstallmentCount.toString() + ' installments if voluntary or as a lump sum if involuntary';
			}
		} else {
			if (this.plan.paymentTiming['involuntary-separation'] === 'installments') {
				this.separationTimingDesc = 'paid as a lump sum if voluntary or in ' + this.plan.otherInstallmentCount.toString() + ' installments if involuntary';
			} else {
				this.separationTimingDesc = 'paid as a lump sum';
			}
		}
	},
	methods: {
		doSave() {
			let newDeferralType = parseInt(this.deferralType);
			let newDeferralYears = parseInt(this.deferralYears);
			if (newDeferralType !== 1) {
				if (newDeferralType === -1) {
					newDeferralType = 1;
				}
				newDeferralYears = 0;
			}
			if (newDeferralType === 0) {
				this.errorMessage = 'Please make a selection.';
			} else {
				this.$apiManager.post('deferral-data/' + this.certificate.grantedSharesId, {
					deferralType: newDeferralType,
					deferralYears: newDeferralYears
				}).then(
					(pack) => {
						if (pack.data.success) {
							this.$emit('close', newDeferralType, newDeferralYears);
						} else {
							this.errorMessage = 'error: ' + pack.data.errorCode;
						}
					},
					() => { this.errorMessage = 'unknown error'; }
				);
			}
		},
		doCancel() {
			this.$emit('close', this.certificate.deferralType, this.certificate.deferralYears);
		}
	}
}
</script>
<style lang="scss" scoped>
	.deferral-wrapper {
		border: thin solid $color-card-border;
		border-radius: 5px;
		padding: 0.75vw;
		background-color: $color-background;
		margin-bottom: 1vw;
	}

	.deferral-type-wrapper {
		padding: 10px;

		input {
			vertical-align: middle;
		}

		label {
			vertical-align: middle;
			padding-left: 10px;
		}
	}

	.deferral-years-wrapper {
		padding: 10px;

		select {
			margin: 5px;
			padding: 5px;
		}
	}

	.deferral-disclaimer-wrapper {
		padding: 10px;
	}

	.deferral-buttons-wrapper {
		margin-top: 1vw;
	}

	.deferral-error {
		color: red;
	}
</style>
