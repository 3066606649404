
class GlobalStorage{

    constructor(){

    }

    static save(name, obj){
        if(obj === null){
            delete window.localStorage[name];
            return;
        }
        if((typeof obj).toLowerCase() === "string"){
            window.localStorage[name] = JSON.stringify({"__str__": obj});
        }else {
            window.localStorage[name] = JSON.stringify(obj);
        }

    }

    static load(name){
        if(window.localStorage[name]){
            let dt = JSON.parse(window.localStorage[name]);
            if(!dt.__str__){
                return dt;
            }
            return dt.__str__;
        }

        return null;
    }

    static has(name){
        if(window.localStorage[name]){
            return true;
        }
        return false;
    }

    static purge(){
        for(let name in window.localStorage){
            if(name != "last_creds"){
               try{
                   delete window.localStorage[name];
               } catch(e){
                   window.localStorage[name] = "";
               }
            }
        }
    }

}

export default GlobalStorage;