import Vue from 'vue';
import VueRouter from 'vue-router';
import BrLogin from "@/components/pages/BrLogin";
import BrHeader from "@/components/BrHeader";
import BrNavigation from "@/components/BrNavigation";
import BrAccount from "@/components/pages/BrAccount";
import BrContact from "@/components/pages/BrContact";
import BrDashboard from "@/components/pages/BrDashboard";
import LongTerm from "@/components/pages/LongTerm";
import ShortTerm from "@/components/pages/ShortTerm";
import FirstLogin from "@/components/pages/FirstLogin";
import PageNotFound from "@/components/pages/PageNotFound";
import BrTerms from "@/components/pages/BrTerms";
import ValueStatement from "@/components/pages/ValueStatement";
import ResetPassword from "./components/pages/ResetPassword";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		components: {
			default: null,
			header: BrLogin,
			navigation: null,
		}
	},
	{
		path: "*",
		components: {
			default: null,
			header: PageNotFound,
			navigation: null,
		}
	},
	{
		path: "/login",
		components: {
			default: null,
			header: BrLogin,
			navigation: null,
		}
	},
	{
		path: "/single-sign-on",
		components: {
			default: null,
			header: BrLogin,
			navigation: null,
		}
	},
	{
		path: "/first-login/:email/:code",
		components: {
			default: null,
			header: FirstLogin,
			navigation: null,
		}
	},
	//https://portal.bonusrightapp.com/#/reset-password/brent.allen%40elikirkriser.com/gAAAAABhH9QVwiqHRJiYvqF8UDMG79ddUQKJHBQ4cJA7tal8yJ0re86SKqcwTz-PKudLNxbByu6d9nY7dPxdHdl7K1cRPkMx5xRCv4u6jVcI-iJF3fSMC_DLwgMC_vzcjq-tJOWX9K2uuXQlBLF4HOt9nd6VZZ5yWazo5XyfjJe1sHfg190jafOYUmcytlkGKhZTWVq_Uf4oqihwADFJQdXFTPv_KfkFwQ/1
	{
		path: "/reset-password/:email/:code/:accepted",
		components: {
			default: null,
			header: ResetPassword,
			navigation: null,
		}
	},
	{
		path: "/dashboard",
		components: {
			default: BrDashboard,
			header: BrHeader,
			navigation: BrNavigation,
		}
	},
	{
		path: "/account",
		components: {
			default: BrAccount,
			header: BrHeader,
			navigation: BrNavigation,
		}
	},
	{
		path: "/short-term",
		components: {
			default: ShortTerm,
			header: BrHeader,
			navigation: BrNavigation,
		}
	},
	{
		path: "/short-term/:id",
		components: {
			default: ShortTerm,
			header: BrHeader,
			navigation: BrNavigation,
		}
	},
	{
		path: "/long-term",
		components: {
			default: LongTerm,
			header: BrHeader,
			navigation: BrNavigation,
		}
	},
	{
		path: "/long-term/:id",
		components: {
			default: LongTerm,
			header: BrHeader,
			navigation: BrNavigation,
		}
	},
	{
		path: "/value-statement",
		components: {
			default: ValueStatement,
			header: BrHeader,
			navigation: BrNavigation,
		}
	},
	{
		path: "/contact",
		components: {
			default: BrContact,
			header: BrHeader,
			navigation: BrNavigation,
		}
	},
	{
		path: "/terms-of-use",
		components: {
			default: BrTerms,
			header: BrHeader,
			navigation: BrNavigation,
		}
	},

];

const router = new VueRouter({
	routes // short for `routes: routes`
});

document.router = router;

router.afterEach(to => {
	if(to.name != "help"){
		window.lastPageName = to.name;
	}
});

export default router;