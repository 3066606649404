<script>
import { Doughnut } from 'vue-chartjs'
export default {
	name: "PieChart",
	extends: Doughnut,
	props: {
		chartdata: {
			type: Object,
			default: null
		},
		options: {
			type: Object,
			default: null
		}
	},
	data() {
		return {}
	},
	mounted() {
		this.renderChart(this.chartdata, this.options);
	},
	methods: {
	}
}
</script>
<style scoped></style>
