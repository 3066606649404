<template>
    <div>
        <div class="calculation-headers">
            <div class="performance">
                <QuestionBadge :id="'pendo-statement-company-performance'"><h2>COMPANY PERFORMANCE</h2></QuestionBadge>
                <p>This graph illustrates company performance and the size of your projected Potential Award. This value may be modified by other factors detailed in the calculation below.</p>
            </div>
            <div v-if="hasOrganizationUnits" class="allocation">
                <QuestionBadge :id="'pendo-statement-allocation'"><h2>ALLOCATION</h2></QuestionBadge>
                <p>Your award is allocated on the following plan metrics. See Metrics/Performance below for additional information.</p>
            </div>
        </div>

        <plan-card class="plan-calculation">
            <div class="charts">
                <line-chart class="line-chart" v-if="hasHistory"
                            :dates="selected.plan.dates" :tiers="selected.tiers"
                            :history="selected.client.performance.history"
                />
                <doughnut-chart class="pie-chart" v-if="hasOrganizationUnits"
                                :client="selected.client" :employee="selected.employee"
                                :organization="selected.organization" :distributions="selected.distributions"
                />
            </div>

            <div v-if="hasSplitFunding">
              <div>
                <div>
                  <h4>Calculation Breakdown</h4>
                </div>

                <div class="allocation-pools-table">
                  <div class="header-cell"></div>
                  <div class="header-cell">{{ selected.tiers.base.label }}</div>
                  <div class="header-cell">{{ selected.tiers.target.label }}</div>
                  <div class="header-cell">{{ selected.tiers.superior.label }}</div>
                  <div class="header-cell last-column">{{ selected.forecast.label }}</div>

                  <div class="first-column">Company Performance</div>
                  <div>{{ selected.allocation_pools.company.base | currency }}</div>
                  <div>{{ selected.allocation_pools.company.target | currency }}</div>
                  <div>{{ selected.allocation_pools.company.superior | currency }}</div>
                  <div class="last-column">{{ selected.client.allocation.contribution | currency }}</div>

                  <template v-for="unit in selected.organization.units">
                    <div class="first-column" :key="`unit-${unit.id}-label`">{{ unit.label }} Performance</div>
                    <div :key="`unit-${unit.id}-base`">{{ selected.allocation_pools.organization_type[unit.type_id].base | currency }}</div>
                    <div :key="`unit-${unit.id}-target`">{{ selected.allocation_pools.organization_type[unit.type_id].target | currency }}</div>
                    <div :key="`unit-${unit.id}-superior`">{{ selected.allocation_pools.organization_type[unit.type_id].superior | currency }}</div>
                    <div class="last-column" :key="`unit-${unit.id}-forecast`">{{ unit.allocation.contribution | currency }}</div>
                  </template>

                  <template v-if="hasIndividualComponent">
                    <div class="first-column">Individual Performance</div>
                    <div>{{ selected.allocation_pools.individual.base | currency }}</div>
                    <div>{{ selected.allocation_pools.individual.target | currency }}</div>
                    <div>{{ selected.allocation_pools.individual.superior | currency }}</div>
                    <div class="last-column">{{ selected.employee.allocation.contribution | currency }}</div>
                  </template>

                  <template v-if="hasIndividualPerformance">
                    <div class="first-column border-t border-solid border-secondary"></div>
                    <div class="border-t border-solid border-secondary">{{ selected.tiers.base.value | currency }}</div>
                    <div class="border-t border-solid border-secondary">{{ selected.tiers.target.value | currency }}</div>
                    <div class="border-t border-solid border-secondary">{{ selected.tiers.superior.value | currency }}</div>
                    <div class="last-column border-t border-solid border-secondary">{{ selected.forecast.raw | currency }}</div>

                  </template>
                  <template v-else>
                    <template v-if="selected.forecast.tweaked_percent !== 0 || selected.forecast.tweaked_cents !== 0">
                      <div class="first-column border-t"></div>
                      <div class="border-t">{{ selected.tiers.base.value | currency }}</div>
                      <div class="border-t">{{ selected.tiers.target.value | currency }}</div>
                      <div class="border-t">{{ selected.tiers.superior.value | currency }}</div>
                      <div class="last-column border-t">{{ selected.forecast.raw | currency }}</div>

                      <template v-if="selected.forecast.tweaked_percent !== 0">
                        <div class="first-column">Adjustment</div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div class="last-column">{{ selected.forecast.tweaked_percent | percent }}</div>
                      </template>

                      <template v-if="selected.forecast.tweaked_cents !== 0">
                        <div class="first-column">Adjustment</div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div class="last-column">{{ selected.forecast.tweaked_cents | currency }}</div>
                      </template>

                      <div class="first-column">Award Value</div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div class="last-column">{{ selected.forecast.adjusted | currency }}</div>
                    </template>
                    <template v-else>
                      <div class="border-t"></div>
                      <div class="border-t">{{ selected.tiers.base.value | currency }}</div>
                      <div class="border-t">{{ selected.tiers.target.value | currency }}</div>
                      <div class="border-t">{{ selected.tiers.superior.value | currency }}</div>
                      <div class="last-column border-t">{{ selected.forecast.adjusted | currency }}</div>
                    </template>
                  </template>
                </div>
              </div>

            </div>
            <plan-distributions v-else-if="hasDistributions"
                                :distributions="selected.distributions" :organization="selected.organization"
                                :employee="selected.employee" :plan="selected.plan" :client="selected.client"
            />
            <plan-breakdown v-else :client="selected.client" :employee="selected.employee"
                            :organization="selected.organization" :forecast="selected.forecast"
            />
        </plan-card>
    </div>
</template>

<script>
import PlanCard from "@/components/plans/global/PlanCard.vue";
import DoughnutChart from "@/components/plans/short-term/charts/DoughnutChart.vue";
import PlanBreakdown from "@/components/plans/short-term/calculation/PlanBreakdown.vue";
import LineChart from "@/components/plans/short-term/charts/LineChart.vue";
import QuestionBadge from "@/components/pendo/QuestionBadge.vue";
import PlanDistributions from "@/components/plans/short-term/PlanDistributions.vue";

export default {
    name: "PlanCalculation",

    components: {
      PlanDistributions,
      QuestionBadge,
      LineChart,
      PlanCard,
      DoughnutChart,
      PlanBreakdown,
    },

    props: {
      selected: {
        type: Object,
        required: true,
      },
    },

    computed: {
        hasIndividualPerformance() {
          return (this.selected.employee.performance.goals || this.selected.employee.performance.rating) && this.selected.employee.performance.mode === 'as-modifier';
        },
        hasIndividualComponent() {
          return this.selected?.employee?.performance?.mode === 'as-component'
        },
        hasSplitFunding() {
          return this.selected?.plan?.funding_mode === 'split';
        },
        hasHistory() {
          return this.selected.client?.performance
        },
        isEos() {
          return this.selected.plan.isEos;
        },
        hasDistributions() {
          return this.selected.plan?.holdback.mode === 'enabled'
        },
        hasOrganizationUnits() {
          return this.selected.organization?.units && Object.values(this.selected.organization?.units).length > 0
        },
    },

    methods: {},

    mounted() {}
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/_variables.scss";

.calculation-headers {
  display: flex;

  margin-bottom: 1.125rem;

  .performance, .allocation {
    p {
      font-size: $text-sm;
    }
  }

  .performance {}

  .allocation {
    min-width: 28%;
    margin-left: 1.25em;
  }
}

.plan-calculation {
  padding: 1.2rem 2.6rem;

  .charts {
    display: flex;

    padding-bottom: 3.6rem;

    border-bottom: 2px solid $cloud;

    .pie-chart {
      min-width: 35%;
      margin-left: 1.25em;
    }
  }
}

.allocation-pools-table {
  width:100%;
  display:grid;
  grid-template-columns:5fr repeat(4, 4fr);
  margin-bottom:0.5em;
}

.allocation-pools-table > div {
  text-align: center;

  padding: 0.5em 0.75em;
}

.allocation-pools-table > .header-cell {
  font-weight: bold;
  font-size: 1rem;

  text-transform: capitalize;

  border-bottom: 1px solid $charcoal-tint-20;
}

.allocation-pools-table > .first-column {
  text-align: right;

  font-weight: bold;
}

.allocation-pools-table > .last-column {
  border-left: 1px solid $charcoal-tint-20;
}

.allocation-pools-table > .border-t {
  border-top: 1px solid $charcoal-tint-20;
}
</style>