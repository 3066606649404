export default class DataDirectory{

	constructor() {
		this._userData = {
			env_code: "",
			employee_id: 0,
			client_id: 0,
			client_status: "",
			client_styles: "",
			firstName: "",
			lastName: "",
			image: "https://via.placeholder.com/500",
			smallImage: "https://via.placeholder.com/100",
			clientLogo: null,
			company: "",
			position: "",
			username: "",
			email:"",
			success: false,
			isDemo: true
		};
		this._userDataLoaded = false;
		this._evsAvailable = false;
		this._conventionalEvs = false;
		this._shortTermAvailable = false;
		this._longTermAvailable = false;
		this._futureYear = null;
		this.isPostingFutureYear = false;
		this.wasPostingFutureYear = false;
		this.planUpdated = false;
		this.navigator = null;
		this.dataListeners = [];
	}

	get userData(){
		return this._userData;
	}

	set userData(dt){
		if(dt.employee_id) {
			window.br_pendo_set_data({
				visitor: {
					id: dt.env_code + '-e-' + dt.employee_id,
					email: dt.email,
					full_name: dt.firstName + ' ' + dt.lastName
				},
				account: {
					id: dt.env_code + '-' + dt.client_id,
					name: dt.company
				}
			});
		}
		this._userData = dt;
		this._userDataLoaded = true;
		for(let i = 0; i < this.dataListeners.length;++i){
			try{
				this.dataListeners[i]();
			} catch (e){
				//skip it
			}
		}
	}

	get userDataLoaded(){
		return this._userDataLoaded;
	}

	set evsAvailable(v){
		this._evsAvailable = v;
		if(this.navigator){
			this.navigator.$forceUpdate();
		}
	}

	get evsAvailable(){
		return this._evsAvailable;
	}

	set conventionalEvs(v){
		this._conventionalEvs = v;
	}

	get conventionalEvs(){
		return this._conventionalEvs;
	}

	set shortTermAvailable(v){
		this._shortTermAvailable = v;
		if(this.navigator){
			this.navigator.$forceUpdate();
		}
	}

	get shortTermAvailable(){
		return this._shortTermAvailable;
	}

	set longTermAvailable(v){
		this._longTermAvailable = v;
		if(this.navigator){
			this.navigator.$forceUpdate();
		}
	}

	get longTermAvailable(){
		return this._longTermAvailable;
	}

	set futureYear(v){
		this._futureYear = v;
	}

	get futureYear(){
		return this._futureYear;
	}

	addListener(fn){
		this.dataListeners.push(fn);
	}

	removeListener(fn){
		for(let i = 0; i < this.dataListeners.length;++i){
			if(this.dataListeners[i] === fn){
				this.dataListeners.splice(i, 1);
				break;
			}
		}
	}

	checkLogin(completeFunction, failedFunction = null){
		if(this.userData.isDemo){
			if(window.apiManager.token){
				window.axios.get(window.apiManager.route("user")).then(
					(pack) => this.setUserData(pack, completeFunction),
					() => this.dataFailure(failedFunction),
				);
			} else if (failedFunction) {
				failedFunction();
			}
		} else if (completeFunction){
			completeFunction();
		}
	}

	setUserData(pack, complete){
		if(pack.data && pack.data.success){
			this.userData = pack.data;
			complete();
		}
	}

	dataFailure(failedFunction){
		if(failedFunction){
			failedFunction();
		}
	}

}
