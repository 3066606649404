<template>
    <div class="organization-units">
        <!-- Company -->
        <section>
            <h5>{{ client.allocation.label }} Portion</h5>

            <div class="grid grid-cols-10">
                <span class="col-span-3 self-start ml-6">Allocation</span>
                <span class="col-span-2 self-end">Funding</span>
                <span class="col-span-2 self-end">Mod%</span>
                <span class="col-span-3 self-end">Adjustment Award</span>

                <span class="col-span-3 self-start ml-6">{{ client.allocation.weight | percent }}</span>
                <span class="col-span-2 self-end">{{ client.allocation.funding | currency }}</span>
                <span class="col-span-2 self-end">
                    <template v-if="client.allocation.modifier">{{ client.allocation.modifier | percent }}</template>
                    <template v-else>N/A</template>
                </span>
                <span class="col-span-3 self-end">{{ client.allocation.contribution | currency }}</span>
            </div>
        </section>

        <!-- Organization Units -->
        <section v-for="(unit, index) in Object.values(organization.units)" :key="`org-units-${index}`">
            <h5>{{ unit.label }} Potion</h5>

            <div class="grid grid-cols-10">
                <span class="col-span-3 self-start ml-6">Allocation</span>
                <span class="col-span-2 self-end">Funding</span>
                <span class="col-span-2 self-end">Mod%</span>
                <span class="col-span-3 self-end">Adjustment Award</span>

                <span class="col-span-3 self-start ml-6">{{ unit.allocation.weight | percent }}</span>
                <span class="col-span-2 self-end">{{ unit.allocation.funding | currency }}</span>
                <span class="col-span-2 self-end">
                    <template v-if="unit.allocation.modifier">{{ unit.allocation.modifier | percent }}</template>
                    <template v-else>N/A</template>
                </span>
                <span class="col-span-3 self-end">{{ unit.allocation.contribution | currency }}</span>
            </div>
        </section>

        <!-- Individual -->
        <section>
            <h5>{{ employee.allocation.label }} Portion</h5>

            <div class="grid grid-cols-10">
                <span class="col-span-3 self-start ml-6">Allocation</span>
                <span class="col-span-2 self-end">Funding</span>
                <span class="col-span-2 self-end">Mod%</span>
                <span class="col-span-3 self-end">Adjustment Award</span>

                <span class="col-span-3 self-start ml-6">{{ employee.allocation.weight | percent }}</span>
                <span class="col-span-2 self-end">{{ employee.allocation.funding | currency }}</span>
                <span class="col-span-2 self-end">
                    <template v-if="employee.allocation.modifier">{{
                            employee.allocation.modifier | percent
                        }}</template>
                    <template v-else>N/A</template>
                </span>
                <span class="col-span-3 self-end">{{ employee.allocation.contribution | currency }}</span>
            </div>
        </section>

        <div class="summary grid grid-cols-10">
            <span class="col-span-3 self-start ml-6">{{ organization.totals.weight | percent }}</span>
            <span class="col-span-2 self-end">{{ organization.totals.funding | currency }}</span>
            <span class="col-span-2 self-end">{{ organization.totals.modifier | percent }}</span>
            <span class="col-span-3 self-end">{{ organization.totals.contribution | currency }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrganizationUnits",

    props: {
        client: {
            type: Object,
            required: true,
        },
        organization: {
            type: Object,
            required: false,
        },
        employee: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {}
    },

    computed: {},

    methods: {},
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/_variables.scss";

.organization-units {
    margin-bottom: 1.125rem;

    h5 {
        font-size: $text-sm;
    }

    section {
        padding-bottom: 0.2rem;
        margin-bottom: 1.125rem;
        border-bottom: 2px solid $cloud;

        span:nth-child(-n+4) {
            font-size: $text-sm;
        }

        span:nth-child(n+5) {
            font-size: $text-base;
            color: $charcoal-tint-50;
        }

        span:last-child {
            font-family: $font-secondary;
            font-weight: 800;
            color: var(--highlight-color);
        }
    }

    .summary {
        font-size: $text-base;
        color: $charcoal-tint-50;

        span:last-child {
            font-weight: bold;
            font-family: $font-secondary;
            font-weight: 800;
            color: var(--highlight-color);
        }
    }
}

.grid {
    display: grid;

    &-cols {
        &-4 {
            grid-template-columns: repeat(4, 1fr);
        }

        &-8 {
            grid-template-columns: repeat(8, 1fr);
        }

        &-10 {
            grid-template-columns: repeat(10, 1fr);
        }

        &-12 {
            grid-template-columns: repeat(12, 1fr);
        }
    }
}

.col-span {
    grid-column: span 1;

    &-1 {
        grid-column: span 1;
    }

    &-2 {
        grid-column: span 2;
    }

    &-3 {
        grid-column: span 3;
    }

    &-4 {
        grid-column: span 4;
    }
}

.self {

    &-start {
        justify-self: start;
    }

    &-end {
        justify-self: end;
    }
}

.ml {
    &-2 {
        margin-left: 0.5rem;
    }

    &-4 {
        margin-left: 1rem;
    }

    &-6 {
        margin-left: 1.5rem;
    }
}
</style>