<template>
  <div class="viewer-wrapper">
    <div class="controls">
      <div class="paging-controls">
        <button v-if="!showAllPages" @click="currentPage -= 1" :disabled="currentPage === 1">&lt;</button>
        <span v-if="showAllPages">Pages: {{ pageCount }}</span><span v-else>{{ currentPage }}</span>
        <button v-if="!showAllPages" @click="currentPage += 1" :disabled="currentPage === pageCount">&gt;</button>
      </div>

      <div>
        <button @click="onDownload">Download</button>
      </div>
    </div>
    <div class="viewer">
      <VuePdfEmbed class="embedded-pdf" ref="pdfRef" :source="source" :page="page" :width="width" @rendered="onRender" />
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "PDFViewer",

  components: {
    VuePdfEmbed,
  },

  props: {
    pdfUrl:{
      type: String,
      default: null,
    },
    pdfBase64: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    showAllPages: {
      type: Boolean,
      default: true,
    },
  },

  data: () => {
    return {
      pageCount: null,
      currentPage: 1,
    }
  },

  computed: {
    source() {
      if(this.pdfUrl) return this.pdfUrl;
      else return `data:application/pdf;base64,${this.pdfBase64}`;
    },
    page() {
      if(!this.showAllPages) return this.currentPage;
      else return null;
    },
  },

  methods: {
    onRender() {
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    onDownload() {
      this.$refs.pdfRef.download('summary');
    },
    onPrint() {
      this.$refs.pdfRef.print(300, 'summary', true);
    }
  },
}
</script>

<style lang="scss" scoped>
button {
  font-size: unset;
  letter-spacing: unset;
  line-height: unset;

  padding: 0.5rem;

  min-width: 2.5rem;
  min-height: 2.5rem;

  border-radius: 4px;
}

.controls {
  display: flex;
  justify-content: space-between;

  min-height: 2.5rem;
  height: 6%;

  margin-bottom: 1rem;
}

.paging-controls {
  display: flex;
  align-items: center;

  span {
    width: 38px;
    text-align: center;
  }
}

.viewer-wrapper {
  background-color: #ccc;

  padding: 1rem;

  height: 100%;
}

.viewer {
  overflow-y: scroll;

  min-height: 500px;
  height: 92%;

  margin-bottom: 1rem;
}

.embeded-pdf {
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>
