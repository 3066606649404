<template>
	<div class="short-plans">
		<h1></h1>
		<short-plan-box :plan="formattedPlan" :green-total="false" :start-open="true" />
	</div>
</template>

<script>
import ShortPlanBox from "@/components/elements/ShortPlanBox";

export default {
	name: "LegacyShortTermPlanView",

	components:{
		ShortPlanBox
	},

	props: {
		plan: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {}
	},

	computed: {
		formattedPlan() {
			return {
				...this.plan.current,
				statementLink: this.plan.current.statement_data.pdf_link,
				summaryLink: this.plan.current.statement_data.statement_link,
			}
		},
	},

	methods: {},

	mounted() {
		console.log(this.plan)
	}
}
</script>
<style lang="scss" scoped>
.short-plans{

}
</style>
