<template>
	<div class="value-statement">
		<h1>Employee Value Statement</h1>
		<a v-if="reportURL" class="value-statement-pdf" :href="reportURL" target="_blank">Full Report <small>(PDF)</small></a>
		<br-card>
			<h2>My Future: Year {{ lookingAtYear }}</h2>
			<range-element v-model="lookingAtYear" :field-min="lowYear" :field-max="highYear"></range-element>
		</br-card>
		<br-card v-if="activeYearData" class="uk-margin-small-top">
			<div v-if="activeYearData.totalRevenue || activeYearData.profit" class="value-statement-projections uk-margin-medium-bottom">
				<h2>Company Target Projections</h2>
				<div>
					<h3 class="muted">{{ revenueLabel }}</h3>
					<br-dollars>{{ $accounting.formatCents(activeYearData.totalRevenue) }}</br-dollars>
				</div>
				<div>
					<h3 class="muted">{{ profitLabel }}</h3>
					<br-dollars>{{ $accounting.formatCents(activeYearData.profit) }}</br-dollars>
				</div>
			</div>

			<h2>My Financial Opportunity</h2>
			<div class="value-statement-opportunity">
				<div v-if="conventionalEvs">
					<h3 class="muted">Annual Income</h3>
					<div>
						<div>
							<br-dollars>{{ $accounting.formatCents(activeYearData.annualIncome.total) }}</br-dollars>
						</div>
						<div>
							<div>
								<span class="value-statement-opportunity-label">Salary</span>
								<span class="value-statement-opportunity-value">
								${{ $accounting.formatCents(activeYearData.annualIncome.salary) }}
							</span>
							</div>
							<div>
								<span class="value-statement-opportunity-label">Short-Term Award</span>
								<span class="value-statement-opportunity-value">
								${{ $accounting.formatCents(activeYearData.annualIncome.shortAward) }}
							</span>
							</div>
							<div>
								<span class="value-statement-opportunity-label">Long-Term Award</span>
								<span class="value-statement-opportunity-value">
								${{ $accounting.formatCents(activeYearData.annualIncome.longAward) }}
							</span>
							</div>
						</div>
					</div>
				</div>
				<div v-if="conventionalEvs">
					<h3 class="muted">Cumulative Income</h3>
					<div>
						<div>
							<br-dollars>{{ $accounting.formatCents(activeYearData.cumulativeIncome.total) }}</br-dollars>
						</div>
						<div>
							<div>
								<span class="value-statement-opportunity-label">Salary</span>
								<span class="value-statement-opportunity-value">
									${{ $accounting.formatCents(activeYearData.cumulativeIncome.salary) }}
								</span>
							</div>
							<div>
								<span class="value-statement-opportunity-label">Short-Term Award</span>
								<span class="value-statement-opportunity-value">
									${{ $accounting.formatCents(activeYearData.cumulativeIncome.shortAward) }}
								</span>
							</div>
							<div>
								<span class="value-statement-opportunity-label">Long-Term Award</span>
								<span class="value-statement-opportunity-value">
									${{ $accounting.formatCents(activeYearData.cumulativeIncome.longAward) }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div>
					<h3 class="muted">Total LTIP value (end of year)</h3>
					<div>
						<div>
							<br-dollars>{{ $accounting.formatCents(activeYearData.totalLTIP.total) }}</br-dollars>
						</div>
						<div>
							<div>
								<span class="value-statement-opportunity-label">Vested</span>
								<span class="value-statement-opportunity-value">
									${{ $accounting.formatCents(activeYearData.totalLTIP.vested) }}
								</span>
							</div>
							<div>
								<span class="value-statement-opportunity-label">Unvested</span>
								<span class="value-statement-opportunity-value">
									${{ $accounting.formatCents(activeYearData.totalLTIP.unvested) }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div v-if="conventionalEvs">
					<h3 class="muted">Total Wealth Projection</h3>
					<div>
						<div>
							<br-dollars>{{ $accounting.formatCents(activeYearData.totalWealth.total) }}</br-dollars>
						</div>
						<div>
							<div>
								<span class="value-statement-opportunity-label">Cumulative income</span>
								<span class="value-statement-opportunity-value">
									${{ $accounting.formatCents(activeYearData.totalWealth.cumulative) }}
								</span>
							</div>
							<div>
								<span class="value-statement-opportunity-label">Total LTIP (end of year)</span>
								<span class="value-statement-opportunity-value">
									${{ $accounting.formatCents(activeYearData.totalWealth.totalLTIP) }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</br-card>
		<div class="value-statement-disclaimer">
			*All values shown are hypothetical. Actual results will vary from those presented here. This report does
			not imply any guarantee of employment or promise of awards or payments. The report should be used to
			forecast hypothetical values only.
		</div>
	</div>
</template>

<script>
import BrCard from "@/components/elements/BrCard";
import BrDollars from "@/components/elements/BrDollars";
import RangeElement from "@/components/elements/RangeElement";
export default {
	name: "ValueStatement",
	components:{
		BrCard,
		BrDollars,
		RangeElement,
	},
	data() {
		return {
			conventionalEvs: false,
			lookingAtYear: "",
			lowYear: 2021,
			highYear: 2022,
			profitLabel:"",
			revenueLabel:"",
			activeYearData:null,
			loadedYears:{},
			reportURL:null
		}
	},
	mounted() {
		!this.$dataDirectory.userData.isDemo ?
			this.init():null;
	},
	beforeDestroy() {
		this.$dataDirectory.isPostingFutureYear = true;
		this.$apiManager.post("plans/year/" + this.$dataDirectory.futureYear).then(() => {
			this.$dataDirectory.isPostingFutureYear = false;
		});
	},
	methods: {
		init(){
			this.loadYearData();
			this.$apiManager.get("value").then(
				(pack) => this.valuesReceived(pack),
				() => this.valuesFailure()
			);
		},
		valuesReceived(pack){
			if(pack.data.success){
				let dt = pack.data;

				this.conventionalEvs = dt.conventionalEvs;
				this.lowYear = dt.startingYear;
				this.highYear = dt.endingYear;
				this.reportURL = dt.report;
				this.loadedYears = {};
				let low = Number(this.lowYear);
				let high = Number(this.highYear);
				for(let i = 0; (i+low) <= high;++i){
					this.loadedYears[(i+low).toString()] = {
						totalRevenue:dt.totalRevenue[i],
						profit:dt.profit[i],
						annualIncome:{
							total:dt.annualIncome.total[i],
							salary:dt.annualIncome.salary[i],
							shortAward:dt.annualIncome.shortAward[i],
							longAward:dt.annualIncome.longAward[i]
						},
						cumulativeIncome:{
							total:dt.cumulativeIncome.total[i],
							salary:dt.cumulativeIncome.salary[i],
							shortAward:dt.cumulativeIncome.shortAward[i],
							longAward:dt.cumulativeIncome.longAward[i]
						},
						totalLTIP:{
							total:dt.totalLTIP.total[i],
							vested:dt.totalLTIP.vested[i],
							unvested:dt.totalLTIP.unvested[i]
						},
						totalWealth:{
							total:dt.totalWealth.total[i],
							cumulative:dt.totalWealth.cumulative[i],
							totalLTIP:dt.totalWealth.totalLongTerm[i]
						}
					}
				}
				this.lookingAtYear = this.$dataDirectory.futureYear ?
					this.$dataDirectory.futureYear:low.toString();
				this.revenueLabel = dt.revenueLabel;
				this.profitLabel = dt.profitLabel;
			}
		},
		valuesFailure(){},
		loadYearData(){
			if(this.loadedYears[this.lookingAtYear]){
				this.activeYearData = this.loadedYears[this.lookingAtYear];
				this.$dataDirectory.futureYear = this.lookingAtYear;
			} else if (this.lookingAtYear !== "") {
				console.warn("No active year data available for " + this.lookingAtYear);
			}
		}
	},
	watch:{
		lookingAtYear(){
			this.loadYearData(); //todo: add delay
		}
	}
}
</script>
<style lang="scss" scoped>
.value-statement{
	&-pdf{
		display: inline-block;
		font-size: 14px;
		margin-bottom: 3vw;
		text-decoration: none;
		&:hover{
			text-decoration: underline;
		}
		small{
			font-size: 8px;
		}
	}
	&-projections{
		display: grid;
		grid-template-columns: 24% 27% 27%;
		grid-column-gap: 5%;
		border-bottom: thin solid $color-divider;
		transition: grid-template-columns 0.5s;
		padding-bottom: 20px;
		&>div{
			background-color: $color-background-tertiary;
			padding: 0.75vw 1.5vw;
			border-radius: 15px;
		}
		@media screen and (max-width: 1200px){
			grid-template-columns: 24% 32% 32%;
		}
	}
	&-opportunity{
		display: grid;
		grid-template-columns: 47% 47%;
		grid-column-gap: 3%;
		margin-top: 40px;
		&>div>div{
			display: grid;
			grid-template-columns: 35% 60%;
			grid-column-gap: 5%;
			margin-bottom: 50px;
		}

		&-label{
			display: inline-block;
			margin-bottom: 10px;
			width: 70%;
		}
		&-value{
			font-family: $font-secondary;
			font-weight: 800;
		}

		@media screen and (max-width: 1200px){
			display: block;
			grid-template-columns: none;
			grid-column-gap: unset;
		}
	}
	&-disclaimer{
		color: $color-text-secondary;
		font-family: $font-secondary;
		font-size: 10px;
		margin-top: 15px;
	}
	h1{
		margin-bottom: 15px;
	}
}
</style>
