<template>
  <section class="plan-details">
    <h3>
      <QuestionBadge :id="'pendo-statement-plan-details'">PLAN DETAILS</QuestionBadge>
    </h3>
    <plan-card>
      <!-- Description -->
      <br-accordion title="Description">
        <p>{{ summary.description.description }}</p>
      </br-accordion>

      <!-- Timing -->
      <br-accordion title="Timing">
        <p>{{ summary.timing.description }}</p>
      </br-accordion>

      <!-- Eligibility -->
      <br-accordion title="Eligibility">
        <p>{{ summary.eligibility.description }}</p>
        <br>
        <p>{{ summary.eligibility.label }}</p>
        <br>
        <ul>
          <template v-for="[key, value] in Object.entries(summary.eligibility.items)">
            <li v-if="value.mode != 'prorate'" :key="key">{{ value.message }}</li>
          </template>
        </ul>
      </br-accordion>

      <!-- Proration Settings -->
      <br-accordion title="Proration Settings">
        <p>{{ summary.proration.label }}</p>
        <br>
        <ul>
          <template v-for="[key, value] in Object.entries(summary.proration.items)">
            <li v-if="value.mode == 'prorate'" :key="key">{{ value.message }}</li>
          </template>
        </ul>
      </br-accordion>

      <!-- Individual Performance -->
      <br-accordion title="Individual Performance">
        <p>{{ summary.individual.description }}</p>
      </br-accordion>

      <!-- Payout Frequency -->
      <br-accordion title="Payout Frequency">
        <p>{{ summary.timing.description }}</p>
      </br-accordion>

      <br-accordion v-if="hasHoldback" title="Holdback">
        <p v-if="plan.holdback.payout_count === 4">Your plan withholds a percentage of your award payment for the first 3 quarters of the plan year (Q1-Q3). The holdback percentage does not apply to the final payment (Q4). Your plan holdback percentage is <span style="font-weight:700;">{{ plan.holdback.percent | percent(2) }}</span>.</p>
        <p v-else-if="plan.holdback.payout_count === 3">Your plan withholds a percentage of your award payment for the first 2 trimesters of the plan year (T1-T2). The holdback percentage does not apply to the final payment (T3). Your plan holdback percentage is <span style="font-weight:700;">{{ plan.holdback.percent | percent(2) }}</span>.</p>
        <p v-else-if="plan.holdback.payout_count === 2">Your plan withholds a percentage of your award payment for the first half of the plan year (SA1). The holdback percentage does not apply to the final payment (SA2). Your plan holdback percentage is <span style="font-weight:700;">{{ plan.holdback.percent | percent(2) }}</span>.</p>
      </br-accordion>
    </plan-card>
  </section>
</template>

<script>
import BrAccordion from "@/components/plans/global/BrAccordion.vue"
import PlanCard from "@/components/plans/global/PlanCard.vue"
import QuestionBadge from "@/components/pendo/QuestionBadge.vue";

export default {
  name: "PlanDetails",

  components: {
    QuestionBadge,
    BrAccordion,
    PlanCard,
  },

  props: {
    summary: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {}
  },

  computed: {
    hasHoldback() {
      return this.plan?.holdback?.mode === 'enabled';
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/_variables.scss";

.plan-details {

  h3 {
    font-weight: bold;
    font-size: $text-md;
  }

  .accordion {
    &:not(:last-child) {
      border-bottom: thin solid $color-divider;
    }
  }

}
</style>