<template>
	<select v-if="linkList && linkList.length > 0" class="select-field" @change="showStatement">
		<option value="">Previous Statements</option>
		<option v-for="item in linkList" :value="item.statementLink" :key="item.statementLink">{{ item.date }}</option>
	</select>
</template>

<script>
export default {
	name: "OldStatements",

	components: {},
	props:{
		linkList: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data() {
		return {};
	},

	mounted() {

	},

	methods: {
		showStatement(){
			if(this.$el.value !== ""){
				window.open(this.$el.value, '_blank').focus();
				this.$el.value = "";
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.select-field{
	display: inline-block;
	margin-bottom: 8px;
	text-align: left;
	font-family: $font-primary;
	border: 2px solid $color-field-border;
	border-radius: 5px;
	color: $color-text-primary;
	display: inline-block;
	float: right;
	margin-top: 5px;
	padding:10px;

	&:focus{
		outline: none;
		border-bottom: 2px solid #479dea;
	}
}
</style>
