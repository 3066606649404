<template>
	<div class="range-field" @mousedown="startListeners">
		<div ref="rail" class="range-field-rail"></div>
		<div ref="fill" class="range-field-rail-fill"></div>
		<svg ref="slider" class="range-field-slider" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
			<g id="Handle" transform="translate(4 4)" fill="#fff" stroke="#241b89" stroke-width="4">
				<circle cx="8.5" cy="8.5" r="8.5" stroke="none"/>
				<circle cx="8.5" cy="8.5" r="10.5" fill="none"/>
			</g>
		</svg>
		<div ref="labels" class="range-field-labels"></div>
	</div>
</template>

<script>
export default {
	name: "RangeElement",
	props:{
		value: {
			type: [Number,String],
			value: 0
		},
		fieldMin:{
			type: Number,
			value: 0
		},
		fieldMax: {
			type: Number,
			value: 10
		}
	},
	watch: {
		value(v){
			this.targetValue = v;
			this.moveToValue(v);
		},
		currentValue(){
			this.updateValue();
		},
		fieldMin(){
			this.setLabels();
			if(this.targetValue){
				this.moveToValue(this.targetValue);
			}
		},
		fieldMax(){
			this.setLabels();
			if(this.targetValue){
				this.moveToValue(this.targetValue);
			}
		},
	},
	data() {
		return {
			fieldData: 0,
			values:[],
			currentValue: 0,
			boundFindValue: null,
			boundStopListeners: null,
			resizeTime: null,
			targetValue: null
		}
	},
	mounted() {
		this.boundFindValue = this.findValue.bind(this);
		this.boundStopListeners = this.stopListeners.bind(this);
		this.setLabels();
		this.moveToValue(this.value);
		window.addEventListener("resize", () => this.adjustSize())
	},
	methods: {
		setLabels(){
			this.$refs.labels.innerHTML = "";
			this.values = [];
			for(let i = this.fieldMin; i <= this.fieldMax;++i){
				this.$refs.labels.innerHTML += "<span>"+i+"</span>";
				this.values.push(i);
			}
		},
		startListeners(event){
			this.$el.addEventListener("mousemove", this.boundFindValue);
			this.$el.addEventListener("mouseup", this.boundStopListeners);
			this.$el.addEventListener("mouseout", this.boundStopListeners);
			this.findValue(event);
		},
		stopListeners(){
			this.$el.removeEventListener("mousemove", this.boundFindValue);
			this.$el.removeEventListener("mouseup", this.boundStopListeners);
			this.$el.removeEventListener("mouseup", this.boundStopListeners);
		},
		findValue(event){
			let width = this.$refs.rail.getBoundingClientRect().width;
			let percentPosition = event.offsetX/width;
			let valueId = Math.round(percentPosition*(this.values.length-1));
			this.moveToId(valueId);
		},
		moveToValue(val){
			for(let i = 0; i < this.values.length;++i){
				if(val == this.values[i]){
					this.moveToId(i);
					return;
				}
			}
		},
		moveToId(valueId){
			let width = this.$refs.rail.getBoundingClientRect().width;
			let segmentWidth = Math.floor(width/(this.values.length-1))-3;
			if(valueId >= this.values.length-1){
				valueId = this.values.length-1;
			}
			if(this.values[valueId]){
				let spotPosition = segmentWidth*valueId;
				if(valueId >= this.values.length-1){
					spotPosition = width-24;
				}
				this.$refs.slider.style.left = spotPosition + "px";
				if(spotPosition > 0){
					this.$refs.fill.style.width = (spotPosition + 12) + "px";
					this.$refs.fill.style.opacity = 1;
				} else {
					this.$refs.fill.style.width = 0;
					this.$refs.fill.style.opacity = 0;
				}
				this.currentValue = this.values[valueId];
			}
		},
		updateValue(){
			this.$emit('input', this.currentValue);
		},
		adjustSize(){
			this.resizeTime = (new Date()).getTime();
			setTimeout(() => this.adjustBuffered(), 250);

		},
		adjustBuffered(){
			let testTime = (new Date()).getTime();
			if(testTime - this.resizeTime > 250){
				this.moveToValue(this.value);
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.range-field{
	position: relative;
	width: 100%;
	&-slider{
		pointer-events: none;
		position: absolute;
		top: 0;
		transition: left 0.5s;
	}
	&-rail{
		background-color: lighten($color-card-border, 5%);
		border-radius: 20px;
		height: 12px;
		pointer-events: none;
		position: relative;
		top: 7px;
		width: 100%;
		&-fill{
			background-color: $color-heading-background;
			border-radius: 20px;
			height: 12px;
			opacity: 0;
			pointer-events: none;
			position: relative;
			top: -5px;
			width: 0;
			transition: width 0.5s, opacity 0.5s;
		}
	}
	&-labels{
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
		pointer-events: none;
		position: relative;
		width: 100%;
		span{
			pointer-events: none;
		}
	}
}
</style>
