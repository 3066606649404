<template>
  <div class="header">
    <div class="title">
      <div>
        <h1>Plan Summary</h1>
        <h2>{{ title }}</h2>
        <div v-if="link.length"><a :href="link" target="_blank">Summary Plan Description</a></div>
      </div>
      <div v-if="message" class="message">
        {{ message }}
      </div>
    </div>

    <div class="info-wrap">

      <!-- Salary Info -->
      <div class="info-grid info-salary">
        <span style="margin-right:1rem;"><QuestionBadge :id="'pendo-current-base-pay'" :right="-20">Annual Base Pay:</QuestionBadge></span>
        <span>{{ salary.current | currency }}</span>
        <span style="margin-right:1rem;"><QuestionBadge :id="'pendo-plan-base-pay'" :right="-20">Plan Base Pay:</QuestionBadge></span>
        <span>{{ salary.plan | currency }}</span>
      </div>

      <!-- Plan Dates Info -->
      <div class="info-grid info-dates">
        <span>Plan Start Date:</span>
        <span>{{ dates.start | date }}</span>
        <span>Plan End Date:</span>
        <span>{{ dates.end | date }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionBadge from "@/components/pendo/QuestionBadge.vue";

export default {
  name: "PlanHeader",
  components: {QuestionBadge},

  props: {
    title: {
      type: String,
      required: true,
    },
    salary: {
      type: Object,
      required: true,
    },
    dates: {
      type: Object,
      required: true,
    },
    isEos: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      required: false,
      default: "",
    }
  },

  computed: {},

  mounted() {},
}
</script>

<style lang="scss" scoped>
.header {
  .title {
    display: flex;
    justify-content: space-between;

    margin-bottom: 0.5rem;

    h1 {
      font-size: $text-xl; // ~ 32px
      line-height: 1.0625;
      font-weight: 700;
      margin-bottom: 0;
    }
    h2 {
      font-size: 1.85rem;
      text-transform: capitalize;
    }

    .message {
      min-width: 400px;
      width: 50%;

      border: 1px solid var(--charcoal-tint-20);
      border-radius: 8px;
      padding: 0.5rem 1rem;
    }

    .info-grid {
      font-size: $text-base;
      line-height: 1.6875;
    }
  }

  .info-wrap {
    display: flex;
    justify-content: space-between;

    font-size: $text-base;
    line-height: 1.6875;
  }

  .info-grid {
    display: grid;
    grid-template-columns: min-content min-content;
    grid-auto-rows: min-content;

    grid-column-gap: 0.5rem;

    span {
      white-space: nowrap;
      text-align: right;

      // info title
      &:nth-child(odd) {
        font-family: $font-primary;
      }

      // info content
      &:nth-child(even) {}
    }
  }

  .info-salary {
    margin-top: auto;

    text-transform: capitalize;
  }
}

</style>