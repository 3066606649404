<template>
	<div class="first-login">
		<terms-form :display-terms="!termsAccepted" @terms-accepted="termsAccepted=true"></terms-form>
		<transition name="fade">
			<div class="first-login-verify" v-if="termsAccepted">
				<input-field
					v-model="email"
					:editable="false">
					Email
				</input-field>
				<password-field
					field-type="password"
					v-model="password">
					Password
				</password-field>
				<password-field
					field-type="password"
					v-model="verifyPassword">
					Verify Password
				</password-field>
				<standard-button @click="register">Login</standard-button>
			</div>
		</transition>
		<vk-modal v-if="showError" :show.sync="showError">
			<vk-modal-title>Registration Failed</vk-modal-title>
			<div>{{ errorMessage }}</div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
	</div>
</template>

<script>
import StandardButton from "@/components/elements/StandardButton";
import InputField from "@/components/elements/InputField";
import PasswordField from "@/components/elements/PasswordField";
import TermsForm from "./TermsForm";
export default {
	name: "FirstLogin",
	components: {
		InputField,
		PasswordField,
		StandardButton,
		TermsForm
	},
	data() {
		return {
			email:null,
			code:null,
			termsAccepted: false,
			username: "",
			password: "",
			verifyPassword: "",
			showError: false,
			errorMessage: "XXX"
		}
	},
	mounted() {
		this.email = this.$route.params.email;
		this.code = this.$route.params.code;
	},
	methods: {
		register(){
			if(this.password === this.verifyPassword){
				this.$apiManager.post("first-login", {
					email: this.email,
					code:this.code,
					password: this.password
				}).then(
					(pack) => this.registrationComplete(pack),
					() => this.registrationFailed()
				);
			} else {
				this.errorMessage = "Password and password confirmation do not match.";
				this.showError = true;
			}
		},
		registrationComplete(pack){
			if(pack.data && pack.data.success){
				this.$apiManager.token = pack.data.token;
				if(pack.data.adminUrl){
					this.$apiManager.adminUrl = pack.data.adminUrl;
				}
				this.$apiManager.get("user").then(
					(pack) => this.userDataSuccess(pack),
					() => this.dataFailure(),
				);
			} else {
				this.registrationFailed();
			}

		},
		userDataSuccess(pack){
			if(pack.data && pack.data.success){
				this.$dataDirectory.userData = pack.data;
				this.$router.push("/dashboard");
			} else {
				this.dataFailure();
			}
		},
		registrationFailed(){
			this.errorMessage = "There was an issue communicating with the server to set up your account. Please try again later.";
			this.showError = true;
		},
		dataFailure(){
			this.errorMessage = "There was an issue communicating with the server. Please try again later.";
			this.showError = true;
		},
	}
}
</script>
<style lang="scss" scoped>
.first-login{

	&-verify{
		width: 500px;
		height: 300px;
		position: fixed;
		top:calc(50vh - 150px);
		left: calc(50vw - 250px);
		-webkit-box-shadow: 0px 0px 8px 2px rgba(46,46,46,0.65);
		box-shadow: 0px 0px 8px 2px rgba(46,46,46,0.65);
		border-radius: 15px;
		text-align: center;
		padding: 50px;
	}
}
.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>