<template>
  <div v-if="selected">
    <div class="header">
      <div class="title">
        <h1>Long-Term Plan</h1>
        <h2>{{ selected.plan_name }}</h2>
        <div v-if="spd_link"><a :href="spd_link" target="_blank">Summary Plan Description</a></div>
      </div>

      <div v-if="summary_message" class="message">
        {{ summary_message }}
      </div>
    </div>

    <!-- toolbar -->
    <PlanToolbar class="toolbar" :pdf="selected.pdf_link"/>

    <!-- overview -->
    <PlanCard class="overview-card">
      <div class="overview">
        <!-- Current/Forecast Header -->
        <div class="summary-header">
          <h2 class="sidebar-header">Current</h2>
          <h2 class="overview-header">Forecasted</h2>
        </div>
        <!-- Sidebar -->
        <div class="sidebar">
          <div class="grant-groups-container">
            <div class="grant-groups-header">
              <div>
                <h3><QuestionBadge :id="'pendo-overview-phantom-share-price'" :right="-12">Phantom&nbsp;Share<br>Redemption&nbsp;Price</QuestionBadge></h3>
                <p class="text-highlight text-center font-bold text-md">{{ selected.current_phantom_share_price | currency(2) }}</p>
              </div>
              <h3><QuestionBadge :id="'pendo-overview-current-vested-unvested'" :right="-16">Vested&nbsp;Value&nbsp;/<br>Unvested&nbsp;Value</QuestionBadge></h3>
            </div>
            <div class="grant-groups-body">
              <template v-for="(certificate, index) in selected.certificates">
                <div :key="`${index}`">
                  <a v-if="certificate.use_certificate" class="grant-group-name" :href="getCertificateRoute(certificate)" target="_blank">{{ certificate.name }}</a>
                  <span v-else>{{ certificate.name }}</span>
                  <p class="grant-group-values">{{ certificate.vested | currency }}<br>{{ certificate.unvested | currency }}</p>
                </div>
              </template>
            </div>
            <div class="grant-groups-footer">
              Click on any link above to view your award.<br/>Click <span style="font-weight:bold;font-size:1.125rem;"><a :href="getStatementRoute()" target="_blank" class="font-bold text-md">here</a></span> to see your plan statement.
            </div>
          </div>

          <div class="totals">
              <h3>Vested</h3>
              <p>{{ selected.vested_value | currency }}</p>

              <h3>Unvested</h3>
              <p>{{ selected.unvested_value | currency }}</p>

              <h3>Paid&nbsp;to&nbsp;date</h3>
              <p>{{ selected.cumulative_redemptions | currency }}</p>

              <h3>Total&nbsp;Plan&nbsp;Value</h3>
              <p>{{ (selected.cumulative_redemptions + selected.vested_value + selected.unvested_value) | currency }}</p>
          </div>
        </div>
        <!-- Graph Header -->
        <div class="header" :class="[isCIC ? 'grid-cols-2' : 'grid-cols-3']">
          <div>
            <h3><QuestionBadge :id="'pendo-overview-next-payment-date'" :right="-4">Next<br>Maturity</QuestionBadge></h3>
            <p class="text-highlight text-center font-bold text-md">
              <template v-if="isCIC">Change In Control</template>
              <template v-else>{{ selected.next_payment_date | date }}</template>
            </p>
          </div>
          <div v-if="!isCIC">
            <h3><QuestionBadge :id="'pendo-overview-forecasted-payment'" :right="-22">Forecasted<br>Payment</QuestionBadge></h3>
            <p class="text-highlight text-center font-bold text-md">{{ selected.forecasted_payment | currency }}</p>
          </div>
          <div>
            <h3><QuestionBadge :id="'pendo-overview-total-plan-value'" :right="-22">Plan&nbsp;Value<br>(EOY)</QuestionBadge></h3>
            <p>{{ selected.total_plan_value | currency }}</p>
          </div>
        </div>
        <!-- Graph -->
        <div class="graph-content">
          <template v-if="userDataLoaded">
            <LongTermLineChart :only-target="clientIsDiy" :history="selected.graph_data" />
          </template>
        </div>
      </div>
    </PlanCard>

    <!-- shares -->
    <div class="shares">
      <h2>TOTAL PLAN VALUE FORECAST</h2>
      <p>Total Plan Value includes Vested Plan Value, Unvested Value, and Cumulative Redemptions paid to date. The forecast displays potential non-guaranteed awards in future years. Actual awards will be determined annually by the company.</p>
      <p v-if="!clientIsDiy" style="margin-top:0.5rem;">Use the drop-down menus to select the performance forecast and award type (if applicable) you would like to view.</p>

      <PlanCard class="shares-card">
        <!-- header -->
        <div class="shares-header">
          <template v-if="!clientIsDiy">
            <div>
              <h3>Award<br>Type</h3>
              <select v-model="selected_award_type" style="text-transform:capitalize;">
                <option v-for="group_index in computedAwardGroups" :value="group_index" :key="group_index" style="text-transform:capitalize;">{{ selected.projections[group_index][selected_tier].grant_group_name }}</option>
              </select>
            </div>
            <div>
              <h3>Performance<br>Forecast</h3>
              <select v-model="selected_tier" style="text-transform:capitalize;">
                <option v-if="showBase" value="base" style="text-transform:capitalize;">{{ selected.projections[selected_award_type].base.tier_label }}</option>
                <option v-if="showTarget" value="target" style="text-transform:capitalize;">{{ selected.projections[selected_award_type].target.tier_label }}</option>
                <option v-if="showSuperior" value="superior" style="text-transform:capitalize;">{{ selected.projections[selected_award_type].superior.tier_label }}</option>
              </select>
            </div>
          </template>
        </div>

        <!-- body -->
        <div class="body">
          <table>
            <thead>
              <tr>
                <th></th>
                <th v-for="year in Object.keys(planValueForecast.total_plan_value)" :key="year">{{ year }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="planValueForecast.formula_value">
                <td><QuestionBadge :id="'pendo-forecast-formula-value'">Formula Value</QuestionBadge></td>
                <td v-for="[key, value] in Object.entries(planValueForecast.formula_value)" :key="`${key}-${value}`">{{ value | currency }}</td>
              </tr>
              <tr v-if="planValueForecast.total_phantom_shares">
                <td><QuestionBadge :id="'pendo-forecast-phantom-shares'">Total Phantom Shares</QuestionBadge></td>
                <td v-for="[key, value] in Object.entries(planValueForecast.total_phantom_shares)" :key="`${key}-${value}`">{{ value | number }}</td>
              </tr>
              <tr v-if="planValueForecast.redemption_price">
                <td><QuestionBadge :id="'pendo-forecast-redemption-price'">Redemption Price (EOY)</QuestionBadge></td>
                <td v-for="[key, value] in Object.entries(planValueForecast.redemption_price)" :key="`${key}-${value}`">{{ value | currency(2) }}</td>
              </tr>
              <tr v-if="planValueForecast.issue_price && selected.separate_redemption_share_price">
                <td><QuestionBadge :id="'pendo-forecast-issue-price'">Issue Price (EOY)</QuestionBadge></td>
                <td v-for="[key, value] in Object.entries(planValueForecast.issue_price)" :key="`${key}-${value}`">{{ value | currency(2) }}</td>
              </tr>
              <tr class="border-t">
                <td><QuestionBadge :id="'pendo-forecast-annual-awards'">Annual Awards</QuestionBadge></td>
                <td v-for="[key, value] in Object.entries(planValueForecast.annual_grants)" :key="`${key}-${value}`">{{ value | number }}</td>
              </tr>
              <tr>
                <td><QuestionBadge :id="'pendo-forecast-projected-redemptions'">Projected Redemptions</QuestionBadge></td>
                <td v-for="[key, value] in Object.entries(planValueForecast.annual_distributions)" :key="`${key}-${value}`">{{ value | currency }}</td>
              </tr>
              <tr>
                <td><QuestionBadge :id="'pendo-forecast-cumulative-redemptions'">Cumulative Redemptions</QuestionBadge></td>
                <td v-for="[key, value] in Object.entries(planValueForecast.cumulative_distributions)" :key="`${key}-${value}`">{{ value | currency }}</td>
              </tr>
              <tr>
                <td><QuestionBadge :id="'pendo-forecast-vested-eoy'">Vested Value (EOY)</QuestionBadge></td>
                <td v-for="[key, value] in Object.entries(planValueForecast.vested_value)" :key="`${key}-${value}`">{{ value | currency }}</td>
              </tr>
              <tr>
                <td><QuestionBadge :id="'pendo-forecast-unvested-eoy'">Unvested Value (EOY)</QuestionBadge></td>
                <td v-for="[key, value] in Object.entries(planValueForecast.unvested_value)" :key="`${key}-${value}`">{{ value | currency }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td><QuestionBadge :id="'pendo-forecast-total-plan-value'">Total Plan Value</QuestionBadge></td>
                <td v-for="[key, value] in Object.entries(planValueForecast.total_plan_value)" :key="`${key}-${value}`">{{ value | currency }}</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <!-- footer -->
        <div class="footer">
          <p>*Be advised that all projections presented in this Plan Summary are based on a hypothetical company forecast. Actual company performance will certainly vary from the forecast, and the future value of your awards will be higher or lower than shown.</p>
        </div>
      </PlanCard>
    </div>
  </div>
</template>

<script>
import PlanToolbar from "@/components/plans/global/PlanToolbar.vue";
import PlanCard from "@/components/plans/global/PlanCard.vue";
import LongTermLineChart from "@/components/plans/long-term/charts/LongTermLineChart.vue";
import UtilsMixin from "@/mixins/UtilsMixin";
import QuestionBadge from "@/components/pendo/QuestionBadge.vue";
import ColorsMixin from "@/mixins/ColorsMixin";

export default {
  name: "LongTermPlanView",

  mixins: [ UtilsMixin, ColorsMixin ],

  components: {
    QuestionBadge,
    LongTermLineChart,
    PlanCard,
    PlanToolbar
  },

  props: {
    plan: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selected: null,

      selected_award_type: null,
      selected_tier: 'target',
    }
  },

  computed: {
    isCIC() {
      return this.selected?.payment_events?.change_in_control && !this.selected?.payment_events?.fixed_date
    },
    userDataLoaded() {
      return this.$dataDirectory?.userDataLoaded
    },
    userData() {
      return this.$dataDirectory?.userData
    },
    computedAwardGroups() {
      if(this.selected?.grant_group_indexes.length === 2) {
        return [this.selected.grant_group_indexes[0]]
      }
      else {
        return this.selected.grant_group_indexes
      }
    },
    planValueForecast() {
      return this.selected.projections[this.selected_award_type][this.selected_tier]
    },
    clientStyles() {
      if (this.userData?.client_styles) return JSON.parse(this.userData.client_styles)
      else return null
    },
    summary_message() {
      return this.plan.current?.summary_message;
    },
    spd_link() {
      return this.selected?.spd_link;
    },
    showBase() {
      return this.selected?.option_parameters?.show_base ?? false
    },
    showTarget() {
      return this.selected?.option_parameters?.show_target ?? false
    },
    showSuperior() {
      return this.selected?.option_parameters?.show_superior ?? false
    },
  },

  methods: {
    getCertificateRoute(certificate) {
      return this.$apiManager.route(`grant-certificate/${certificate.granted_shares_id}`);
    },
    getStatementRoute() {
      let plan_id = this.plan?.current.summary_data.plan_id;
      let route = this.$apiManager.route(`plan-statement/${plan_id}`);

      return `${route}/?plan_id=${plan_id}`;
    },
    updateClientColors() {
      // TODO: rework this once a state management system is in place
      let highlight = this.getHighlightColor();

      let root_element = document.querySelector(':root')
      root_element.style.setProperty('--highlight-color', highlight)

      let tint = 0.8

      let brandColor = this.getBrandColor();
      let brandColorTint = this.getBrandTintColor(tint);

      root_element.style.setProperty('--brand-color', brandColor)
      root_element.style.setProperty('--brand-color-tint', brandColorTint)

      let foregroundColor = this.getForegroundColor();

      root_element.style.setProperty('--foreground-color', foregroundColor)
    },
  },

  beforeCreate() {},

  mounted() {
    this.updateClientColors();

    this.selected = this.plan?.current.summary_data
    this.selected_award_type = this.plan?.current.summary_data.grant_group_count === 1 ? 0 : this.plan?.current.summary_data.grant_group_count
  },

  watch: {
    clientStyles: {
      handler: function () {
        this.updateClientColors()
      },
      deep: true
    }
  },
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 0.5rem;

  .title {

    h1 {
      font-size: $text-xl; // ~ 32px
      line-height: 1.0625;
      font-weight: 700;
      margin-bottom: 0;
    }
    h2 {
      font-size: 1.85rem;
      text-transform: capitalize;
    }
  }

  .message {
    min-width: 400px;
    width: 50%;

    border: 1px solid var(--charcoal-tint-20);
    border-radius: 8px;
    padding: 0.5rem 1rem;
  }
}

h3 {
  font-size: $text-base;
}

.text-highlight {
  color: var(--highlight-color);
}

.font-bold {
  font-weight: bold;
}

.text-md {
  font-size: $text-md;
}

.text-center {
  text-align: center;
}

.toolbar {
  margin-bottom: 1rem;
}

.overview-card {
  margin-bottom: 4rem;
}

.overview {
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  width: 100%;

  .header {
    grid-area: 2 / 3 / 3 / 8;

    display: grid;

    grid-gap: 1rem;

    padding: 1.5rem;

    border-bottom: 1px solid $charcoal-tint-20;

    &>div {
      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      &:last-child {
        justify-content: end;
      }
    }

    h3 {
      text-align: center;
      font-weight: bold;
      font-size: $text-base;

      margin-bottom: 0.25rem;
    }
    p {
      color: var(--highlight-color);
      font-weight: bold;
      font-size: $text-md;
    }
  }

  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .graph-content {
    grid-area: 3 / 3 / 4 / 8;

    height: 560px;
    width: 100%;

    padding: 1.5rem;
  }

  .summary-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-area: 1 / 1 / 2 / 8;
    background-color: var(--brand-color);

    .sidebar-header {
      grid-area: 1 / 1 / 2 / 3;      
    }

    .overview-header {
      grid-area: 1 / 3 / 2 / 8;      
    }

    h2 {
      color: var(--foreground-color);
      text-align: center;
      margin-top: 0.5vw;
    }
  }

  .sidebar {
    grid-area: 2 / 1 / 4 / 3;

    padding: 1.5rem;

    border-right: 1px solid $charcoal-tint-20;

    display: flex;
    flex-direction: column;

    .grant-groups-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;

      border-bottom: 1px solid $charcoal-tint-20;

      .grant-groups-header {
        display: flex;
        justify-content: space-between;

        padding-bottom: 1.5rem;
        margin-bottom: 0.75rem;

        border-bottom: 1px solid $charcoal-tint-20;


        &>div {
          display: flex;
          flex-direction: column;

          justify-content: center;
          align-items: center;

          &:last-child {
            justify-content: end;
          }
        }

        h3 {
          font-size: $text-base;

          margin-bottom: 0.25rem;
          text-align: center;
          font-weight: bold;
        }
      }

      .grant-groups-body {
        display: flex;
        flex-direction: column;

        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;

        max-height: 38vh;

        div {
          display: grid;
          grid-template-columns: 3fr 2fr;
          grid-column-gap: 1rem;

          margin-bottom: 0.5rem;

          height: 100%;
          width: 100%;

          .grant-group-name {
            font-weight: bold;
            font-size: $text-base;
          }

          .grant-group-values {
            font-size: $text-base;
            text-align: right;
            padding-right: 1em;
          }
        }
      }

      .grant-groups-footer {
        text-align: center;
        margin-top: auto;
      }
    }

    .totals {
      display: grid;
      grid-template-columns: 1fr 2fr;

      grid-column-gap: 1.5rem;
      grid-row-gap: 0.25rem;

      h3 {
        display: flex;
        justify-content: end;
        align-items: center;

        margin: 0;

        font-weight: bold;
        font-size: $text-base;
      }

      p {
        color: var(--highlight-color);
        font-weight: bold;
        font-size: $text-md;

        text-align: right;
        padding-right: 1rem;
      }
    }

    .grant-certificates {
      display: grid;
      grid-template-rows: 15% 70% 15%;

      grid-row-gap: 0.4rem;

      height: 100%;

      h3 {
        font-weight: bold;
        font-size: $text-base;

        margin-bottom: 0;
      }

      .certificate-links {
        display: flex;
        flex-direction: column;

        font-size: $text-md;

        a {
          font-size: $text-base;
          margin-bottom: 0.6rem;
        }
      }

      .pagination {
        display: flex;
        justify-content: end;
        font-size: $text-sm;

        button {
          border: none;
          border-radius: 5px;
          background: none;
          padding: 0 0.5rem;
          cursor: pointer;

          &:hover {
            background-color: $charcoal-tint-20;
          }
        }

        div {
          margin: 0 0.75rem;
        }
      }
    }
  }
}

.shares {
  .shares-card {
    padding: 1.5rem;
    margin-top: 1rem;

    .shares-header {
      display: flex;
      margin-bottom: 0.5rem;

      div {
        margin-right: 1rem;

        h3 {
          text-align: center;
          margin-bottom: 0.25rem;
        }

        select {
          width: 100%;
        }
      }

      div:last-child {
        //margin-left: auto;
        margin-right: 0;
      }
    }

    .body {
      margin-bottom: 1.5rem;

      overflow: auto;
      scrollbar-width: thin;
    }
  }
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width:100%;

  tr {
    th, td {
      text-align: right;

      background: var(--pure-white);
      width: 160px;

      padding: 0.25rem 0.75rem;
    }

    > :first-child {
      text-align: left;

      left: 0;
      z-index: 1;
      position: sticky;
      width: 240px;
    }

    &:not(:first-of-type), &:not(:last-of-type) {
      td:first-of-type {
        border-left: 1px solid $charcoal-tint-20;
      }
    }
  }

  thead {
    tr {
      font-weight: bold;

      th {
        text-align: center;
      }
    }
  }

  tbody {
    border-top: 1px solid $charcoal-tint-20;
    border-bottom: 1px solid $charcoal-tint-20;

    tr {
      td {
        border-right: 1px solid $charcoal-tint-20;
      }
    }
  }

  tfoot {
    tr {
      font-weight: bold;

      > :not(:first-child) {
        color: var(--highlight-color);
      }
    }
  }
}

.border-t {
  border-top: 1px solid $charcoal-tint-20;
}
</style>