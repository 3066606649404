<template>
	<div class="message-board">
		<h3>Message Board</h3>
		<button class="link message-board-clear" type="button" @click="markAllRead">Clear All</button>

		<transition-group name="bounce">
			<br-message v-for="msg in importantMessages" :key="'im_'+msg.id" @read="markRead" :message="msg"></br-message>
		</transition-group>
		<transition name="bounce">
			<hr v-if="importantMessages.length > 0" />
		</transition>
		<transition-group name="bounce">
			<br-message v-for="msg in regularMessages" :key="'ir_'+msg.id" @read="markRead" :message="msg"></br-message>
		</transition-group>
		<transition name="bounce">
			<div v-if="importantMessages.length <= 0 && regularMessages.length <= 0 && !loadingMessages && firstCheckDone" >
				All messages have been read.
			</div>
		</transition>
		<transition name="bounce">
			<div v-if="loadingMessages" >
				Checking messages.
			</div>
		</transition>
		<div ref="scrollPoint"></div>
	</div>
</template>

<script>
import BrMessage from "@/components/elements/BrMessage";
export default {
	name: "BrMessages",
	components:{
		BrMessage
	},
	data() {
		return {
			userData:null,
			messages:[],
			importantMessages: [],
			regularMessages: [],
			messageOffset: 0,
			pageLength: 5,
			totalMessages: -1,
			loadingMessages: false,
			firstCheckDone: false,
			scrollFunction: null,
		}
	},
	mounted() {
		this.userData = this.$dataDirectory.userData;
		this.requestMessages();
		this.scrollFunction = (e) => this.checkMessages(e);
		//window.addEventListener("scroll", this.scrollFunction);
	},
	destroyed() {
		//window.removeEventListener("scroll",this.scrollFunction);
	},
	methods: {
		requestMessages(){
			this.loadingMessages = true;
			this.$apiManager.get("messages/" + this.messageOffset +"/" + this.pageLength).then(
				(pack) => this.receivedMessages(pack),
				() => this.requestFailed()
			);
		},
		receivedMessages(pack){
			setTimeout(() => {

			}, 500);
			this.loadingMessages = false;
			this.firstCheckDone = true;
			if(pack.data.success){
				this.totalMessages = pack.data.totalCount;
				for(let i = 0; i < pack.data.messages.length;++i){
					this.messages.push(pack.data.messages[i]);
					pack.data.messages[i].important ?
						this.importantMessages.push(pack.data.messages[i]):
						this.regularMessages.push(pack.data.messages[i]);
				}
				this.$forceUpdate();
			}


		},
		checkMessages(event = null){
			if(!this.loadingMessages && this.messages.length < this.totalMessages &&
				(!event || event.dir === "down")){
				//check if our scroll point is in the window
				let boxPoints = this.$refs.scrollPoint.getBoundingClientRect();
				if(boxPoints.bottom < window.innerHeight){
					this.messageOffset += this.pageLength;
					this.requestMessages();
				}

			}
		},
		markRead(msgId){
			this.$apiManager.post("messages/read/" + msgId).then(
				() => this.requestSucceeded(),
				() => this.requestFailed()
			);
			for(let i = 0; i < this.importantMessages.length;++i){
				if(this.importantMessages[i].id == msgId){
					this.importantMessages.splice(i, 1);
					this.messageOffset--;
				}
			}
			for(let i = 0; i < this.regularMessages.length;++i){
				if(this.regularMessages[i].id == msgId){
					this.regularMessages.splice(i, 1);
					this.messageOffset--;
				}
			}
		},
		markAllRead(){
			this.importantMessages = [];
			this.regularMessages = [];
			this.$apiManager.post("messages/read/all").then(
				() => this.requestSucceeded(),
				() => this.requestFailed()
			);
		},
		requestSucceeded(){
			if(this.totalMessages > this.messages.length){
				this.messageOffset = this.messages.length;
				this.pageLength = 1;
				this.requestMessages();
			}
		},
		requestFailed(){

		}
	}
}
</script>
<style lang="scss" scoped>
.message-board{

	h3{
		display: inline-block;
	}

	hr{
		border-top: thin solid $color-divider;
		border-bottom: none;
		margin-bottom: 1vw;
	}

	&-clear{
		float: right;
	}

	.bounce-enter-active {
		animation: bounce-in .5s;
	}
	.bounce-leave-active {
		animation: bounce-in .5s reverse;
	}
	@keyframes bounce-in {
		0% {
			transform: scale(0);
		}
		50% {
			transform: scale(1.1);
		}
		100% {
			transform: scale(1);
		}
	}
}
</style>
