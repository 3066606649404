window.axios = require('axios');

import Vue from 'vue';
import App from './App.vue';
import DataDirectory from "@/classes/DataDirectory";
import ApiManager from "@/classes/ApiManager";
import Accounting from "@/classes/Accounting";
import Vuikit from 'vuikit';
import '@vuikit/theme';
import router from './router.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// Filters
import * as Filters from '@/filters'

// Fontawesome
Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(faDollarSign);
library.add(faTimes);

Vue.config.productionTip = false;
Vue.prototype.$apiManager = window.apiManager = new ApiManager();
Vue.prototype.$dataDirectory = new DataDirectory();
Vue.prototype.$accounting = new Accounting();
Vue.use(Vuikit);

// Import global filters
Object.values(Filters).forEach((filter) => {
  Vue.use(filter)
})

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
