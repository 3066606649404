<template>
	<div class="contact">
		<header class="page-header">
			<h1>Contact Plan Administrator</h1>
			<p>Please do not send sensitive or confidential data through this messaging system.</p>
		</header>
		<br-card>
			<h2 class="uk-margin-medium-bottom">Send us a message</h2>
			<input-field v-model="subject" :use-wide="true">Subject</input-field>
			<text-field v-model="message" :use-wide="true">Message</text-field>
			<standard-button class="uk-float-right" @click="submitForm">Send</standard-button>
			<span class="uk-clearfix"></span>
			<vk-modal v-if="showThanksMessage" :show.sync="showThanksMessage">
				<vk-modal-title>Thank you for contacting us!</vk-modal-title>
				<p>{{ thanksResponse }}</p>
				<vk-modal-close></vk-modal-close>
			</vk-modal>
			<vk-modal v-if="showFailMessage" :show.sync="showFailMessage">
				<vk-modal-title>Something Went Wrong</vk-modal-title>
				<p>Something went wrong with sending your message. Please try again later.</p>
				<vk-modal-close></vk-modal-close>
			</vk-modal>
			<vk-modal v-if="showIncompleteMessage" :show.sync="showIncompleteMessage">
				<vk-modal-title>Message Incomplete</vk-modal-title>
				<p>Please provide both a subject and a message.</p>
				<vk-modal-close></vk-modal-close>
			</vk-modal>
		</br-card>
	</div>
</template>

<script>
import BrCard from "@/components/elements/BrCard";
import InputField from "@/components/elements/InputField";
import TextField from "@/components/elements/TextField";
import StandardButton from "@/components/elements/StandardButton";
export default {
	name: "BrContact",
	components:{
		BrCard,
		InputField,
		TextField,
		StandardButton
	},
	data() {
		return {
			fullName:"",
			email:"",
			subject:"",
			message:"",
			showThanksMessage:false,
			showFailMessage: false,
			showIncompleteMessage: false,
			thanksResponse: "Your message has been sent to the plan success manager. We will get back to you within 4-5 business days."
		}
	},
	mounted() {
		!this.$dataDirectory.userData.isDemo ?
			this.init():null;
	},
	methods: {
		init(){
			this.fullName = this.$dataDirectory.userData.firstName + " " + this.$dataDirectory.userData.lastName;
			this.email = this.$dataDirectory.userData.email
		},
		submitForm(){
			if(this.subject.length > 0 && this.message.length > 0){
				this.$apiManager.post("contact", {
					fullName: this.fullName,
					email: this.email,
					subject: this.subject,
					message: this.message
				}).then(
					(pack) => this.messageSent(pack),
					() => this.messageFailed()
				)
			} else {
				this.showIncompleteMessage = true;
			}
		},
		messageSent(pack){
			if(pack.data && pack.data.success){
				this.showThanksMessage = true;
				this.thanksResponse = pack.data.response;
				this.subject = "";
				this.message = "";
			}
		},
		messageFailed(){
			this.showFailMessage = true;
		}
	}
}
</script>
<style lang="scss" scoped>
.page-header{
	margin-bottom: 3vw;

	h1{
		margin-bottom: 0px;
	}


	h1 + *{
		margin-top: 10px;
	}
}

.contact{
	.input-field-field{
		width: 80%;
	}
}

.uk-modal-title{
	color: $color-text;
	font-family: $font-primary;
}
</style>
