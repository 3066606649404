<template>
	<div v-if="message" class="message" :class="{'message-important':message.important}">
		<svg class="message-close" :class="{'invert':message.important}" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" @click="readMessage">
			<path id="Icon_material-close" data-name="Icon material-close" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" />
		</svg>
		<div v-if="message.date" class="message-date_line">
			{{ displayDate }}
		</div>
		<h5>
			<svg v-if="message.important" class="invert message-flag" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
				<path id="Icon_material-error-outline" data-name="Icon material-error-outline" d="M11,15h2v2H11Zm0-8h2v6H11Zm.99-5A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" transform="translate(-2 -2)" />
			</svg>
			{{ message.title }}
		</h5>
		<p class="message-from">From: {{ message.from }}</p>
		<p>{{ summary }}</p>
		<button class="link" type="button" @click.prevent="showMessage" >Expand</button>

		<vk-modal v-if="showFullMessage" :show.sync="showFullMessage">
			<vk-modal-title>{{ message.title }}</vk-modal-title>
			<div v-html="contentCopy"></div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
	</div>
</template>

<script>
import Moment from "moment";
export default {
	name: "BrMessage",
	props: {
		message: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			showFullMessage: false,
			displayDate: "",
			contentCopy: ""
		}
	},
	computed:{
		summary() {
                        let result = this.message.content.replace(/(<([^>]+)>)/gi, "");
                        if(result.indexOf(".") !== -1) {
				let sentences = result.split(".");
				result = sentences[0] + ".";
			}
			return result;
		}
	},
	mounted() {
		let dDate = new Moment(this.message.date);
		this.displayDate = dDate.format("DD MMMM YYYY");
		this.contentCopy = this.message.content.replace(/(\r\n)|(\\r)/g, "</p><p>");
		this.contentCopy = "<p>" + this.contentCopy + "</p>";
	},
	methods: {
		readMessage(){
			this.$emit("read", this.message.id);
		},
		showMessage(){
			this.showFullMessage = true;
		},
		hideMessage(){
			this.showFullMessage = false;
		}
	}
}
</script>
<style lang="scss" scoped>
	.message{
		border: thin solid $color-card-border;
		border-radius: 5px;
		padding: 0.75vw;
		background-color: $color-background;
		margin-bottom: 1vw;
		position: relative;

		&.message-important{
			background-color: $color-header-primary;
			color: $color-background;
			h5,p{
				color: $color-background;
			}

			.link{
				color: $color-background;
				&:hover{
					color: darken($color-background, 20%);
				}
			}
		}
		&-date_line{
			color: $color-text-secondary;
			font-size: 13px;
			margin-bottom: 10px;
		}

		&-close{
			position: absolute;
			top: 0.75vw;
			right: 0.75vw;
			cursor: pointer;
		}

		&-flag{
			margin-right: 8px;
			position: relative;
		}

		&-from{
			font-size: 12px;
			margin-bottom: 6px;
		}

		.link{
			display: inline-block;
			margin-top: 1.2em;
		}

		svg{
			fill: $color-text-primary;
			&.invert{
				fill: $color-background;
			}
		}

		h5{
			color: $color-text-secondary;
			margin-bottom: 10px;
		}

		p{
			font-size: 13px;
			line-height: 1.5em;
			color: $color-text;
		}
	}
</style>
