<template>
	<div v-if="!loading && plan">
		<template v-if="!isLegacyStatement">
			<ShortTermPlanView :plan="plan"/>
		</template>
		<template v-else>
			<LegacyShortTermPlanView :plan="plan"/>
		</template>

		<vk-modal v-if="showErrorMessage" :show.sync="showErrorMessage">
			<vk-modal-title>Communication Error</vk-modal-title>
			<div>
				<p>There was an error connecting to the server.</p>
				<p>We were unable to obtain the data for this page at this time. Please try again later.</p>
			</div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
	</div>
</template>

<script>
import ShortTermPlanView from "@/components/pages/ShortTermPlanView.vue";
import LegacyShortTermPlanView from "@/components/pages/LegacyShortTermPlanView.vue";

export default {
	name: "ShortTerm",

	components:{
		LegacyShortTermPlanView,
		ShortTermPlanView
	},

	data() {
		return {
			showErrorMessage: false,
			loading: false,
			plan: null,
		}
	},

	computed: {
		isLegacyStatement() {
			if(this.plan?.current?.statement_version) {
				return false
			}
			return true
		},
		plan_id() {
			return this.$route.params.id
		},
	},

	methods: {
		getStatements() {
			this.loading = true
			this.$apiManager.get(`plans/stip/${this.plan_id}`)
				.then((result) => {
					this.plan = result.data
				})
				.catch((error) => {
					console.error(error)
				})
				.finally(() => {
					this.loading = false
				})
		},
	},

	mounted() {
		this.getStatements()
	},

	watch: {
		plan_id: {
			handler() {
				this.getStatements()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
</style>
