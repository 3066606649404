<template>
	<div class="not-found">
		404: Page not found.
	</div>
</template>

<script>
export default {
	name: "PageNotFound",
	data() {
		return {}
	},
	mounted() {

	},
	methods: {}
}
</script>
<style lang="scss" scoped>
.not-found{
	padding-top: 40vh;
	text-align: center;
	font-family: $font-primary;
	font-size: 2vw;
}
</style>