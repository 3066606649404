<template>
  <div ref="fs" class="forecast-scale">
    <template v-if="userDataLoaded">
      <div class="forecast-label">
        <div>
          <span class="value">
            <template v-if="isEos && distributions.length">{{ distributions[distributions.length - 1].forecasted_annual_award | currency }}</template>
            <template v-else>{{ forecast.adjusted | currency }}</template>
          </span>
          <span class="label">{{ forecast.label }}</span>
        </div>
      </div>
      <div id="slider" class="slider"></div>
      <div class="slider-axis">
        <div class="axis-labels" :class="detailsClass">
          <div v-for="tier in tiers" :class="`tier-label-${tier.key}`" :key="`tier-label-${tier.key}`">
            <span class="label">{{ tier.label }}</span>
            <span class="value">{{ tier.value | currency }}</span>
            <div class="client-results" v-if="!client.hide_client_results">
              <template v-if="client.performance.mode === 'revenue-margin'">
                <div>
                  <span>{{ client.performance.current.revenue.label }}</span>
                  <span>{{ client.performance.current.revenue[tier.key] | currency }}</span>
                </div>
                <div>
                  <span>{{ client.performance.current.margin.label }}</span>
                  <span>{{ client.performance.current.margin[tier.key] | percent(2) }}</span>
                </div>
              </template>
              <div>
                <span>{{ client.performance.current.profit.label }}</span>
                <span>{{ client.performance.current.profit[tier.key] | currency }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import UtilsMixin from "@/mixins/UtilsMixin";
import ColorsMixin from "@/mixins/ColorsMixin";

export default {
  name: "ForecastScale",

  mixins: [ UtilsMixin, ColorsMixin ],

  components: {},

  props: {
    tiers: {
      type: Object,
      required: true,
    },
    forecast: {
      type: Object,
      required: true,
    },
    distributions: {
      type: Array,
      required: false,
    },
    client: {
      type: Object,
      required: true,
    },
    isEos: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      triangleWidth: 40,
      min: 0,
      max: 4,
    }
  },

  computed: {
    performanceZone() {
      let forecast_value = this.forecast.adjusted
      if(this.isEos && this.distributions.length) {
        forecast_value = this.distributions[this.distributions.length - 1].forecasted_annual_award
      }

      switch (true) {
        case (forecast_value < this.tiers.base.value):
          return 5
        case (forecast_value === this.tiers.base.value):
          return (10 * (forecast_value / this.tiers.base.value))
        case (forecast_value <= this.tiers.target.value && forecast_value > this.tiers.base.value ):
          return (10 + (40 * ((forecast_value - this.tiers.base.value) / (this.tiers.target.value - this.tiers.base.value))))
        case (forecast_value <= this.tiers.superior.value && forecast_value > this.tiers.target.value):
          return (50 + (40 * ((forecast_value - this.tiers.target.value) / (this.tiers.superior.value - this.tiers.target.value))))
        case (forecast_value > this.tiers.superior.value):
          return 95
        default:
          return 7
      }
    },
    forecastPosition() {
      return this.performanceZone
    },
    detailsClass() {
      if(this.client.hide_client_results || !this.client.performance.current) return ""
      if(this.client.performance.mode === "revenue-margin") return "details-profit-height"
      return "details-height"
    },
    userDataLoaded() {
      return this.$dataDirectory?.userDataLoaded
    },
    clientStyles() {
      if (this.userDataLoaded && this.$dataDirectory?.userData?.client_styles) return JSON.parse(this.$dataDirectory.userData.client_styles)
      else return null
    },
  },

  methods: {
    updateForecastPosition() {
      this.$el.style.setProperty('--forecast-position', `${this.forecastPosition}%`)
    },
    updateClientColors() {
      let highlight_color = this.getHighlightColor()
      let brand_color = this.getBrandColor()
      let brand_tint_color = this.getBrandTintColor()

      this.$el.style.setProperty('--highlight-color', highlight_color)
      this.$el.style.setProperty('--slider-brand-color', brand_color)
      this.$el.style.setProperty('--slider-brand-color-tint', brand_tint_color)
    },
  },

  watch: {
    forecastPosition: function() {
      this.updateForecastPosition()
    },
    clientStyles: function() {
      this.updateClientColors()
    }
  },

  mounted() {
    this.updateClientColors()
    this.updateForecastPosition()
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/_variables.scss";

.forecast-scale {
  --mask-top: 28.6%;
  --triangle-width: 40px;
  --triangle-base: calc(var(--triangle-width) / 2); // half the width of the caret

  --forecast-position: 0%;

  --triangle-center: var(--forecast-position);
  --triangle-left: calc(var(--triangle-center) - var(--triangle-base));
  --triangle-right: calc(var(--triangle-center) + var(--triangle-base));

  padding: 1.2rem 3.6rem;

  .forecast-label {
    position: relative;

    width: 100%;
    height: 4rem;

    div {
      position: absolute;
      left: var(--forecast-position);

      transform: translateX(-50%);

      display: inline-flex;
      flex-direction: column;

      align-items: center;

      .label {
        margin-right: unset;
      }
    }
  }

  .slider {
    position: relative;

    width: 100%;
    height: 35px;

    margin-bottom: 1.334rem;

    clip-path: polygon(
            0 100%,
            0 var(--mask-top),
            var(--triangle-left) var(--mask-top),
            var(--triangle-center) 0,
            var(--triangle-right) var(--mask-top),
            100% var(--mask-top),
            100% 100%
    );

    background: linear-gradient(90deg, var(--slider-brand-color-tint) 0%, var(--slider-brand-color) 100%);
  }

  .slider-axis {
    display: flex;
    justify-content: center;

    width: 100%;

    .axis-labels {
      position: relative;
      width: 100%;
      height: 3.65rem;

      .tier-label-base,
      .tier-label-target,
      .tier-label-superior
      {
        display: flex;
        flex-direction: column;
        align-items: center;

        position: absolute;
        top: 0;
        transform: translateX(-50%);
      }

      .tier-label-base {
        left: 10%;
      }

      .tier-label-target {
        left: 50%;
      }

      .tier-label-superior {
        left: 90%;
      }

      .client-results {
        margin-top: 0.25em;

        div {
          display: flex;
          justify-content: space-between;
          white-space: nowrap;

          &>span:first-child {
            text-transform: capitalize;
            margin-right: 1em;
          }

          &>span:last-child {
            color: $color-light-grey-500;
          }
        }
      }
    }

    .details-height {
      height: 6.625em;
    }

    .details-profit-height {
      height: 8.625em;
    }
  }

  .label {
    font-weight: bold;
    font-family: $font-primary;
    font-size: $text-base;
    white-space: nowrap;

    text-transform: capitalize;
  }

  .value {
    color: var(--highlight-color);
    font-family: $font-secondary;
    font-weight: 800;
    font-size: $text-lg;
  }
}
</style>