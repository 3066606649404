<template>
	<label class="input-field">
		<span class="input-field-label"><slot></slot></span>
		<input
			v-if="editable"
			ref="dataField"
			:type="fieldType"
			class="input-field-field"
			:class="{'input-field-field_wide':useWide}"
			:value="value"
			@input="updated"
		/>
		<span v-if="!editable" class="input-field-content">{{ value }}</span>
	</label>
</template>

<script>
export default {
	name: "InputField",
	props: {
		fieldType: {
			type: String,
			default: "text"
		},
		value: {
			type: [String, Number],
			default: null
		},
		updateWhenMounted: {
			type: Boolean,
			default: false
		},
		tip:{
			type: String,
			default:null
		},
		editable:{
			type: Boolean,
			default: true
		},
		useWide:{
			type: Boolean,
			default: false
		}
	},
	watch: {

	},
	data() {
		return {

		}
	},
	mounted() {

	},
	methods: {
		updated(){
			this.$emit("input", this.$refs.dataField.value);
		}
	}
}
</script>
<style lang="scss" scoped>
.input-field{
	display: inline-block;
	margin-bottom: 8px;
	text-align: left;
	width: 100%;
	&-label{
		display: inline-block;
		padding-top: 14px;
		font-family: $font-primary;
		font-size: 13px;
	}
	&-field{
		border: 2px solid #e0e0e0;
		border-radius: 5px;
		color: $color-text-primary;
		display: inline-block;
		float: right;
		margin-top: 5px;
		padding:10px;
		width: 70%;
		font-size: 14.5px;
		height: 3rem;
		&.input-field-field_wide{
			width: 85%;
		}
	
		&:focus{
			outline: none;
			border-bottom: 2px solid #479dea;
		}
	}
	&-content{
		color: $color-text-primary;
		display: inline-block;
		float: right;
		font-size: 14px;
		padding-top: 10px;
		text-align: right;
	}
	input{
		font-family: $font-secondary;
	}
}
</style>
