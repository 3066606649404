<template>
  <details class="accordion" :open="open">
    <summary class="accordion-header" @click.prevent="open = !open">
      <span class="title"><slot name="title">{{ title }}</slot></span>
      <span class="icon" :class="{isOpen: open}">
        <slot name="icon">
          <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.32938 19.5L11 10.7162" stroke="#1F3A4D" stroke-width="3" stroke-linecap="round"/>
            <path d="M2.32938 19.5L11 10.7162" stroke="#1F3A4D" stroke-width="3" stroke-linecap="round"/>
            <path d="M2.32938 19.5L11 10.7162" stroke="#1F3A4D" stroke-width="3" stroke-linecap="round"/>
            <line x1="2.44973" y1="1.56829" x2="10.7113" y2="10.3798" stroke="#1F3A4D" stroke-width="3" stroke-linecap="round"/>
            <line x1="2.44973" y1="1.56829" x2="10.7113" y2="10.3798" stroke="#1F3A4D" stroke-width="3" stroke-linecap="round"/>
            <line x1="2.44973" y1="1.56829" x2="10.7113" y2="10.3798" stroke="#1F3A4D" stroke-width="3" stroke-linecap="round"/>
          </svg>
        </slot>
      </span>
    </summary>
    <div class="accordion-content">
      <slot name="default">
        <div v-html="content"></div>
      </slot>
    </div>
  </details>
</template>

<script>
export default {
  name: "BrAccordion",

  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      open: false,
    }
  },

  computed: {},

  methods: {},

}
</script>

<style lang="scss" scoped>
.isOpen {
  transform: rotate(90deg);
}

.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 50px;
    width: 100%;

    padding-inline: 20px;

    .title {
      font-family: $font-primary;
      font-size: $text-base;
    }

    .icon {
      transition: all 0.08s ease-in-out;
    }
  }

  .accordion-content {
    width: 100%;

    padding: 20px;

    //border-top: thin solid $color-divider;
  }
}
</style>