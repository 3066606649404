<template>
	<div v-if="userData" class="main-header" ref="header">
		<img v-if="hasLogo" ref="logo" class="main-header-logo" @load="positionLogo" :src="userData.clientLogo" alt="company logo" />
		<div ref="companyName" class="main-header-company">{{ userData.company }}</div>
		<div class="main-header-user">
<!--			<img ref="thumbImage" class="main-header-user-image" :src="imageHead" alt="user image" />-->
			<span ref="nameField" class="user-name">{{ userData.firstName }} {{ userData.lastName }}</span>
		</div>
		<div ref="menu" class="user-menu">
			<standard-button @click="logout" >Logout</standard-button>
		</div>
	</div>
</template>

<script>
import StandardButton from "./elements/StandardButton";
import UtilsMixin from "@/mixins/UtilsMixin"

export default {
	name: "BrHeader",
    mixins:[UtilsMixin],
    components:{
		StandardButton
	},
	data() {
		return {
			userData: null,
			imageHead: "/img/blankman.png",
			showMenu: true,
		}
	},
	mounted() {
		this.$dataDirectory.checkLogin(
			() => this.dataAvailable(),
			() => this.dataNotLoaded()
		);
		this.userData = this.$dataDirectory.userData;

		this.$nextTick(() => {
			this.updateClientColors()
		})
	},
	methods: {
		dataAvailable(){
			this.userData = this.$dataDirectory.userData;
			// setTimeout(() => this.positionImage(), 2);
			setTimeout(() => this.positionMenu(), 2);
			//calls init so page data updates correctly from a refresh
			if(this.$parent
				&& this.$parent.$refs
				&& this.$parent.$refs.page
				&& this.$parent.$refs.page.init){
				this.$parent.$refs.page.init();
			}
			this.imageHead = this.userData.smallImage !== "" ? this.userData.smallImage : "/img/blankman.png";
		},
		updateData(){
			this.userData = this.$dataDirectory.userData;
			this.imageHead = this.userData.smallImage !== "" ? this.userData.smallImage : "/img/blankman.png";
			// setTimeout(() => this.positionImage(), 2);
			setTimeout(() => this.positionMenu(), 2);
		},
		dataNotLoaded(){
			this.$router.push("/login");
		},
		// positionImage(){
		// 	let nameWidth = this.$refs.nameField.getBoundingClientRect().width;
		// 	// this.$refs.thumbImage.style.right = (nameWidth+65) + "px";
		// },
		positionLogo(){
			let imgWidth = 0;
			if(this.$refs.logo){
				imgWidth = this.$refs.logo.getBoundingClientRect().width;
			}
			this.$refs.companyName.style["margin-left"] = (imgWidth + 20) + "px";
		},
		positionMenu(){
			let nameBlock = this.$refs.nameField.getBoundingClientRect();
			this.$refs.menu.style.left = nameBlock.left + "px";
			if(this.showMenu){
				this.$refs.menu.style.top = (nameBlock.bottom) + 6 + "px";
			} else {
				this.$refs.menu.style.top = "-50px";
			}
		},
		logout(){
			this.$apiManager.post("logout", {}).then(
				() => this.logoutProceed(),
				() => this.logoutProceed(),
			);
		},
		logoutProceed(){
			this.$apiManager.token = null;
			this.$apiManager.adminUrl = null;
			this.$dataDirectory.userData = {
				env_code: "",
				employee_id: 0,
				client_id: 0,
				firstName: "",
				lastName: "",
				image: "https://via.placeholder.com/500",
				smallImage: "https://via.placeholder.com/100",
				clientLogo: "https://via.placeholder.com/75x30",
				company: "",
				position: "",
				username: "",
				email:"",
				success: false,
				isDemo: true
			};
			this.$router.push("/login");
		},
		updateClientColors() {
            // TODO: this needs to be redone, it would be good to use something like Pinia or Vuex to manage this...

			if(this.styles) {
                if(this.exists(this.styles?.heading_background_red)) {
                    let r = this.styles.heading_background_red
                    let g = this.styles.heading_background_green
                    let b = this.styles.heading_background_blue

                    let headerColor = `rgb(${r} ${g} ${b})`

                    this.$refs.header.style.setProperty('background-color', headerColor)
                }

                if(this.exists(this.styles?.heading_foreground_red)) {
                    let r = this.styles.heading_foreground_red
                    let g = this.styles.heading_foreground_green
                    let b = this.styles.heading_foreground_blue

                    let foregroundColor = `rgb(${r} ${g} ${b})`

                    this.$refs.companyName.style.setProperty('color', foregroundColor)
                }
			}
		},
	},
	computed:{
    styles() {
        if(this.userData?.client_styles) return JSON.parse(this.userData.client_styles)
        return null
    },
		hasLogo(){
			if(this.userData){
				return this.userData.clientLogo && this.userData.clientLogo !== "";
			}
			return false;
		},
	},
	watch: {
		styles: {
			handler: function() {
				this.updateClientColors()
			},
			deep: true
		},
	}
}
</script>

<style lang="scss" scoped>
    @import '../scss/_variables.scss';

	.main-header{
		background-color: $emerald;
		color: $color-background;
		min-height: 60px;
		padding: 13px 22px;
		position: relative;
		&-user{
			position: absolute;
			right: 90px;
			top: 20px;

			&-image{
				border-radius: 50%;
				max-width: 100px;
				position: absolute;
				right: 90px;
				top: -8px;
			}
		}
		&-logo{
			height: 60px;
			position: absolute;
			top: 0;
			left: 22px;
		}
		&-company{
			display: inline-block;
			margin-top: 5px;
		}
	}
	.user-name{
		cursor: pointer;
	}
	.user-menu{
		position: absolute;
	}
</style>
