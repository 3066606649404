<template>
	<label class="image-edit">
		<input ref="image_file" accept="image/*" type="file" @change="imageUpdated" />
		<span class="image-edit-button">
			<span class="image-edit-button-icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
					<g id="Icon_feather-camera" data-name="Icon feather-camera" transform="translate(0 -2)">
						<path id="Path_2822" data-name="Path 2822" d="M23,19a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V8A2,2,0,0,1,3,6H7L9,3h6l2,3h4a2,2,0,0,1,2,2Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
						<path id="Path_2823" data-name="Path 2823" d="M16,13a4,4,0,1,1-4-4,4,4,0,0,1,4,4Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					</g>
				</svg>
			</span>
			Edit Profile Picture
		</span>
	</label>
</template>

<script>
export default {
	name: "ImageEditButton",
	data() {
		return {}
	},
	mounted() {

	},
	methods: {
		imageUpdated(){
			this.$emit("update", this.$refs.image_file.files[0]);
		}
	}
}
</script>
<style lang="scss" scoped>
.image-edit{
	input{
		display: none;
	}
	&-button{
		position: relative;
		padding: 8px 25px;
		padding-left: 54px;
		color: $color-button-primary;
		border: thin solid $color-button-primary;
		background-color: $color-background;
		border-radius: 3px;
		font-size: 14px;
		cursor: pointer;
		text-decoration: none;
		font-family: $font-primary;
		&-icon{
			background-color: $color-button-primary;
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			padding: 4px 6px 8px;
		}
		&:hover{
			color: $color-button-hover;
			border: thin solid $color-button-hover;
			background-color: $color-background;
			.image-edit-button-icon{
				background-color: $color-button-hover;
			}
		}
	}

}

</style>