<template>
	<div class="percentage-bar" :class="{'percentage-bar_min':totalValue <= 1}">
		<template v-if="totalValue > 1">
			<div class="percentage-bar-part" v-for="(val, i) in dataValues" :key="'pb-'+i" :style="'width: '+((val/totalValue)*100)+'%'" >

			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: "PercentageBar",
	props: {
		dataValues: {
			type: Array,
			default: null
		}
	},
	data() {
		return {
			totalValue: 1
		}
	},
	watch:{
		dataValues(v){
			this.calculateTotal(v);
		}
	},
	mounted() {
		if(this.dataValues){
			this.calculateTotal(this.dataValues);
		}
	},
	methods: {
		calculateTotal(v){
			let total = 0;
			for(let i = 0; i < v.length;++i){
				total += v[i];
			}
			this.totalValue = total;
		}
	}
}
</script>
<style lang="scss" scoped>
.percentage-bar{
	width: 100%;
	height: 28px;
	overflow: hidden;
	transition: width 0.5s;
	&_min{
		width: 1%;
	}

	&-part{
		height: 28px;
		background-color: black;
		display: inline-block;
		transition: width 0.5s;
		&:nth-child(1){
			background-color: $color-card-border-green;
		}
		&:nth-child(2){
			background-color: $color-card-border-orange;
		}
		&:nth-child(3){
			background-color: $color-card-border-blue;
		}
	}
}
</style>