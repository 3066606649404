import { render, staticRenderFns } from "./BrTerms.vue?vue&type=template&id=4f6fafbb&scoped=true&"
import script from "./BrTerms.vue?vue&type=script&lang=js&"
export * from "./BrTerms.vue?vue&type=script&lang=js&"
import style0 from "./BrTerms.vue?vue&type=style&index=0&id=4f6fafbb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f6fafbb",
  null
  
)

export default component.exports