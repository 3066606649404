<template>
	<div v-if="userData" class="account">
		<h1>My Account</h1>
		<div class="account-sections">
			<br-card class="account-sections-info">
				<!--img class="account-sections-info-photo" :src="userData.image" / -->
				<div class="account-sections-info-section">
					<h2>{{ userData.firstName }} {{ userData.lastName }}</h2>
					<p>{{ userData.position }}</p>
				</div>
<!--				<div v-if="shortPlans.length > 0" class="account-sections-info-section">-->
<!--					<h3 class="uk-float-left">Short-Term Plans </h3>-->
<!--					<router-link class="link uk-float-right" to="/short-term">View Details</router-link>-->
<!--					<div class="uk-clearfix"></div>-->
<!--					<p v-for="(plan, i) in shortPlans" :key="'splan_'+i"> {{ plan.title }}</p>-->
<!--				</div>-->
<!--				<div v-if="longPlans.length > 0" class="account-sections-info-section">-->
<!--					<h3 class="uk-float-left">Long-Term Plans </h3>-->
<!--					<router-link class="link uk-float-right" to="/long-term">View Details</router-link>-->
<!--					<div class="uk-clearfix"></div>-->
<!--					<p v-for="(plan, i) in longPlans" :key="'lplan_'+i">{{ plan.title }}</p>-->
<!--				</div>-->
				<div class="account-sections-info-section">
					<h3 class="uk-float-left">Beneficiaries</h3>
					<a v-if="!editBeneficiaries" class="link uk-float-right" @click="openBeneficiaries" >Edit</a>
					<div class="uk-clearfix"></div>
					<h4 v-if="editBeneficiaries">Primary <span @click="addPrimaryBeneficiaries">+</span></h4>
					<beneficiary-form
						v-for="(pr, i) in primaryBeneficiaries"
						:is-editable="editBeneficiaries"
						:value="pr"
						@updated="(beneficiary) => updatePrimary(i, beneficiary)"
						@deleted="deletePrimary(i)"
						:key="'f_' + i"
					></beneficiary-form>
					<h4 v-if="editBeneficiaries">Secondary <span @click="addSecondaryBeneficiaries">+</span></h4>
					<beneficiary-form
						v-for="(se, j) in secondaryBeneficiaries"
						:is-editable="editBeneficiaries"
						:value="se"
						@updated="(beneficiary) => updateSecondary(j, beneficiary)"
						@deleted="deleteSecondary(j)"
						:key="'s_' + j"
					></beneficiary-form>
					<div v-if="editBeneficiaries" class="account-sections-edit-save" >
						<standard-button :is-light="true" style="margin-right: 8px;" @click="cancelBeneficiaryChanges">Cancel</standard-button>
						<standard-button @click.prevent="submitBeneficiaries">Save</standard-button>
					</div>
				</div>
			</br-card>
			<br-card class="account-sections-edit">
				<div class="account-sections-edit-controls">
					<image-edit-button class="uk-float-left" @update="uploadImage" />
					<button v-if="!editUser" class="link" type="button" @click.prevent="switchEdit">Edit Account Details</button>
				</div>
				<input-field
					:editable="false"
					v-model="userData.firstName"
				>
					First Name
				</input-field>
				<input-field
					:editable="false"
					v-model="userData.lastName"
				>
					Last Name
				</input-field>
				<password-field
					:editable="editUser"
					v-model="currentPassword"
					place-holder-text="Current Password"
				>
					Password
				</password-field>
				<password-field
					v-if="editUser"
					:editable="editUser"
					v-model="newPassword"
					place-holder-text="New Password"
				>
				</password-field>
				<password-field
					v-if="editUser"
					:editable="editUser"
					v-model="confirmPassword"
					place-holder-text="Confirm New Password"
				>
				</password-field>
				<input-field
					:editable="false"
					v-model="userData.email"
				>
					Username
				</input-field>
				<div v-if="editUser" class="account-sections-edit-save" >
					<standard-button :is-light="true" style="margin-right: 8px;" @click="cancelEdit">Cancel</standard-button>
					<standard-button @click="submitChanges">Save</standard-button>
				</div>
			</br-card>
		</div>
		<vk-modal v-if="showError" :show.sync="showError">
			<vk-modal-title>Update Failed</vk-modal-title>
			<div>There was a problem updating your account information. Please try again later.</div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
		<vk-modal v-if="showImageError" :show.sync="showImageError">
			<vk-modal-title>Update Failed</vk-modal-title>
			<div>There was a problem updating your image. Please try again later.</div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
		<vk-modal v-if="showPasswordMismatchError" :show.sync="showPasswordMismatchError">
			<vk-modal-title>Passwords do not match</vk-modal-title>
			<div>Your new password and password confirmation do not match.</div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
		<vk-modal v-if="showIncorrectCurrentPasswordError" :show.sync="showIncorrectCurrentPasswordError">
			<vk-modal-title>Incorrect current password</vk-modal-title>
			<div>The current password you entered is incorrect.</div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
		<vk-modal v-if="showBeneficiaryError" :show.sync="showBeneficiaryError">
			<vk-modal-title>{{ beneficiaryTitleError }}</vk-modal-title>
			<div>{{ beneficiaryError }}</div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
		<vk-modal v-if="showErrorMessage" :show.sync="showErrorMessage">
			<vk-modal-title>Communication Error</vk-modal-title>
			<div>
				<p>There was an error connecting to the server.</p>
				<p>We were unable to obtain the data for this page at this time. Please try again later.</p>
			</div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
	</div>
</template>

<script>
import BrCard from "@/components/elements/BrCard";
import InputField from "@/components/elements/InputField";
import PasswordField from "@/components/elements/PasswordField";
import ImageEditButton from "@/components/elements/ImageEditButton";
import StandardButton from "@/components/elements/StandardButton";
import BeneficiaryForm from "@/components/elements/BeneficiaryForm";
export default {
	name: "BrAccount",
	components:{
		BeneficiaryForm,
		BrCard,
		InputField,
		ImageEditButton,
		StandardButton,
		PasswordField
	},
	data() {
		return {
			userData: null,
			editUser: false,
			currentPassword: null,
			newPassword: null,
			confirmPassword: null,
			states:[ "AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI",
				"ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE",
				"NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD",
				"TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY" ],
			showError: false,
			showImageError: false,
			showPasswordMismatchError: false,
			showIncorrectCurrentPasswordError: false,
			primaryBeneficiaries: [],
			secondaryBeneficiaries: [],
			savedPrimaryBeneficiaries: [],
			savedSecondaryBeneficiaries: [],
			editBeneficiaries: false,
			showBeneficiaryError:false,
			beneficiaryTitleError:"Incorrect Beneficiary Percentages",
			beneficiaryError:"",
			showErrorMessage: false,
			shortPlans:[],
			longPlans:[],
			holdon:null,
		}
	},
	mounted() {
		!this.$dataDirectory.userData.isDemo ?
			this.init():null;
	},
	methods: {
		init(){
			this.userData = {...this.$dataDirectory.userData};

			this.$apiManager.get("user/beneficiaries").then(
				(pack) => this.beneficiaryData(pack),
				() => this.dataFailure()
			);
			this.$apiManager.get("plans/short-term/0/10").then(
				(pack) => this.shortPlansReceived(pack),
				() => this.plansDataFailure()
			);

			this.$apiManager.get("plans/long-term/0/10").then(
				(pack) => this.longPlansReceived(pack),
				() => this.plansDataFailure()
			);
		},
		switchEdit(){
			this.editUser = !this.editUser;
		},
		uploadImage(image){
			let fData = new FormData();
			fData.append("image", image);
			this.$apiManager.post("user/image",fData).then(
				(pack) => this.imageUpdated(pack),
				() => this.imageFailure(),
			);
		},
		imageUpdated(pack){
			if(pack.data && pack.data.success){
				this.$apiManager.get("user").then(
					(pack) => this.userDataSuccess(pack),
					() => this.dataFailure(),
				);
			}
		},
		imageFailure(){
			this.showImageError = true;
		},
		userDataSuccess(pack){
			if(pack.data && pack.data.success){
				pack.data.image += "?t=" + (new Date()).getTime();
				pack.data.smallImage += "?t=" + (new Date()).getTime();
				this.$dataDirectory.userData = pack.data;
				this.userData = {...this.$dataDirectory.userData};
				this.$parent.$refs.header.updateData();
			} else {
				this.dataFailure();
			}
		},
		dataFailure(){
			this.showImageError = true;
		},
		submitChanges(){
			if(this.newPassword && this.newPassword.trim().length > 0){
				if(!this.confirmPassword || this.newPassword.trim() !== this.confirmPassword.trim()){
					this.showPasswordMismatchError = true;
					return;
				}
				this.editUser = false;
				this.$apiManager.post("user",{
					firstName: this.userData.firstName,
					lastName: this.userData.lastName,
					email: this.userData.email,
					password: this.currentPassword,
					newPassword: this.newPassword.trim(),
				}).then(
					(pack) => this.userChanges(pack),
					() => this.userUpdateFail(),
				);
			} else {
				this.editUser = false;
				this.$apiManager.post("user",{
					firstName: this.userData.firstName,
					lastName: this.userData.lastName,
					email: this.userData.email,
				}).then(
					(pack) => this.userChanges(pack),
					() => this.userUpdateFail(),
				);
			}

		},
		userChanges(pack){
			if(pack.data){
				if(pack.data.success){
					this.$dataDirectory.userData = {...this.userData};
					this.$parent.$refs.header.updateData();
				} else if(pack.data.errorCode === 'incorrect-current-password'){
					this.editUser = true;
					this.showIncorrectCurrentPasswordError = true;
				} else {
					this.userUpdateFail();
				}
			} else {
				this.userUpdateFail();
			}
		},
		userUpdateFail(){
			this.showError = true;
			this.userData = {...this.$dataDirectory.userData};
			this.editUser = false;
		},
		cancelEdit(){
			this.userData = {...this.$dataDirectory.userData};
			this.editUser = false;
		},
		openBeneficiaries(){
			this.savedPrimaryBeneficiaries = this.primaryBeneficiaries.map((b) => Object.assign({}, b));
			this.savedSecondaryBeneficiaries = this.secondaryBeneficiaries.map((b) => Object.assign({}, b));
			this.editBeneficiaries = true;
		},
		cancelBeneficiaryChanges(){
			this.primaryBeneficiaries = this.savedPrimaryBeneficiaries.map((b) => Object.assign({}, b));
			this.secondaryBeneficiaries = this.savedSecondaryBeneficiaries.map((b) => Object.assign({}, b));
			this.editBeneficiaries = false;
		},
		addPrimaryBeneficiaries(){
			this.primaryBeneficiaries.push({
				name:"",
				street:"",
				cityStateZip:"",
				dateOfBirth:"",
				relationship:"",
				percentage:""
			});
		},
		addSecondaryBeneficiaries(){
			this.secondaryBeneficiaries.push({
				name:"",
				street:"",
				cityStateZip:"",
				dateOfBirth:"",
				relationship:"",
				percentage:""
			});
		},
		updatePrimary(i, beneficiary){
			this.primaryBeneficiaries[i] = Object.assign({}, beneficiary);
		},
		updateSecondary(i, beneficiary){
			this.secondaryBeneficiaries[i] = Object.assign({}, beneficiary);
		},
		deletePrimary(i){
			this.primaryBeneficiaries.splice(i, 1);
		},
		deleteSecondary(i){
			this.secondaryBeneficiaries.splice(i, 1);
		},
		beneficiaryData(pack){
			let dt = pack.data;
			if(dt.success){
				this.primaryBeneficiaries = dt.primary;
				this.secondaryBeneficiaries = dt.secondary;
			}
		},
		submitBeneficiaries(){
			let primaries = [];
			let secondaries = [];

			for(let i = 0; i < this.primaryBeneficiaries.length;++i){
				primaries.push(Object.assign({}, this.primaryBeneficiaries[i]));
			}

			for(let i = 0; i < this.secondaryBeneficiaries.length;++i){
				secondaries.push(Object.assign({}, this.secondaryBeneficiaries[i]));
			}

			let submitData = {
				primary: primaries,
				secondary: secondaries
			};

			let primaryPercent = 0;
			let secondaryPercent = 0;

			for(let i = 0; i < primaries.length;++i){
				//check for name, DoB, relationship and percentage
				let item = primaries[i];
				if(!item.name || item.name.trim() === ""){
					this.beneficiaryTitleError = "Incomplete Beneficiary Information";
					this.beneficiaryError = "Names for all beneficiaries must be provided.";
					this.showBeneficiaryError = true;
					return;
				}
				if(!item.dateOfBirth || item.dateOfBirth.trim() === ""){
					this.beneficiaryTitleError = "Incomplete Beneficiary Information";
					this.beneficiaryError = "Date of birth for all beneficiaries must be provided.";
					this.showBeneficiaryError = true;
					return;
				}
				if(!item.relationship || item.relationship.trim() === ""){
					this.beneficiaryTitleError = "Incomplete Beneficiary Information";
					this.beneficiaryError = "Relationship for all beneficiaries must be provided.";
					this.showBeneficiaryError = true;
					return;
				}
				//clean number
				item.percentage = item.percentage.replace(/[^\d.-]/g,'');
				let percNumber = Number(item.percentage);
				if(isNaN(percNumber) || percNumber <= 0){
					this.beneficiaryTitleError = "Incorrect Beneficiary Percentages";
					this.beneficiaryError = "Benefit percentage for all beneficiaries must be provided.";
					this.showBeneficiaryError = true;
					return;
				}
				primaryPercent += percNumber;
			}

			for(let i = 0; i < secondaries.length;++i){
				//check for name, DoB, relationship and percentage
				let item = secondaries[i];
				if(!item.name || item.name.trim() === ""){
					this.beneficiaryTitleError = "Incomplete Beneficiary Information";
					this.beneficiaryError = "Names for all beneficiaries must be provided.";
					this.showBeneficiaryError = true;
					return;
				}
				if(!item.dateOfBirth || item.dateOfBirth.trim() === ""){
					this.beneficiaryTitleError = "Incomplete Beneficiary Information";
					this.beneficiaryError = "Date of birth for all beneficiaries must be provided.";
					this.showBeneficiaryError = true;
					return;
				}
				if(!item.relationship || item.relationship.trim() === ""){
					this.beneficiaryTitleError = "Incomplete Beneficiary Information";
					this.beneficiaryError = "Relationship for all beneficiaries must be provided.";
					this.showBeneficiaryError = true;
					return;
				}
				//clean number
				item.percentage = item.percentage.replace(/[^\d.-]/g,'');
				let percNumber = Number(item.percentage);
				if(isNaN(percNumber) || percNumber <= 0){
					this.beneficiaryTitleError = "Incorrect Beneficiary Percentages";
					this.beneficiaryError = "Benefit percentage for all beneficiaries must be provided.";
					this.showBeneficiaryError = true;
					return;
				}
				secondaryPercent += percNumber;
			}

			if(primaryPercent !== 100){
				//throw alert
				this.beneficiaryTitleError = "Incorrect Beneficiary Percentages";
				this.beneficiaryError = "All primary beneficiaries' percentages must add up to 100.";
				this.showBeneficiaryError = true;
				return;
			}

			if(secondaryPercent !== 100 && secondaries.length > 0){
				//throw alert
				this.beneficiaryTitleError = "Incorrect Beneficiary Percentages";
				this.beneficiaryError = "All secondary beneficiaries' percentages must add up to 100.";
				this.showBeneficiaryError = true;
				return;
			}

			this.$apiManager.post("user/beneficiaries", submitData).then(
				(pack) => this.beneficiaryDataUpdated(pack),
				() => this.beneficiaryPushDataFailure()
			);

			this.editBeneficiaries = false;
		},
		beneficiaryDataUpdated(pack){
			this.holdon = pack.data;
		},
		beneficiaryDataFailure(){
			this.showErrorMessage = true;
		},
		beneficiaryPushDataFailure(){
			this.beneficiaryError = "Unable to update beneficiaries at this time. Please try again later.";
			this.showBeneficiaryError = true;
		},
		shortPlansReceived(pack){
			if(pack.data && pack.data.success){
				this.shortPlans = pack.data.plans;
			}
		},
		longPlansReceived(pack){
			if(pack.data && pack.data.success){
				this.longPlans = pack.data.plans;
			}
		},
		plansDataFailure(){
			//don't think we need to do anything, really.
		}
	}
}
</script>
<style lang="scss" scoped>
.account{
	&-sections{
		display: grid;
		grid-template-columns: 43% 55%;
		grid-column-gap: 2%;
		&-info{
			&-photo{
				border-radius: 50%;
				display: block;
				margin: 0 auto 2vw;
			}
			&-section{
				border-bottom: thin solid $color-divider;
				padding-bottom: 1vw;
				margin-bottom: 1vw;

				h3{
					color: $color-text-secondary;
				}
				&:last-child{
					border: none;
				}
				h4 span{
					cursor: pointer;
				}
			}
		}
		&-edit{
			&-controls{
				margin-bottom: 40px;
				.link{
					float: right;
					margin-top: calc(0.5vw + 2px);
				}
			}
			&-save{
				clear: both;
				text-align: right;
			}
		}
	}

	.address-field{
		&::after{
			clear: both;
		}
		&-label{
			display: inline-block;
			padding-top: 14px;
			font-size: 13px;
			font-family: $font-primary;
			color: $color-text-secondary;
		}
		&-content{
			display: inline-block;
			width: 70%;
			float: right;
			color: $color-text-primary;
			font-size: 14px;
			padding-top: 10px;
			text-align: right;
		}
		&-fields{
			display: inline-block;
			width: 70%;
			float: right;
			margin-top: 5px;
		}
		&-field{
			color: $color-text-primary;
			padding:10px;
			width: 100%;
			border: 2px solid $color-field-border;
			border-radius: 5px;
			margin-bottom: 8px;
			&[name=city]{
				width: 70%;
			}&[name=state]{
				width: 29%;
				margin-left: 1%;
			}
		}
	}
}
</style>
