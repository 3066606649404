import Moment from 'moment'

const args = {
    NUMERIC: 'numeric',
    SHORT: 'short',
    LONG: 'long',
    TWO_DIGITS: '2-digit',
}

let options = {
    DEFAULT: { year: args.NUMERIC, month: args.TWO_DIGITS, day: args.TWO_DIGITS },
    DIRECT: { month: args.LONG, day: args.NUMERIC, year: args.NUMERIC }
}

function formatDate(date, format = 'YYYY-MM-DD', preset = 'DEFAULT') {
    let _date = date
    let locale = undefined

    if(typeof date === 'string' || date instanceof String) {
        _date = Moment(date, format)
        return _date.toDate().toLocaleDateString(locale, options[preset])
    }
    else if(typeof date === 'object' && date instanceof Date) {
        _date = Moment(date.toDateString())
        return _date.toDate().toLocaleDateString(locale, options[preset])
    }
}

export default (Vue) => {
    Vue.filter('date', formatDate)
}