<template>
	<label class="text-field">
		<span class="text-field-label"><slot></slot></span>
		<textarea
			v-if="editable"
			ref="dataField"
			:type="fieldType"
			class="text-field-field"
			:class="{'text-field-field_wide':useWide}"
			:value="value"
			@input="updated"
		/>
		<span v-if="!editable" class="text-field-content">{{ value }}</span>
	</label>
</template>

<script>
export default {
	name: "TextField",
	props: {
		fieldType: {
			type: String,
			default: "text"
		},
		value: {
			type: [String, Number],
			default: null
		},
		updateWhenMounted: {
			type: Boolean,
			default: false
		},
		tip:{
			type: String,
			default:null
		},
		editable:{
			type: Boolean,
			default: true
		},
		useWide:{
			type: Boolean,
			default: false
		}
	},
	watch: {
		fieldData(value){
			this.$emit("update", value);
		},
		fieldModel(value){
			if(this.fieldType.toLowerCase() == "number"){
				if(value === null){
					this.fieldData = 0;
				} else{
					this.fieldData = Number(value);
				}
			}
			else {
				if(value === null){
					this.fieldData = "";
				} else{
					this.fieldData = value;
				}
			}

		}
	},
	data() {
		return {
			fieldData:""
		}
	},
	mounted() {
		this.fieldData = this.fieldModel;
	},
	methods: {
		updated(){
			this.$emit("input", this.$refs.dataField.value);
		}
	}
}
</script>
<style lang="scss" scoped>
.text-field{
	display: inline-block;
	margin-bottom: 8px;
	text-align: left;
	width: 100%;
	&-label{
		display: inline-block;
		font-family: $font-primary;
		font-size: 13px;
		padding-top: 14px;
	}
	&-field{
		border: 2px solid $color-field-border;
		border-radius: 5px;
		color: $color-text-primary;
		display: inline-block;
		float: right;
		margin-top: 5px;
		min-height: 230px;
		padding:10px;
		resize: none;
		width: 70%;
		font-size: 14.5px;
		height: 3rem;
		
		&.text-field-field_wide{
			width: 85%;
		}

		&:focus{
			outline: none;
			border-bottom: 2px solid #479dea;
		}
	}
	&-content{
		color: $color-text-primary;
		display: inline-block;
		float: right;
		font-size: 14px;
		padding-top: 10px;
		text-align: right;
		width: 70%;
	}
	textarea{
		font-family: $font-secondary;
	}
}
</style>
