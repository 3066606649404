<template>
  <div class="navigation">
    <router-link to="/dashboard" :class="{active:currentLocation === '/dashboard'}">
      <svg xmlns="http://www.w3.org/2000/svg" width="23.847" height="19.574" viewBox="0 0 23.847 19.574">
        <path id="Path_2824" data-name="Path 2824"
              d="M367.061-136.965h0a11.878,11.878,0,0,0-11.924,11.833h0a11.728,11.728,0,0,0,2.919,7.742h18.01a11.727,11.727,0,0,0,2.918-7.742h0A11.878,11.878,0,0,0,367.061-136.965Zm.826,13.345.845.564a2.3,2.3,0,0,1,.64,1.588,2.314,2.314,0,0,1-2.311,2.311,2.313,2.313,0,0,1-2.311-2.311,2.313,2.313,0,0,1,2.311-2.311A2.272,2.272,0,0,1,367.886-123.62Zm7.338,4.463h-4.808a4.054,4.054,0,0,0,.722-2.311,4.052,4.052,0,0,0-1.358-3.018l2.389-3.581-1.47-.98-2.465,3.695a4.039,4.039,0,0,0-1.175-.193,4.078,4.078,0,0,0-4.077,4.078,4.059,4.059,0,0,0,.722,2.311H358.9a10,10,0,0,1-1.963-5.194h2.494v-1.766h-2.476a9.976,9.976,0,0,1,2.326-5.472l1.746,1.746,1.249-1.249-1.741-1.741a10.156,10.156,0,0,1,5.643-2.32v2.42h1.766v-2.42a10.162,10.162,0,0,1,5.673,2.346l-1.743,1.743,1.249,1.249,1.745-1.745a9.97,9.97,0,0,1,2.3,5.443h-2.475v1.766h2.493A10,10,0,0,1,375.224-119.157Z"
              transform="translate(-355.137 136.965)"/>
      </svg>
      <span>Dashboard</span>
    </router-link>
    <router-link to="/account" :class="{active:currentLocation === '/account'}">
      <svg xmlns="http://www.w3.org/2000/svg" width="18.25" height="21.411" viewBox="0 0 18.25 21.411">
        <path id="Path_2825" data-name="Path 2825"
              d="M376.556-89.521V-83.3a1.524,1.524,0,0,1-1.523,1.522H359.827a1.523,1.523,0,0,1-1.521-1.522v-6.221a2.219,2.219,0,0,1,1.366-2.053l2.308-.966a7.812,7.812,0,0,0,1.55,1.267l-3.177,1.329a.458.458,0,0,0-.281.423v5.977H374.79v-5.977a.457.457,0,0,0-.282-.423l-2.922-1.222a7.887,7.887,0,0,0,1.607-1.243l2,.836A2.221,2.221,0,0,1,376.556-89.521Zm-8.911-11.9a3.532,3.532,0,0,0-3.528,3.528,3.531,3.531,0,0,0,3.528,3.528,3.532,3.532,0,0,0,3.529-3.528,3.532,3.532,0,0,0-3.529-3.528m0-1.766a5.3,5.3,0,0,1,5.295,5.294,5.294,5.294,0,0,1-5.295,5.294,5.294,5.294,0,0,1-5.294-5.294,5.3,5.3,0,0,1,5.294-5.294Z"
              transform="translate(-358.306 103.189)"/>
      </svg>
      <span>My Account</span>
    </router-link>

    <div v-if="$dataDirectory.shortTermAvailable" class="short-term-plans" @click="handleShortTermPlan">
      <svg :class="{'active-svg':this.$router.currentRoute.path.includes('/short-term')}" xmlns="http://www.w3.org/2000/svg" width="22.026" height="22.738" viewBox="0 0 22.026 22.738">
        <path id="Path_2828" data-name="Path 2828"
              d="M375.675-56.537a9.147,9.147,0,0,0-9.136-9.137,9.148,9.148,0,0,0-9.138,9.137,9.147,9.147,0,0,0,9.138,9.136A9.147,9.147,0,0,0,375.675-56.537Zm-16.238.98h.892v-1.96h-.892a7.134,7.134,0,0,1,1.39-3.346l.627.628,1.386-1.386-.627-.628a7.121,7.121,0,0,1,3.346-1.389v.891h1.96v-.891a7.117,7.117,0,0,1,3.345,1.389l-.628.628,1.386,1.386.628-.628a7.131,7.131,0,0,1,1.39,3.346h-.892v1.96h.892a7.131,7.131,0,0,1-1.39,3.346l-.628-.628-1.386,1.386.628.628a7.137,7.137,0,0,1-3.345,1.389v-.892h-1.96v.892a7.132,7.132,0,0,1-3.346-1.39l.627-.627-1.386-1.386-.627.628A7.134,7.134,0,0,1,359.437-55.557Zm11.652-10.919a10.955,10.955,0,0,0-1.6-.587l.529-1.888a12.958,12.958,0,0,1,1.888.692Zm2.511,1.6a11.152,11.152,0,0,0-1.382-1l1.02-1.673a12.9,12.9,0,0,1,1.629,1.176Zm.926.882,1.432-1.338a13.1,13.1,0,0,1,1.2,1.5l-1.614,1.112A11.083,11.083,0,0,0,374.526-63.995Zm-6.289-3.338a10.99,10.99,0,0,0-1.7-.132v-1.96a12.982,12.982,0,0,1,2,.156Zm2.072,14.567-3.772-3.771v-5.334a5.334,5.334,0,0,1,5.334,5.334A5.314,5.314,0,0,1,370.31-52.766Zm8.134,4.1.006,1.96-6.522.02,1.911-1.717a10.949,10.949,0,0,0,3.628-8.131,10.95,10.95,0,0,0-1.246-5.073l1.736-.912a12.921,12.921,0,0,1,1.47,5.985,12.907,12.907,0,0,1-2.687,7.874Z"
              transform="translate(-357.401 69.426)"/>
      </svg>
      <span :class="{'active':this.$router.currentRoute.path.includes('/short-term')}">
        <template v-if="clientIsDiy">Annual Plans</template>
        <template v-else>Short-Term Plans</template>
      </span>
    </div>
    <div v-if="$dataDirectory.shortTermAvailable && short_term.years && $router.currentRoute.path.includes('/short-term')" class="years-container">
      <div v-for="year in sortedShortTermYears" :key="`plan-year-${year}`">
        <div class="plan-year" @click="toggleShortTermYear(year)">
          <span>{{ year }}</span>
          <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'year-open':short_term.open_years.includes(year)}">
            <path d="M7.136 0.00799963L7.136 3.448L3.6 6.696L0.0639999 3.448L0.0640001 0.00799932L3.6 3.272L7.136 0.00799963Z" fill="#065088"/>
          </svg>
        </div>
        <div class="plans-container" :class="{'year-open':short_term.open_years.includes(year)}">
          <router-link :to="`/short-term/${plan_id}`" v-for="plan_id in short_term.years[year]" :key="`plan-id-${plan_id}`" class="plan-link">
            <span :class="{'selected' : isSelectedPlan(plan_id)}">{{ short_term.plans[plan_id].name }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="$dataDirectory.longTermAvailable && Object.values(longTermPlans).length" class="long-term-plans" :class="{active:currentLocation === '/long-term'}" @click="handleLongTermPlan">
      <svg :class="{'active-svg':$router.currentRoute.path.includes('/long-term')}" xmlns="http://www.w3.org/2000/svg" width="25.919" height="25.94" viewBox="0 0 25.919 25.94">
        <path id="Path_2829" data-name="Path 2829" d="M359.248-21.693a9.147,9.147,0,0,0,9.138,9.136,9.147,9.147,0,0,0,9.136-9.136,9.147,9.147,0,0,0-9.136-9.137A9.148,9.148,0,0,0,359.248-21.693Zm16.238-.98h-.892v1.96h.892a7.121,7.121,0,0,1-1.389,3.346l-.628-.628-1.386,1.386.628.628a7.142,7.142,0,0,1-3.345,1.389v-.891h-1.96v.891a7.137,7.137,0,0,1-3.346-1.389l.627-.628L363.3-17.994l-.628.628a7.14,7.14,0,0,1-1.389-3.346h.892v-1.96h-.892a7.126,7.126,0,0,1,1.389-3.346l.628.628,1.386-1.386-.627-.628a7.127,7.127,0,0,1,3.346-1.389v.891h1.96v-.891a7.128,7.128,0,0,1,3.345,1.389l-.628.628,1.386,1.386.628-.628A7.116,7.116,0,0,1,375.487-22.673Zm-.259,9.606,1.221,1.533a12.912,12.912,0,0,1-1.344.937,12.956,12.956,0,0,1-6.719,1.874,12.985,12.985,0,0,1-12.971-12.97,13.018,13.018,0,0,1,2.616-7.808h-1.817v-1.96h6.51l-1.828,1.7a11.048,11.048,0,0,0-3.521,8.07,11.022,11.022,0,0,0,11.011,11.009,10.99,10.99,0,0,0,5.7-1.589A11.171,11.171,0,0,0,375.228-13.067Zm-1.472-8.725a5.233,5.233,0,0,1-5.233,5.233,5.233,5.233,0,0,1-5.233-5.233,5.2,5.2,0,0,1,1.358-3.49l3.737,3.59v-5.32c.047,0,.09-.014.137-.014A5.234,5.234,0,0,1,373.756-21.792Zm5.391-2.243,1.916-.414a12.863,12.863,0,0,1,.27,1.99l-1.956.114A11.049,11.049,0,0,0,379.147-24.035Zm-2.755-5.216,1.425-1.346a13.158,13.158,0,0,1,1.262,1.56l-1.614,1.112A11.181,11.181,0,0,0,376.391-29.252Zm-.932-.879a10.96,10.96,0,0,0-1.387-.992l1.014-1.678a12.861,12.861,0,0,1,1.634,1.17Zm2.667,3.3,1.733-.916a12.965,12.965,0,0,1,.8,1.846l-1.855.635A10.71,10.71,0,0,0,378.126-26.831Zm-8.039-5.742a11.145,11.145,0,0,0-1.7-.13v-1.96a13.081,13.081,0,0,1,2,.154Zm11.247,11.62a12.868,12.868,0,0,1-.267,1.992l-1.916-.411a11.169,11.169,0,0,0,.226-1.691Zm-2.524,2.814,1.855.631a12.63,12.63,0,0,1-.8,1.849l-1.732-.915A10.869,10.869,0,0,0,378.81-18.138ZM372.939-31.72a10.777,10.777,0,0,0-1.6-.583l.524-1.889a12.958,12.958,0,0,1,1.891.687Zm4.536,16.241,1.615,1.11a13.059,13.059,0,0,1-1.258,1.563L376.4-14.148A11.2,11.2,0,0,0,377.475-15.478Z" transform="translate(-355.415 34.663)"/>
      </svg>
      <span>Long-Term Plans</span>
    </div>
    <div v-if="!long_term.loading && $router.currentRoute.path.includes('/long-term') && Object.values(longTermPlans).length" :class="{active:currentLocation === '/long-term'}" class="long-term-link">
      <router-link :to="`/long-term/${plan.id}`" v-for="plan in longTermPlans" :key="`plan-id-${plan.id}`" class="plan-link">
        <span :class="{'selected' : isSelectedPlan(plan.id)}">{{ longTermPlans[plan.id].name }}</span>
      </router-link>
    </div>

    <router-link v-if="$dataDirectory.evsAvailable" to="/value-statement" :class="{active:currentLocation === '/value-statement'}">
      <svg xmlns="http://www.w3.org/2000/svg" width="22.977" height="22.982" viewBox="0 0 22.977 22.982">
        <path id="Path_2830" data-name="Path 2830" d="M375.574,19.362V4.208A3.723,3.723,0,0,0,371.856.49H358.887a3.261,3.261,0,0,0-3.257,3.256V4.6h3.033V19.753a3.723,3.723,0,0,0,3.718,3.718H375.35a3.26,3.26,0,0,0,3.257-3.256v-.853ZM358.887,2.2h7.771a3.23,3.23,0,0,0-.28.7h-8.784A1.55,1.55,0,0,1,358.887,2.2Zm7.377,18.018a1.55,1.55,0,0,1-1.548,1.549h-2.334a2.012,2.012,0,0,1-2.01-2.011V4.6h7.6V3.746A1.551,1.551,0,0,1,369.521,2.2h2.334a2.013,2.013,0,0,1,2.01,2.011V19.362h-7.6Zm9.086,1.549h-7.771a3.183,3.183,0,0,0,.279-.7h8.784A1.548,1.548,0,0,1,375.35,21.764Zm-4.3-11.578-1.982-.238-1.064-2.129a.994.994,0,0,0-1.779,0l-1.064,2.129-1.981.238a.994.994,0,0,0-.526,1.744l1.558,1.325-.6,2.181a1,1,0,0,0,1.472,1.115l2.027-1.216,2.028,1.217a.994.994,0,0,0,1.113-.062.989.989,0,0,0,.358-1.055l-.6-2.18,1.557-1.324a.994.994,0,0,0-.525-1.745Zm-2.3,2.117a1,1,0,0,0-.316,1.02l.327,1.2-1.133-.68a.99.99,0,0,0-1.025,0l-1.135.682.327-1.2a1,1,0,0,0-.316-1.017l-.864-.734,1.023-.123a.994.994,0,0,0,.772-.543l.7-1.409.707,1.412a.991.991,0,0,0,.768.539l1.025.124Z" transform="translate(-355.63 -0.49)"/>
      </svg>
      <span>Employee Value Statement</span>
    </router-link>
    <router-link to="/contact" :class="{active:currentLocation === '/contact'}">
      <svg xmlns="http://www.w3.org/2000/svg" width="22.036" height="15.973" viewBox="0 0 22.036 15.973">
        <path id="Path_2827" data-name="Path 2827" d="M378.2,42.618l-.016-.025v-.556a1.977,1.977,0,0,0-1.977-1.977h-18.05a1.977,1.977,0,0,0-1.977,1.977v.556l-.016.025.016.011V54.058a1.977,1.977,0,0,0,1.977,1.977h18.05a1.977,1.977,0,0,0,1.977-1.977V42.629Zm-20.043-.927h18.05a.347.347,0,0,1,.246.1l-9.272,5.865-9.271-5.865A.345.345,0,0,1,358.156,41.691Zm18.4,12.367a.348.348,0,0,1-.347.347h-18.05a.348.348,0,0,1-.347-.347v-10.4l9.372,5.927,9.373-5.927Z" transform="translate(-356.163 -40.061)"/>
      </svg>
      <span>Contact Plan Administrator</span>
    </router-link>

    <!-- The 'Administration' link appeared here, if $apiManager.adminUrl !== null -->

    <div class="navigation-footer">
      <!-- Logo -->
      <div class="logo-footer">
        <svg width="250" height="51" viewBox="0 0 250 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_8103_61924)">
            <path
                d="M82.2491 15.516L73.7268 35.9735C73.6053 36.2674 73.3154 36.4594 72.998 36.4594H67.8729C67.5555 36.4594 67.2655 36.2674 67.144 35.9735L58.6256 15.516C58.4101 14.9988 58.7902 14.4268 59.3544 14.4268H63.9036C64.2249 14.4268 64.5149 14.6227 64.6363 14.9205L69.9809 28.2231C70.1455 28.6385 70.7293 28.6385 70.8978 28.2231L76.2424 14.9205C76.3638 14.6188 76.6538 14.4268 76.9751 14.4268H81.5242C82.0885 14.4268 82.4686 14.9988 82.2531 15.516H82.2491Z"
                fill="#241B89"/>
            <path
                d="M90.4735 36.4603H86.23C85.795 36.4603 85.4424 36.1076 85.4424 35.6727V15.2583C85.4424 14.8234 85.795 14.4707 86.23 14.4707H90.4735C90.9084 14.4707 91.265 14.8234 91.265 15.2583V35.6727C91.265 36.1076 90.9123 36.4603 90.4735 36.4603Z"
                fill="#241B89"/>
            <path
                d="M105.203 32.448C107.366 32.448 108.647 31.4802 108.647 30.2459C108.647 29.4505 108.118 28.659 106.222 28.2632L101.724 27.3385C97.7504 26.5039 95.5013 24.2548 95.5013 21.081C95.5013 16.8492 98.9455 13.8086 104.506 13.8086C109.325 13.8086 112.781 16.1126 113.866 19.4941C114.007 19.9368 113.718 20.407 113.259 20.4972L109.27 21.2886C108.894 21.3631 108.498 21.1633 108.377 20.7989C107.899 19.3765 106.453 18.393 104.329 18.393C101.99 18.393 101.332 19.5372 101.332 20.552C101.332 21.2573 101.594 22.0057 103.314 22.4014L108.565 23.5456C112.448 24.3802 114.348 26.8095 114.348 29.76C114.348 34.607 110.422 37.1225 104.686 37.1225C100.121 37.1225 95.9127 35.4455 94.9488 31.441C94.843 31.01 95.1291 30.579 95.564 30.4888L99.9212 29.6033C100.301 29.5249 100.697 29.7365 100.818 30.1048C101.387 31.8485 103.162 32.448 105.215 32.448H105.207H105.203Z"
                fill="#241B89"/>
            <path
                d="M123.078 36.4603H118.834C118.4 36.4603 118.043 36.1076 118.043 35.6727V15.2583C118.043 14.8234 118.396 14.4707 118.834 14.4707H123.078C123.513 14.4707 123.869 14.8234 123.869 15.2583V35.6727C123.869 36.1076 123.517 36.4603 123.078 36.4603Z"
                fill="#241B89"/>
            <path
                d="M151.361 25.4848C151.361 32.4045 145.934 37.032 139.492 37.032C133.05 37.032 127.49 32.4045 127.49 25.4848C127.49 18.565 132.917 13.9375 139.492 13.9375C146.067 13.9375 151.361 18.565 151.361 25.4848ZM145.714 25.4848C145.714 21.3862 142.537 18.9177 139.496 18.9177C136.455 18.9177 133.14 21.3862 133.14 25.4848C133.14 29.5833 136.318 32.0519 139.496 32.0519C142.674 32.0519 145.714 29.5833 145.714 25.4848Z"
                fill="#241B89"/>
            <path
                d="M185.779 36.4601H181.536C181.101 36.4601 180.744 36.1075 180.744 35.6725V8.64793C180.744 8.213 181.097 7.86035 181.536 7.86035H185.779C186.214 7.86035 186.571 8.213 186.571 8.64793V35.6725C186.571 36.1075 186.218 36.4601 185.779 36.4601Z"
                fill="#479DEA"/>
            <path
                d="M196.985 36.4603H192.742C192.307 36.4603 191.95 36.1076 191.95 35.6727V15.2583C191.95 14.8234 192.303 14.4707 192.742 14.4707H196.985C197.42 14.4707 197.773 14.8234 197.773 15.2583V35.6727C197.773 36.1076 197.42 36.4603 196.985 36.4603Z"
                fill="#479DEA"/>
            <path
                d="M175.513 36.4601H171.183C170.748 36.4601 170.391 36.1075 170.391 35.6726V24.6347C170.391 22.0094 168.413 19.7172 165.791 19.5409C162.915 19.3489 160.517 21.6293 160.517 24.4623V35.6726C160.517 36.1075 160.165 36.4601 159.726 36.4601H155.396C154.961 36.4601 154.604 36.1075 154.604 35.6726V24.7248C154.604 18.8552 159.161 13.8554 165.035 13.6321C171.202 13.397 176.3 18.3458 176.3 24.4544V35.6647C176.3 36.0997 175.948 36.4523 175.509 36.4523L175.513 36.4601Z"
                fill="#241B89"/>
            <path
                d="M224.002 36.4601H219.672C219.237 36.4601 218.881 36.1075 218.881 35.6726V24.6347C218.881 22.0094 216.902 19.7172 214.281 19.5409C211.404 19.3489 209.006 21.6293 209.006 24.4623V35.6726C209.006 36.1075 208.654 36.4601 208.219 36.4601H203.889C203.454 36.4601 203.098 36.1075 203.098 35.6726V24.7248C203.098 18.8552 207.655 13.8554 213.528 13.6321C219.696 13.397 224.793 18.3458 224.793 24.4544V35.6647C224.793 36.0997 224.441 36.4523 224.006 36.4523L224.002 36.4601Z"
                fill="#479DEA"/>
            <path
                d="M249.757 35.1044L239.731 25.3517C239.531 25.1597 239.531 24.8423 239.731 24.6464L248.645 15.8184C249.146 15.3247 248.794 14.4705 248.088 14.4705H243.018C242.802 14.4705 242.591 14.5567 242.45 14.7135L235.914 21.5548C235.608 21.8761 235.064 21.6606 235.064 21.2179V8.64793C235.064 8.213 234.711 7.86035 234.276 7.86035H230.033C229.598 7.86035 229.241 8.213 229.241 8.64793V35.6725C229.241 36.1075 229.594 36.4601 230.033 36.4601H234.276C234.711 36.4601 235.064 36.1075 235.064 35.6725V28.7567C235.064 28.31 235.612 28.0945 235.918 28.4197L243.151 36.2054C243.3 36.3661 243.512 36.4562 243.731 36.4562H249.209C249.918 36.4562 250.267 35.5942 249.761 35.1005L249.757 35.1044Z"
                fill="#479DEA"/>
            <path
                d="M124.485 7.49188C124.485 9.43144 122.984 11.2926 120.954 11.2926C118.925 11.2926 117.424 9.83895 117.424 7.89938C117.424 5.95982 118.881 4.41992 120.954 4.41992C123.027 4.41992 124.485 5.63852 124.485 7.49188Z"
                fill="#241B89"/>
            <path
                d="M91.8801 7.89938C91.8801 9.83895 90.3794 11.2926 88.3497 11.2926C86.32 11.2926 84.8193 9.83895 84.8193 7.89938C84.8193 5.95982 86.2769 4.41992 88.3497 4.41992C90.4225 4.41992 91.8801 6.04994 91.8801 7.89938Z"
                fill="#241B89"/>
            <path
                d="M198.392 7.89938C198.392 9.83895 196.891 11.2926 194.861 11.2926C192.832 11.2926 191.331 9.83895 191.331 7.89938C191.331 5.95982 192.789 4.41992 194.861 4.41992C196.934 4.41992 198.392 6.04994 198.392 7.89938Z"
                fill="#479DEA"/>
            <path
                d="M21.1044 41.4399L14.7685 26.2368C14.647 25.943 14.3571 25.751 14.0397 25.751H3.96181C3.63659 25.751 3.41716 26.0801 3.54647 26.3779L10.0861 42.0668C13.1424 49.4019 21.5746 52.8735 28.9214 49.8212C29.1722 49.7154 29.4152 49.6057 29.6581 49.492C25.9318 48.1872 22.754 45.37 21.1162 41.4399H21.1083H21.1044Z"
                fill="#241B89"/>
            <path
                d="M12.1393 19.9169C9.18485 12.8326 12.3313 4.73344 19.1648 1.43814C15.9322 0.305752 12.2842 0.317507 8.8753 1.73594C1.69304 4.71777 -1.78642 12.8326 0.913294 20.0462C1.02692 20.3479 1.32472 20.5477 1.64994 20.5477H11.72C12.0452 20.5477 12.2646 20.2186 12.1353 19.9208L12.1393 19.9169Z"
                fill="#479DEA"/>
            <path
                d="M27.706 9.49031L34.0419 24.6934C34.1634 24.9872 34.4533 25.1792 34.7707 25.1792H44.8525C45.1777 25.1792 45.3972 24.8501 45.2718 24.5523L38.7321 8.86338C35.6758 1.52438 27.2436 -1.94724 19.8968 1.10904C19.646 1.21484 19.4031 1.32455 19.1602 1.43818C22.8865 2.74298 26.0642 5.56024 27.7021 9.49031H27.706Z"
                fill="#241B89"/>
            <path
                d="M36.675 31.0098C39.6294 38.0941 36.483 46.1932 29.6455 49.4885C32.8781 50.6209 36.5261 50.6091 39.935 49.1907C47.1133 46.2089 50.5967 38.0941 47.897 30.8804C47.7834 30.5787 47.4856 30.3789 47.1604 30.3789H37.0903C36.7651 30.3789 36.5457 30.708 36.675 31.0058V31.0098Z"
                fill="#479DEA"/>
            <path
                d="M24.4069 30.4221C27.1497 30.4221 29.3714 28.2005 29.3714 25.4616C29.3714 22.7227 27.1497 20.501 24.4069 20.501C21.6641 20.501 19.4424 22.7227 19.4424 25.4616C19.4424 28.2005 21.6641 30.4221 24.4069 30.4221Z"
                fill="#479DEA"/>
          </g>
          <defs>
            <clipPath id="clip0_8103_61924">
              <rect width="250" height="50.9263" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>

      <div class="navigation-footer-copy">
        © 2024 VisionLink, All Rights Reserved
      </div>
      <a href="/site/terms-of-use.pdf">Terms of Use</a> &nbsp; &nbsp; <a href="/site/privacy-policy.pdf">Privacy
      Policy</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrNavigation",

  data() {
    return {
      currentLocation: null,
      short_term: {
        loading: false,
        plans: null,
        years: null,
        open_years: [],
      },
      long_term: {
        loading: false,
        plans: {},
      }
    }
  },

  beforeMount() {
    this.getShortTermPlans()
    this.getLongTermPlans()
  },

  mounted() {
    this.currentLocation = this.$router.currentRoute.path;
    this.$dataDirectory.navigator = this;
    setTimeout(() => this.checkForUpdate(), 750);
  },

  beforeRouteLeave(to, from, next) {
    this.currentLocation = to.path;
    next();
  },

  computed: {
    sortedShortTermYears() {
      if (this.short_term.years) {
        return Object.keys(this.short_term.years).reverse()
      }
      return null
    },
    selectedPlanYear() {
      if (this.$route.params?.id) return this.short_term.plans[this.$route.params.id].year
      return null
    },
    clientIsDiy() {
      return this.$dataDirectory.userData.client_status === 'diy';
    },
    longTermPlans() {
      return this.long_term.plans;
    },
  },

  methods: {
    handleShortTermPlan() {
      if (!this.$route.path.includes('short-term')) {
        this.$router.push(`/short-term/${this.short_term.years[this.sortedShortTermYears[0]][0]}`)
        this.toggleShortTermYear(this.sortedShortTermYears[0])
      } else if (!this.short_term.open_years.includes(this.short_term.plans[this.$route.params.id].year)) {
        this.toggleShortTermYear(this.short_term.plans[this.$route.params.id].year)
      }
    },

    handleLongTermPlan() {
      if (!this.$route.path.includes('long-term')) {
        let plans = Object.values(this.long_term.plans);
        this.$router.push(`/long-term/${plans[0].id}`);
      }
    },

    isSelectedPlan(plan_id) {
      return !!(this.$route.params?.id && this.$route.params.id == plan_id);
    },
    toggleShortTermYear(year) {
      if (this.short_term.open_years.includes(year)) {
        this.short_term.open_years.splice(this.short_term.open_years.indexOf(year), 1)
      } else {
        this.short_term.open_years.push(year)
      }
    },
    toggleLongTermPlans() {
      this.long_term.open = !this.long_term.open
    },
    getShortTermPlans() {
      this.short_term.loading = true
      this.$apiManager.get("plans/stip-list")
          .then((result) => {
            this.short_term.plans = result.data.plans
            this.short_term.years = result.data.years
            this.short_term.open_years = []
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.short_term.loading = false
          })
    },
    getLongTermPlans() {
      this.long_term.loading = true
      this.$apiManager.get("plans/ltip-list")
          .then((result) => {
            this.long_term.plans = result.data.plans
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.long_term.loading = false
          })
    },

    checkForUpdate() {
      if (!this.$dataDirectory.planUpdated) {
        this.$dataDirectory.wasPostingFutureYear = this.$dataDirectory.isPostingFutureYear;
        this.$apiManager.get("plans").then(
            (pack) => this.updateMenuItems(pack),
            () => this.planFailure()
        );
      }
    },
    updateMenuItems(pack) {
      if (pack.data) {
        this.$dataDirectory.evsAvailable = pack.data.evsAvailable;
        this.$dataDirectory.conventionalEvs = pack.data.conventionalEvs;
        this.$dataDirectory.shortTermAvailable = pack.data.stipCount && pack.data.stipCount > 0;
        this.$dataDirectory.longTermAvailable = pack.data.ltipCount && pack.data.ltipCount > 0;
        if (!this.$dataDirectory.wasPostingFutureYear) {
          this.$dataDirectory.futureYear = pack.data.futureYear;
        }
        this.$dataDirectory.planUpdated = true;
      }
    },
    planFailure() {
    } //just a catcher
  },

  watch: {
    "short_term.plans": {
      handler() {
        if (this.$router.currentRoute.path.includes('/short-term') && this.$route.params?.id) {
          this.toggleShortTermYear(this.short_term.plans[this.$route.params.id].year)
        }
      }, deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  padding: 4vh 1.5vw;
  position: relative;
  min-height: calc(100vh - 60px);
  background-color: $color-background-tertiary;

  background-color: #e4f2ff;

  & > a {
    color: $color-navigation-active;
    //color: $color-navigation-primary;
    text-decoration: none;
    display: block;
    margin-bottom: 6vh;
    transition: 0.5s;
    font-size: 13px;
    font-family: $font-primary;
    font-weight: 500;

    span{
      border-bottom: 2px solid transparent;
      padding-bottom: 2px;
      line-height: 2em;
    }

    &.active,
    &:hover {
      color: $color-navigation-active;

      & span {
        border-bottom: 2px solid $color-navigation-active;
        padding-bottom: 2px;
        line-height: 2em;
      }

      svg {
        margin-right: 15px;
        fill: $color-navigation-active;
      }
    }

    svg {
      margin-right: 15px;
      // fill: $color-navigation-primary;
      fill: $color-navigation-active;
      transition: 0.5s;
      position: relative;

      &.adjust {
        top: 3px;
      }
    }
  }

  &-footer {
    border-top: thin solid $color-divider;
    bottom: 0;
    padding: 20px 0 15px;
    position: absolute;
    //width: calc(20% - 3vw);
    &-copy {
      font-family: $font-secondary;
      font-size: 12px;
      margin: 8px 0;
    }

    a {
      margin-right: 20px;
    }
  }
}

.logo-footer {
  display: flex;

  svg {
    width: 65%;
    height: auto;
  }
}

.long-term-plans,
.short-term-plans {
  text-decoration: none;
  display: block;
  margin-bottom: 6vh;
  transition: 0.5s;
  font-size: 13px;
  font-family: $font-primary;
  font-weight: 500;

  color: $color-navigation-active;

  border-bottom: 2px solid transparent;
  padding-bottom: 2px;
  line-height: 2em;

  cursor: pointer;

  &:hover,
  &.active {
    color: $color-navigation-active;

    & span {
      border-bottom: 2px solid $color-navigation-active;
    }

    svg {
      fill: $color-navigation-active;
    }
  }

  svg {
    fill: $color-navigation-active;
    margin: 0 15px 0 0;

    &.active-svg {
      fill: $color-navigation-active;
    }
  }

  & > .active {
    border-bottom: 2px solid $color-navigation-active;

    color: $color-navigation-active;
  }
}

.long-term-link,
.years-container {
  display: flex;
  flex-direction: column;

  margin-left: 28px;
  margin-top: -6vh;
  margin-bottom: 1.25em;

  padding-top: 1em;

  .plan-year {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 13px;
    font-family: $font-secondary;
    font-weight: 800;

    color: $color-link;

    cursor: pointer;

    & > svg {
      transition: all 0.125s ease-out;
      transform: rotate(-90deg);

      &.year-open {
        transform: none;
      }
    }
  }

  .plans-container {
    display: none;

    &.year-open {
      display: flex;
      flex-direction: column;

      margin-bottom: 10px;
    }

    .plan-link {
      font-size: 13px;
      font-family: $font-primary;
      font-weight: 500;

      color: $color-blue-700;

      margin-left: 14px;

      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      .selected {
        text-decoration: underline;
      }
    }
  }
}

.admin-link {
  margin-left: 37px;

  & > a {
    color: $color-navigation-primary;
    text-decoration: none;
    display: block;
    margin-bottom: 6vh;
    transition: 0.5s;
    font-size: 13px;
    font-family: $font-primary;
    font-weight: 500;

    &:hover {
      color: $color-navigation-active;

      & span {
        border-bottom: 2px solid $color-navigation-active;
        padding-bottom: 2px;
        line-height: 2em;
      }
    }
  }
}
</style>
