<template>
    <div class="doughnut-chart">
        <g-chart type="PieChart" :data="chartData" :options="chartOptions"/>
    </div>
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'

export default {
    name: "DoughnutChart",

    components: {
        GChart
    },

    props: {
        client: {
            type: Object,
            required: true,
        },
        employee: {
            type: Object,
            required: false,
        },
        organization: {
            type: Object,
            required: false,
        },
        distributions: {
            type: Array,
            required: false,
        },
    },

    data() {
        return {
            chartData: [
                ['component', 'weight']
            ],
            chartOptions: {
                chartArea: { left: 6, right: 6, top: 15, bottom: 15, width: '100%', height: '100%' },
                pieHole: 0.6,
                pieStartAngle: 180,
                slices: this.getSliceColors(),
                tooltip: {
                    isHTML: true,
                    ignoreBounds: true,
                },
                pieSliceText: 'none',
                legend: {
                    alignment: 'center',
                    textStyle: {
                        fontSize: 12,
                    },
                },
            },
            clientColors: {
                highlight: null,
                foreground: null,
                background: null,
            },
        }
    },

    computed: {
      orgUnits() {
        if(this.distributions.length) return this.distributions[this.distributions.length - 1].org_units
        else return this.organization.units
      },
    },

    methods: {
        getClientColors() {},
        getSliceColors() {
            let unitColors = ['#c6abff', '#ddc1ff', '#e5d1ff', '#eee0ff', '#f6f0ff']

            let slices = [];

            if(this.client) {
                slices.push({color: '#2e7471'})
            }

            if(this.organization?.types) {
                let totalUnitColors = unitColors.length;
                let totalTypes = Object.values(this.organization.types).length

                for(let index = 0; index < totalTypes; index++) {
                    let colorIndex = index < totalUnitColors - 1 ? index : index - (totalUnitColors - 1);

                    slices.push({color: unitColors[colorIndex]})
                }
            }

            if(this.employee) {
                slices.push({color: '#ff7d01'})
            }

            return slices;
        },
        buildChartData() {
            this.chartData.push([this.client.allocation.label, this.client.allocation.weight / 10000000])

            if(Object.values(this.orgUnits).length) {
                Object.values(this.orgUnits).forEach((unit) => {
                    let label = `${unit.label}`
                    let weight = null

                    if(unit?.allocation?.weight) {
                        weight = unit.allocation.weight
                    }
                    else if(unit?.weight) {
                        weight = unit.weight
                    }

                    this.chartData.push([label, weight / 10000000])
                })
            }

            if(this.employee) {
                this.chartData.push([this.employee.allocation.label, this.employee.allocation.weight / 10000000])
            }
        },
    },

    created() {
        this.buildChartData()
    },

    mounted() {}
}
</script>

<style scoped>
</style>