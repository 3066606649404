<template>
  <div>
    <div class="distributions-header">
      <div class="section-header">
        <QuestionBadge :id="'pendo-eos-statement-calculation-breakdown'">
          <h2>CALCULATION BREAKDOWN</h2>
        </QuestionBadge>
      </div>
    </div>
    <!-- EOS distributions breakdown -->
    <template v-if="plan.isEos">
      <table>
        <thead>
          <tr>
            <th></th>
            <template v-for="index in payoutCount">
              <th v-if="(index) === payoutCount" class="final-distribution" style="width: 12rem;" :key="index">Final</th>
              <th v-else class="forecasted-distribution" style="width: 12rem;" :key="index"></th>
            </template>
          </tr>
          <tr>
            <th></th>
            <template v-for="index in payoutCount">
              <th v-if="payoutCount === 4" class="distribution-period" :key="index">Q{{ index }}</th>
              <th v-else-if="payoutCount === 3" class="distribution-period" :key="index">T{{ index }}</th>
              <th v-else-if="payoutCount === 2" class="distribution-period" :key="index">P{{ index }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="first-column"><QuestionBadge :id="'pendo-eos-performance-level'">Performance Level</QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td :class="[index === distributionCount  ? 'performance-level' : '']" :key="index"><template v-if="distributions[index - 1]">{{ distributions[index - 1].company_performance_position }}</template></td>
            </template>
          </tr>
          <tr>
            <td class="first-column"><QuestionBadge :id="'pendo-eos-annual-award-value'">Forecasted Annual Award Value</QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td :key="index">
                <template v-if="index === payoutCount || index === distributionCount"><template v-if="distributions[index - 1]">{{ distributions[index - 1].forecasted_annual_award | currency }}</template></template>
              </td>
            </template>
          </tr>
          <tr>
            <td class="first-column">
              <QuestionBadge :id="'pendo-eos-eligible-quarterly-payment'">
                <template v-if="payoutCount === 4">Eligible Quarterly Payment</template>
                <template v-else-if="payoutCount === 3">Eligible Trimester Payment</template>
                <template v-else-if="payoutCount === 2">Eligible Semi-annual Payment</template>
              </QuestionBadge
            ></td>
            <template v-for="index in payoutCount">
              <td :key="index"><template v-if="distributions[index - 1]">{{ distributions[index - 1].prorated_forecast | currency }}</template></td>
            </template>
          </tr>
          <template v-if="hasOrgUnits">
            <tr>
              <td class="first-column pt-4"><QuestionBadge :id="'pendo-eos-allocation-percent'">Allocation %</QuestionBadge></td>
              <template v-for="index in payoutCount">
                <td :key="index"><div class="distribution-symbol pt-4"><span>X</span><template v-if="distributions[index - 1]">{{ distributions[index - 1].corp_weight | percent(2) }}</template></div></td>
              </template>
            </tr>
            <tr>
              <td class="first-column">{{ client.allocation.label }}</td>
              <template v-for="index in payoutCount">
                <td :key="index"><div class="font-bold border-t"><template v-if="distributions[index - 1]">{{ distributions[index - 1].corp_contribution | currency }}</template></div></td>
              </template>
            </tr>
            <template v-for="(unit, index) in Object.values(organization.units)">
              <tr :key="`${unit[unit.length - 1].label}-weight-${index}`">
                <td class="first-column pt-4"><QuestionBadge :id="'pendo-eos-org-unit-name'">{{ unit[unit.length - 1].label }} {{ organization.types[unit[unit.length - 1].type_id].label }}</QuestionBadge></td>
                <template v-for="index in payoutCount">
                  <td :key="index"><div v-if="unit[index - 1]" class="distribution-symbol pt-4"><span>X</span>{{ unit[index - 1].allocation.weight | percent(2) }}</div></td>
                </template>
              </tr>
              <tr :key="`${unit[unit.length - 1].label}-funding-${index}`">
                <td class="first-column"></td>
                <template v-for="index in payoutCount">
                  <td :key="index"><div v-if="unit[index - 1]" class="font-bold border-t">{{ unit[index - 1].allocation.funding | currency }}</div></td>
                </template>
              </tr>
              <tr :key="`${unit[unit.length - 1].label}-modifier-${index}`">
                <td class="first-column pt-4"><QuestionBadge :id="'pendo-eos-org-unit-performance'">Performance Modification</QuestionBadge></td>
                <template v-for="index in payoutCount">
                  <td :key="index"><div v-if="unit[index - 1]" class="distribution-symbol pt-4"><span>X</span> {{ unit[index - 1].allocation.modifier | percent }}</div></td>
                </template>
              </tr>
              <tr :key="`${unit[unit.length - 1].label}-contribution-${index}`">
                <td class="first-column"></td>
                <template v-for="index in payoutCount">
                  <td :key="index"><div v-if="unit[index - 1]" class="font-bold border-t">{{ unit[index - 1].allocation.contribution | currency }}</div></td>
                </template>
              </tr>
            </template>
          </template>
          <template v-if="employeeGoals || employeeRating">
            <tr>
              <td class="first-column pt-4"><QuestionBadge :id="'pendo-eos-individual-performance'">Individual Performance Adjustment</QuestionBadge></td>
              <template v-for="index in payoutCount">
                <td :key="index"><div v-if="distributions[index - 1]" class="distribution-symbol pt-4"><span>X</span>{{ distributions[index - 1].ind_mult | percent(2) }}</div></td>
              </template>
            </tr>
            <tr>
              <td class="first-column"></td>
              <template v-for="index in payoutCount">
                <td :key="index"><div v-if="distributions[index - 1]" class="font-bold border-t">{{ distributions[index - 1].raw_payout | currency }}</div></td>
              </template>
            </tr>
          </template>
          <tr>
            <td class="first-column"><QuestionBadge :id="'pendo-eos-holdback-percent'">Holdback <span style="margin-left:0.5rem;">{{ plan.holdback.percent | percent }}</span></QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td :key="index">
                <template v-if="index !== payoutCount && distributions[index - 1]">-{{ distributions[index - 1].holdback_amount | currency }}</template>
              </td>
            </template>
          </tr>
          <tr>
            <td class="first-column"><QuestionBadge :id="'pendo-eos-less-previously-paid'">Less Previously Paid</QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td :key="index">
                <template v-if="index === payoutCount && distributions[index - 1]">{{ (-distributions[index - 1].sum_of_previous) | currency }}</template>
              </td>
            </template>
          </tr>
          <tr>
            <td class="first-column"></td>
            <template v-for="index in payoutCount">
              <td :key="index" class="font-bold"><template v-if="distributions[index - 1]"><div class="font-bold border-t">{{ distributions[index - 1].eligible | currency }}</div></template></td>
            </template>
          </tr>
          <tr>
            <td class="first-column" style="padding-bottom:1em;"><QuestionBadge :id="'pendo-eos-employer-adjustment'">Employer Adjustment</QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td style="padding-bottom:1em;" :key="index">
                <template v-if="distributions[index - 1] && distributions[index - 1].adjustments !== 0">{{ distributions[index - 1].adjustments | currency }}</template>
              </td>
            </template>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="first-column distribution-footer" style="vertical-align:bottom;">
              <QuestionBadge :id="'pendo-period-payment'">
                <template v-if="payoutCount === 4">Quarterly Payment</template>
                <template v-else-if="payoutCount === 3">Trimester Payment</template>
                <template v-else-if="payoutCount === 2">Semi-annual Payment</template>
              </QuestionBadge>
            </td>
            <template v-for="index in payoutCount">
              <td class="distribution-amount" style="vertical-align:bottom;" :key="index"><template v-if="distributions[index - 1]">{{ distributions[index - 1].award_amount | currency }}</template></td>
            </template>
          </tr>
          <tr>
            <td class="first-column distribution-footer" style="vertical-align:top;"><QuestionBadge :id="'pendo-paid-to-date'">Paid to Date</QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td class="ytd-bonus" style="vertical-align:top;" :key="index"><template v-if="distributions[index - 1]">{{ distributions[index - 1].ytd_bonus | currency }}</template></td>
            </template>
          </tr>
        </tfoot>
      </table>
    </template>

    <!-- standard distributions breakdown -->
    <template v-else>
      <table>
        <thead>
          <tr>
            <th></th>
            <template v-for="index in payoutCount">
              <th v-if="index === payoutCount" class="final-distribution" style="width: 12rem;" :key="index">Final</th>
              <th v-else class="forecasted-distribution" style="width: 12rem;" :key="index">Forecasted</th>
            </template>
          </tr>
          <tr>
            <th></th>
            <template v-for="index in payoutCount">
              <th v-if="payoutCount === 4" class="distribution-period" :key="index">Q{{ index }}</th>
              <th v-else-if="payoutCount === 3" class="distribution-period" :key="index">T{{ index }}</th>
              <th v-else-if="payoutCount === 2" class="distribution-period" :key="index">SA{{ index }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="first-column" style="padding-top:2rem;"><QuestionBadge :id="'pendo-performance-level'">Performance Level</QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td :class="[index === distributionCount ? 'performance-level' : '']" style="padding-top:2rem;" :key="index"><template v-if="distributions[index - 1]">{{ distributions[index - 1].company_performance_position }}</template></td>
            </template>
          </tr>
          <tr>
            <td class="first-column"><QuestionBadge :id="'pendo-annual-award-value'">Forecasted Annual Award Value</QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td :key="index"><template v-if="distributions[index - 1]">{{ distributions[index - 1].forecasted_annual_award | currency }}</template></td>
            </template>
          </tr>
          <template v-if="hasOrgUnits">
            <tr>
              <td class="first-column pt-4"><QuestionBadge :id="'pendo-allocation-percent'">Allocation %</QuestionBadge></td>
              <template v-for="index in payoutCount">
                <td :key="index">
                  <div v-if="index === payoutCount" class="distribution-symbol pt-4"><template v-if="distributions[index - 1]"><span>X</span>{{ distributions[index - 1].corp_weight | percent(2) }}</template></div>
                </td>
              </template>
            </tr>
            <tr>
              <td class="first-column">{{ client.allocation.label }}</td>
              <template v-for="index in payoutCount">
                <td :key="index">
                  <div v-if="index === payoutCount" class="font-bold" :class="[distributions[index - 1]? 'border-t' : '']"><template v-if="distributions[index - 1]">{{ distributions[index - 1].corp_contribution | currency }}</template></div>
                </td>
              </template>
            </tr>
            <template v-for="(unit, unit_index) in Object.values(organization.units)">
              <tr :key="`${unit.label}-weight-${unit_index}`">
                <td class="first-column pt-4"><QuestionBadge :id="'pendo-org-unit-name'">{{ unit.label }} {{ organization.types[unit.type_id].label }}</QuestionBadge></td>
                <template v-for="index in payoutCount">
                  <td :key="index">
                    <div v-if="distributions[index - 1] && index === payoutCount" class="distribution-symbol pt-4"><span>X</span>{{ unit.allocation.weight | percent(2) }}</div>
                  </td>
                </template>
              </tr>
              <tr :key="`${unit.label}-funding-${unit_index}`">
                <td class="first-column"></td>
                <template v-for="index in payoutCount">
                  <td :key="index">
                    <div v-if="distributions[index - 1] && index === payoutCount" class="font-bold" :class="[distributions[index - 1]? 'border-t' : '']">{{ unit.allocation.funding | currency }}</div>
                  </td>
                </template>
              </tr>
              <tr :key="`${unit.label}-modifier-${unit_index}`">
                <td class="first-column pt-4"><QuestionBadge :id="'pendo-org-unit-performance'">Performance Modification</QuestionBadge></td>
                <template v-for="index in payoutCount">
                  <td :key="index">
                    <div v-if="distributions[index - 1] && index === payoutCount" class="distribution-symbol pt-4"><span>X</span> {{ unit.allocation.modifier | percent(2) }}</div>
                  </td>
                </template>
              </tr>
              <tr :key="`${unit.label}-contribution-${unit_index}`">
                <td class="first-column"></td>
                <template v-for="index in payoutCount">
                  <td :key="index">
                    <div v-if="distributions[index - 1] && index === payoutCount" class="font-bold border-t">{{ unit.allocation.contribution | currency }}</div>
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <tr>
            <td class="first-column pt-4"><QuestionBadge :id="'pendo-percent-of-year-complete'">% of Year Completed</QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td :key="index"><div class="distribution-symbol pt-4"><template v-if="distributions[index - 1]"><span>X</span>{{ distributions[index - 1].percent_complete | percent }}</template></div></td>
            </template>
          </tr>
          <tr>
            <td class="first-column">
              <QuestionBadge :id="'pendo-eligible-quarterly-payment'">
                <template v-if="payoutCount === 4">Eligible Quarterly Payment</template>
                <template v-else-if="payoutCount === 3">Eligible Trimester Payment</template>
                <template v-else-if="payoutCount === 2">Eligible Semi-annual Payment</template>
              </QuestionBadge>
            </td>
            <template v-for="index in payoutCount">
              <td :key="index"><div class="font-bold" :class="[distributions[index - 1]? 'border-t' : '']"><template v-if="distributions[index - 1]">{{ distributions[index - 1].prorated_forecast | currency }}</template></div></td>
            </template>
          </tr>
          <template v-if="employeeGoals || employeeRating">
            <tr>
              <td class="first-column"><QuestionBadge :id="'pendo-individual-performance'">Individual Performance Adjustment</QuestionBadge></td>
              <template v-for="index in payoutCount">
                <td :key="index">
                  <div v-if="distributions[index - 1] && index === payoutCount && employeeGoals" class="distribution-symbol"><span>X</span>{{ employee.performance.goals.performance_total | percent }}</div>
                  <div v-else-if="distributions[index - 1] && index === payoutCount && employeeRating" class="distribution-symbol"><span>X</span>{{ employee.performance.rating.achieved_value | percent }}</div>
                </td>
              </template>
            </tr>
            <tr>
              <td class="first-column"></td>
              <template v-for="index in payoutCount">
                <td :key="index">
                  <div v-if="distributions[index - 1] && index === payoutCount" class="font-bold" :class="[distributions[index - 1]? 'border-t' : '']">
                    <template v-if="distributions[index - 1]">{{ distributions[index - 1].raw_payout | currency }}</template>
                  </div>
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td class="first-column">Holdback <span style="margin-left:0.5rem;">{{ plan.holdback.percent | percent }}</span></td>
            <template v-for="index in payoutCount">
              <td :key="index">
                <template v-if="distributions[index - 1] && index !== payoutCount">-{{ distributions[index - 1].holdback_amount | currency }}</template>
              </td>
            </template>
          </tr>
          <tr>
            <td class="first-column"></td>
            <template v-for="index in payoutCount">
              <td :key="index">
                <div class="font-bold" :class="[distributions[index - 1]? 'border-t' : '']">
                  <template v-if="distributions[index - 1]">{{ distributions[index - 1].eligible_to_date | currency }}</template>
                </div>
              </td>
            </template>
          </tr>
          <tr>
            <td class="first-column"><QuestionBadge :id="'pendo-less-previously-paid'">Less Previously Paid</QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td :key="index">
                <template v-if="distributions[index - 1] && distributions[index - 1].sum_of_previous !== 0">{{ (-distributions[index - 1].sum_of_previous) | currency }}</template>
              </td>
            </template>
          </tr>
          <tr>
            <td class="first-column"></td>
            <template v-for="index in payoutCount">
              <td :key="index">
                <div class="font-bold" :class="[distributions[index - 1]? 'border-t' : '']">
                  <template v-if="distributions[index - 1]">{{ distributions[index - 1].eligible | currency }}</template>
                </div>
              </td>
            </template>
          </tr>
          <tr>
            <td class="first-column" style="padding-bottom:1em;"><QuestionBadge :id="'pendo-employer-adjustment'">Employer Adjustment</QuestionBadge></td>
            <template v-for="index in payoutCount">
              <td style="padding-bottom:1em;" :key="index">
                <template v-if="distributions[index - 1] && distributions[index - 1].adjustments !== 0">{{ distributions[index - 1].adjustments | currency }}</template>
              </td>
            </template>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="first-column distribution-footer" style="vertical-align:bottom;">
              <QuestionBadge :id="'pendo-period-payment'">
                <template v-if="payoutCount === 4">Quarterly Payment</template>
                <template v-else-if="payoutCount === 3">Trimester Payment</template>
                <template v-else-if="payoutCount === 2">Semi-annual Payment</template>
              </QuestionBadge>
            </td>
            <template v-for="index in payoutCount">
              <td class="distribution-amount" style="vertical-align:bottom;" :key="index"><template v-if="distributions[index - 1]">{{ distributions[index - 1].award_amount | currency }}</template></td>
            </template>
          </tr>
          <tr>
            <td class="first-column distribution-footer" style="vertical-align:top;">
              <QuestionBadge :id="'pendo-paid-to-date'">Paid to Date</QuestionBadge>
            </td>
            <template v-for="index in payoutCount">
              <td class="ytd-bonus" style="vertical-align:top;" :key="index"><template v-if="distributions[index - 1]">{{ distributions[index - 1].ytd_bonus | currency }}</template></td>
            </template>
          </tr>
        </tfoot>
      </table>
    </template>
  </div>
</template>

<script>
import QuestionBadge from "@/components/pendo/QuestionBadge.vue";

export default {
    name: "PlanDistributions",
  components: {QuestionBadge},

    props: {
      plan: {
        type: Object,
        required: true,
      },
      client: {
        type: Object,
        required: false,
      },
      employee: {
        type: Object,
        required: true,
      },
      organization: {
        type: Object,
        required: false,
      },
      distributions: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {}
    },

    computed: {
      distributionCount() {
        return this.distributions.length
      },
      payoutCount() {
        return this.plan?.holdback.payout_count
      },
      employeeRating() {
        return !!this.employee.performance.rating
      },
      employeeGoals() {
        return !!this.employee.performance.goals
      },
      hasOrgUnits() {
        return !!(this.organization && Object.keys(this.organization.units).length)
      },
    },

    methods: {},

    mounted() {},
}
</script>

<style scoped lang="scss">
@import "../../../scss/_variables.scss";

.distributions-header {
  display: flex;
  flex-direction: column;

  padding-top: 2.4rem;

  h2 {
    margin: 0;
  }
}

table {
  width: 100%;
  border-collapse: collapse;

  thead {
    border-bottom: 1px solid $charcoal-tint-20;
    white-space: nowrap;
  }

  tbody {
    border-bottom:1px solid $charcoal-tint-20;
  }

  tfoot>tr>td {
    border: none;
    border-radius: unset;
    text-align: right;
  }

  th,
  td {
    padding: 0.25rem 1rem;
    border: none;
    border-radius: unset;
    text-align: right;
    color: $charcoal-tint-50;
    font-size: $text-base;
  }

  th {
    text-align: center;
  }

  th:first-child,
  td:first-child {
    text-align: left;
  }

  td {
    border-right:1px solid $charcoal-tint-20;
  }
}

.distribution-symbol {
  display: flex;
  justify-content: space-between;
  width: 100%;

  color: $charcoal-tint-50;

  span {
    width: 40%;
    color: var(--charcoal-tint-50);
  }
}

.distribution-period {
  color: $charcoal;
}

.forecasted-distribution {
  color: $charcoal-tint-50;
  font-weight: normal;
}

.final-distribution {
  color: var(--highlight-color);
}

.first-column {
  color: $charcoal;
  font-size: $text-sm;
}

.performance-level {
  font-weight: bold;
  color: var(--highlight-color);
}

.distribution-footer {
  font-weight: bold;
}

.ytd-bonus {
  color: var(--highlight-color);
  font-weight: bold;
  font-size: $text-lg;
}

.distribution-amount {
  padding-top:0.75rem;
  font-weight: bold;
  font-size: $text-md;
}

.font-bold {
  font-weight: bold;
}

.pl-4 {
  padding-left: 0.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-4 {
  padding-top: 0.5em;
}

.pt-8 {
  padding-top: 1rem;
}

.border-t {
  border-top: 1px solid $charcoal-tint-50;
}
</style>