<template>
	<div v-if="userData" class="dashboard">
		<div class="dashboard-greeting">
			<h1>{{ greeting }}, {{ displayName }}!</h1>
			<p>DASHBOARD</p>
		</div>
		<br-deferral
			v-if="planData.deferrableCertificate && !deferralShown"
			:plan="planData.deferrablePlan"
			:certificate="planData.deferrableCertificate"
			:heading-text="'You have the option to defer maturity for ' + planData.deferrableCertificate.title + '. Please make your selection here.'"
			cancel-text="Skip for now"
			@close="closeDeferral">
		</br-deferral>

		<template v-if="clientIsDiy">
			<!-- Messages/Alerts -->
			<div v-if="portalMessage" class="messages">
        <!-- TODO: refactor into separate component -->
				<div class="message-card">
					<div class="message-header">
						<!-- Icon Slot -->
						<div class="message-icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
						</div>

						<!-- Title Slot -->
            <p v-if="portalMessage.subject" class="message-title">{{ portalMessage.subject }}</p>
						<p v-else class="message-title">Message</p>
					</div>
					<div class="message-content">
						<!-- Message Slot -->
						<p>{{ portalMessage.content }}</p>
					</div>
				</div>
			</div>

			<div class="diy-dashboard">
				<!-- Welcome -->
				<section class="welcome-section">
          <h1>Welcome to MyVisionLink Rewards</h1>
          <p>This site is dedicated to helping you understand how your plan works, review your awards and forecasts and to get current up-to-date plan information.<br>We recommend you watch the explanatory video below to learn how the plan operates and to discover how to navigate the site.</p>
				</section>

        <template v-if="videos.length">
          <div class="videos-container">
            <section v-for="video in videos" :key="video.video_id">
              <h3>{{ video.plan_name }}</h3>

              <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                  <iframe :src="`https://fast.wistia.net/embed/iframe/${video.video_id}?seo=false&videoFoam=true`" :title="video.title" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe>
                </div>
              </div>

              <template v-if="video.link"><a :href="`${ video.link }`">Download</a></template>
            </section>
          </div>
        </template>
        <template v-else>
          <p>The explanation video corresponds to your active plans. If you have not activated a plan, nothing will be displayed.</p>
        </template>
			</div>
		</template>
		<template v-else>
			<div class="dashboard-sections" :class="{'dashboard-sections_noplan':!canShowPlan}">
				<div class="dashboard-sections-dash">
					<h3>My {{ currentYear }} Plans</h3>
					<div class="dashboard-sections-dash-short">
<!--						<br-card v-if="$dataDirectory.shortTermAvailable" :orange="true">-->
<!--							<h3>Short-Term Plans (forecasted award)</h3>-->
<!--							<br-dollars>{{ $accounting.formatCents(planData.shortTerm) }}</br-dollars>-->
<!--						</br-card>-->
<!--						<br-card v-if="$dataDirectory.longTermAvailable" :blue="true">-->
<!--							<h3>Total Plan Value</h3>-->
<!--							<br-dollars>{{ $accounting.formatCents(planData.longTerm) }}</br-dollars>-->
<!--						</br-card>-->
					</div>
					<template v-if="canShowFuture">
						<h3 style="display: inline-block">My Future: {{ planData.futureYear }}</h3>
						<h3 style="float:right;">Values are hypothetical</h3>
						<div class="dashboard-sections-dash-future">
							<br-card class="uk-margin-small-bottom">
								<div class="dashboard-sections-dash-future-accumulation">
									<h3>Accumulation Program</h3>
									<div v-if="canShowTotalLtip">
										<h3>Forecasted Plan Value (EOY)</h3>
										<br-dollars>{{ $accounting.formatCents(planData.totalLTIP) }}</br-dollars>
									</div>
									<div v-if="this.planData.conventionalEvs">
										<h3>Total Wealth Projection</h3>
										<br-dollars>{{ $accounting.formatCents(planData.totalWealth) }}</br-dollars>
									</div>
								</div>
							</br-card>
							<br-card v-if="this.planData.conventionalEvs">
								<div class="dashboard-sections-dash-future-charts">
									<div class="dashboard-sections-dash-future-charts-annual">
										<h3>Annual Income</h3>
										<div class="chart">
											<pie-chart ref="annual_chart" :chartdata="pieChartData" :options="{
											legend: {
												display: false
											},
											tooltips: {
												enabled: false
											}
										}"></pie-chart>
											<div class="uk-text-center">
												<br-dollars>{{ $accounting.formatCents(planData.annualIncome) }}</br-dollars> <br />
												<span>Total Annual Income</span>
											</div>
										</div>
										<div class="legend">
											<div v-if="this.annualValues[0]" class="legend-row">
												<div class="square square-salary"></div>
												<div>Salary</div>
												<br-dollars>{{ $accounting.formatCents(this.annualValues[0]) }}</br-dollars>
											</div>
											<div v-if="this.annualValues[1]" class="legend-row">
												<div class="square square-short"></div>
												<div>Short-Term Award</div>
												<br-dollars>{{ $accounting.formatCents(this.annualValues[1]) }}</br-dollars>
											</div>
											<div v-if="this.annualValues[2]" class="legend-row">
												<div class="square square-long"></div>
												<div>Long-Term Award</div>
												<br-dollars>{{ $accounting.formatCents(this.annualValues[2]) }}</br-dollars>
											</div>
										</div>
									</div>
									<div class="dashboard-sections-dash-future-charts-cumulative">
										<h3>Cumulative Income</h3>
										<div class="uk-text-center">
											<br-dollars>{{ $accounting.formatCents(planData.totalIncome) }}</br-dollars> <br />
											<span>Total Cumulative Income</span>
										</div>
										<percentage-bar :data-values="cumulativeValues"></percentage-bar>
										<div class="legend">
											<div v-if="this.cumulativeValues[0]" class="legend-row">
												<div class="square square-salary"></div>
												<div>Total Salaries</div>
												<br-dollars>{{ $accounting.formatCents(this.cumulativeValues[0]) }}</br-dollars>
											</div>
											<div v-if="this.cumulativeValues[1]" class="legend-row">
												<div class="square square-short"></div>
												<div>Short-Term Award</div>
												<br-dollars>{{ $accounting.formatCents(this.cumulativeValues[1]) }}</br-dollars>
											</div>
											<div v-if="this.cumulativeValues[2]" class="legend-row">
												<div class="square square-long"></div>
												<div>Long-Term Award</div>
												<br-dollars>{{ $accounting.formatCents(this.cumulativeValues[2]) }}</br-dollars>
											</div>
										</div>
									</div>
								</div>
							</br-card>
						</div>
					</template>
				</div>
				<br-messages></br-messages>
			</div>
		</template>

		<vk-modal v-if="showErrorMessage" :show.sync="showErrorMessage">
			<vk-modal-title>Communication Error</vk-modal-title>
			<div>
				<p>There was an error connecting to the server.</p>
				<p>We were unable to obtain the data for this page at this time. Please try again later.</p>
			</div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
	</div>
</template>

<script>
import BrCard from "@/components/elements/BrCard";
import BrDeferral from "@/components/elements/BrDeferral";
import BrDollars from "@/components/elements/BrDollars";
import BrMessages from "@/components/elements/BrMessages";
import PieChart from "@/components/charts/PieChart";
import PercentageBar from "@/components/charts/PercentageBar";
export default {
	name: "BrDashboard",

	components: {
		BrCard,
		BrDeferral,
		BrDollars,
		BrMessages,
		PieChart,
		PercentageBar
	},

	data() {
		return {
      loading: {
        portal_message: false,
        video: false,
      },
			userData: null,
			planData: {
				annualIncome: 0,
				annualLongAward: 0,
				annualSalary: 0,
				annualShortAward: 0,
				evsAvailable: false,
				conventionalEvs: false,
				futureYear: 0,
				longTerm: 0,
				ltipCount: 0,
				shortTerm: 0,
				stipCount: 0,
				success: true,
				totalIncome: 0,
				totalLTIP: 0,
				totalLongAwards: 0,
				totalSalary: 0,
				totalShortAwards: 0,
				totalWealth: 0,
			},
			annualValues: [
				0,
				0,
				0
			],
			cumulativeValues: [
				0,
				0,
				0
			],
			pieChartData: {
				hoverBorderWidth: 10,
				labels: ["Salary", "Short-Term Award", "Long-Term Award"],
				datasets: [
					{
						label: 'Data One',
						backgroundColor: ['#87C426','#FF9800','#71C4BC'],
						data: []
					}
				]
			},
			showErrorMessage: false,
			deferralShown: false,
      planVideos: null,
      portalMessage: null,
		}
	},

	mounted() {
		!this.$dataDirectory.userData.isDemo ? this.init() : null;

        this.getLatestPortalMessage()
	},

	methods: {
		init(){
			this.userData = this.$dataDirectory.userData;
			this.pieChartData.datasets[0].data = this.annualValues;

			this.$dataDirectory.wasPostingFutureYear = this.$dataDirectory.isPostingFutureYear;
			this.$apiManager.get("plans").then(
				(pack) => this.planDataSuccess(pack),
				() => this.planDataFailure()
			);
		},
		planDataSuccess(pack){
      console.log(pack.data)

			if(pack.data.success){
				this.planData = pack.data;
				this.annualValues = [
					this.planData.annualSalary,
					this.planData.annualShortAward,
					this.planData.annualLongAward
				];

				this.cumulativeValues = [
					this.planData.totalSalary,
					this.planData.totalShortAwards,
					this.planData.totalLongAwards
				];
				this.pieChartData.datasets[0].data = this.annualValues;
				if(this.$refs.annual_chart){
					this.$refs.annual_chart.updateWith(this.pieChartData);
				}
				this.$dataDirectory.evsAvailable = this.planData.evsAvailable;
				this.$dataDirectory.conventionalEvs = this.planData.conventionalEvs;
				this.$dataDirectory.shortTermAvailable = this.planData.stipCount && this.planData.stipCount > 0;
				this.$dataDirectory.longTermAvailable = this.planData.ltipCount && this.planData.ltipCount > 0;
				if (this.$dataDirectory.wasPostingFutureYear) {
					this.planData.futureYear = this.$dataDirectory.futureYear;
				} else {
					this.$dataDirectory.futureYear = this.planData.futureYear;
				}
				this.$dataDirectory.planUpdated = true;

        if(this.clientIsDiy) this.getPlanVideos()
			}
		},
		planDataFailure(){
			this.showErrorMessage = true;
		},

    getLatestPortalMessage() {
        this.loading.portal_message = true
        this.$apiManager.get('portal-message')
            .then((result) => {
                if(result.data?.success) this.portalMessage = result.data.message
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                this.loading.portal_message = false
            })
    },
    getPlanVideos() {
      this.loading.video = true
      this.$apiManager.get(`plan-videos`)
          .then((result) => {
              if(result.data?.videos) this.planVideos = Object.values(result.data.videos)
          })
          .catch((error) => {
              console.error(error)
          })
          .finally(() => {
              this.loading.video = false
          })
    },

		messageCardClass(message) {
			console.log(message);
			return true
		},
		closeDeferral() {
			this.deferralShown = true;
		},
	},

	computed:{
		clientIsDiy() {
			return this.$dataDirectory.userData.client_status === 'diy' || this.$dataDirectory.userData.client_status === 'DIY';
		},
		greeting() {
			let current_hour = new Date().getHours();

			if(current_hour < 12){
				return "Good Morning";
			}
			else if(current_hour < 18){
				return "Good Afternoon";
			}
			else {
				return "Welcome";
			}
		},

		canShowPlan(){
			return this.planData.stipCount > 0 || this.planData.ltipCount > 0;
		},
		canShowFuture(){
			return this.planData.totalIncome && this.planData.totalIncome > 0;
		},
		canShowTotalLtip(){
			return this.planData.ltipCount > 0;
		},
		displayName(){
			if(/\s/.test(this.userData.firstName.trim())){
				return this.userData.firstName + " " + this.userData.lastName;
			}
			return this.userData.firstName;
		},
		currentYear(){
			let date = new Date();
			return date.getFullYear();
		},

    videos() {
      if(this.planVideos?.length) return this.planVideos
      else return []
    },
	},

	watch:{
		planData:{
			deep: true,
			handler(){}
		}
	}
}
</script>

<style lang="scss" scoped>
	.messages {
		margin-bottom: 2em;

		.message-card {
			width: 100%;

			padding: 2em 2.5em;

			// Alert
			//background-color: #FF98000D;
			//border:1px solid #FF9800;

			// Message/Info
			background-color: rgba(6, 80, 136, 0.2);
			border:1px solid #065088;

			border-radius: 10px;

			font-size: $text-sm;

			.message-header {
				display: flex;
				align-items: center;
				margin-bottom: 1em;

				.message-icon {
					margin-right: 1em;

					svg {
						fill: #065088;

						width: 2em;
						height: 2em;
					}
				}
				.message-title {
					font-size: $text-base;
					font-weight: 700;
				}
			}
			.message-content {}
		}
	}

	.dashboard-greeting {
		display: flex;
		justify-content: space-between;
		margin-bottom: 3vw;

		h1{
			margin-bottom: 0px;
		}

		h2 {
			font-size: $text-lg;
			text-transform: uppercase;
		}
		p {
			font-size: $text-lg;
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	.diy-dashboard {
		padding: 0 2em 0 0;

		.welcome-section {
			margin-bottom: 3em;

			h1 {
				margin-bottom: 0.25em;
			}

      p:last-child {
        margin-top: 2em;
      }
		}

    section {
      margin-bottom: 2rem;
    }

    .videos-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.5rem 2rem;
    }

	}

	.dashboard-sections{
		display: grid;
		grid-template-columns: auto 260px;

		&-dash{
			padding-right: 2vw;
			margin-right: 2vw;
			border-right: thin solid rgba(#241b89, 0.25);
			&-short{
				display: grid;
				grid-template-columns: 48% 48%;
				grid-column-gap: 4%;
				padding-bottom: 2vw;
				margin-bottom: 2vw;
				border-bottom: thin solid rgba(#241b89, 0.25);
				h3{
					color: $color-text-secondary;
				}
			}
			&-future{
				h3{
					color: $color-text-secondary;
				}
				&-accumulation{
					display: grid;
					grid-template-columns: 15% 36% 36%;
					grid-column-gap: 5%;
					&>div{
						background-color: $color-background-tertiary;
						border-radius: 15px;
						padding: 1vw 1.5vw;
					}
				}
				&-charts{
					display: grid;
					grid-template-columns: 47% 48%;
					grid-column-gap: 5%;
					.dollar-field{
						font-size: 20px;
					}

					&-annual{
						.chart{
							display: grid;
							grid-template-columns: 120px auto;
							margin-bottom: 15px;

							.uk-text-center{
								padding-top: 20px;
							}
						}
					}

					&-cumulative{
						.percentage-bar{
							margin-top: 20px;
							margin-bottom: 40px;
						}
					}

					.legend{

						&-row{
							display: grid;
							grid-template-columns: 24px auto 25%;
							grid-column-gap: 4%;
							font-size: 13px;
							margin-bottom: 8px;
							.dollar-field{
								font-size: 13px;
							}
						}
						.square{
							background-color: black;
							width: 24px;
							height: 24px;

							&-salary{
								background-color: $color-card-border-green;
							}
							&-short{
								background-color: $color-card-border-orange;
							}
							&-long{
								background-color: $color-card-border-blue;
							}
						}
					}
				}
			}
		}

		&_noplan{
			display: block;
			.dashboard-sections-dash{
				display: none;
				border-bottom: thin solid rgba(#241b89, 0.25);
				border-right: none;
				margin-bottom: 25px;
				margin-right: 0;
				padding-bottom: 15px;
				padding-right: 0;
			}
		}

		@media screen and (max-width: 1200px){
			display: block;
			&-dash{
				border-bottom: thin solid rgba(#241b89, 0.25);
				border-right: none;
				margin-bottom: 25px;
				margin-right: 0;
				padding-bottom: 15px;
				padding-right: 0;
			}
		}
	}
</style>
