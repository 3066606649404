<template>
	<transition name="fade">
		<div class="terms-form" v-if="displayTerms">
			<br-terms></br-terms>
			<standard-button class="terms-form-accept" @click="acceptTerms">Accept</standard-button>
		</div>
	</transition>
</template>

<script>
import BrTerms from "./BrTerms";
import StandardButton from "../elements/StandardButton";
export default {
	name: "TermsForm",
	components: {
		StandardButton,
		BrTerms
	},
	props: {
		displayTerms:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			termsAccepted: false
		};
	},
	mounted() {
	},
	methods: {
		acceptTerms(){
			this.$emit("terms-accepted");
		}
	},
}
</script>

<style lang="scss" scoped>
.terms-form{
	padding: 20px 4vw;
	&-accept{
		display: block;
		margin: 20px auto 40px;
	}
}
.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>