<template>
  <div class="plan-toolbar">
    <div class="statement">
    </div>
    <div class="actions">
      <vk-button v-if="pdf" type="link">
        <a :href="pdf" target="_blank"><img src="/icons/file-export-solid.svg" alt="export"></a>
      </vk-button>
      <vk-button v-if="calendar" type="link">
        <img src="/icons/calendar-solid.svg" alt="calendar">
      </vk-button>
    </div>
  </div>
</template>

<script>
// import QuestionBadge from "@/components/pendo/QuestionBadge.vue";

export default {
  name: "PlanToolbar",

  components: {
    // QuestionBadge
  },

  props: {
    planType: {

    },
    date: {
      type: [String, Date],
      required: false,
    },
    pdf: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      calendar: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/_variables.scss";

.plan-toolbar {
  display: flex;
  justify-content: space-between;

  .statement {
    color: $charcoal-tint-50;

    h2 {
      font-size: $text-lg;
      margin-bottom: 0;
    }

    span {
      font-size: $text-base;
      line-height: 1.5;

      &:first-of-type {
        font-weight: 800;
        font-family: $font-secondary;
        margin-right: 0.5rem;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    min-width: 130px;

    button {
      height: fit-content;
      padding: 0.25rem;
      margin-left: 1.5rem;

      &:first-child {
        margin-left: 0;
      }

      img {
        height: 1.4667rem;
      }
    }
  }
}
</style>