<template>
    <section v-if="message && value" class="individual-rating">
        <h4>X Ind Performance Modifier</h4>
        <div>
            <template v-if="tbd">
                <span>{{ message }}</span><span>X TBD</span>
            </template>
            <template v-else>
                <span>{{ message }}</span><span>X {{ value | percent(2) }}</span>
            </template>
        </div>
    </section>
</template>

<script>
export default {
    name: "IndividualRating",

    props: {
        tbd: {
            type: Boolean,
            default: true,
        },
        message: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        }
    },

    data() {
        return {}
    },

    computed: {},

    methods: {},
}
</script>

<style lang="scss" scoped>
.individual-rating {
    font-size: $text-sm;
    font-family: $font-secondary;
    font-weight: 800;

    margin-bottom: 1rem;

    div {
        display: flex;
        justify-content: space-between;

        span {

            &:last-child {
                font-size: $text-base;
                color: var(--highlight-color);
            }
        }

    }
}
</style>