<template>
    <div>
        <div class="goals-header">
            <h3><span>{{ unit.label }}</span> <span>{{ organization.label }}</span></h3>
            <div>Current: {{ unit.allocation.modifier | percent }}</div>
        </div>
        <div class="goals-body">
            <div v-for="(task, index) in unit.goals.tasks" :key="`goal-index-${index}`">
                <h4 class="goal-title">{{ task.label }}</h4>
                <table class="goal-table">
                    <thead>
                    <tr>
                        <th>Performance Scale</th>
                        <th>Weight</th>
                        <th>Final</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ task.notes }}</td>
                        <td>{{ task.weight | percent(2) }}</td>
                        <td>
                            <template v-if="task.achievement">{{ task.achievement | percent(2) }}</template>
                            <template v-else>TBD</template>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <table class="goals-footer">
            <tbody>
            <tr>
                <td><h3>Performance Total</h3></td>
                <td>{{ unit.goals.weight_total | percent(2) }}</td>
                <td>
                    {{ unit.goals.achievement_total | percent(2) }}
<!--                    <template v-if="!goals.tbd">{{ unit.achievement_total | percent(2) }}</template>-->
<!--                    <template v-else>TBD</template>-->
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "OrgUnitGoals",

    props: {
        unit: {
            type: Object,
            required: true,
        },
        organization: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {}
    },

    computed: {},

    methods: {},
}
</script>

<style lang="scss" scoped>
@import "../../../scss/_variables.scss";

.goals-header {
  display: flex;
  justify-content: space-between;

  font-size: 1.125em;
}

.goals-body {
    width: 100%;

    .goal-title {
        font-size: $text-base;
        font-weight: bold;

        margin: 0;
    }

    .goal-table {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;

        margin-bottom: 1.5em;

        thead {
            th {
                border: 1px solid $charcoal-tint-20;

                font-size: $text-base;
                font-weight: bold;
                color: $charcoal-tint-50;

                &:nth-child(1) {
                    width: 42%;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    width: 29%;
                }
            }
        }

        td {
            border: 1px solid $charcoal-tint-20;
            text-align: center;

            padding: 0.5em 1em;

            &:first-child {
                text-align: right;
                padding: 0.5em 2.5em;
            }

            &:nth-child(2),
            &:nth-child(3) {
                font-size: $text-md;
                font-weight: bold;

                color: var(--highlight-color);
            }
        }

        th:first-of-type {
            border-top-left-radius: 5px;
        }
        th:last-of-type {
            border-top-right-radius: 5px;
        }
        tr:last-of-type td:first-of-type {
            border-bottom-left-radius: 5px;
        }
        tr:last-of-type td:last-of-type {
            border-bottom-right-radius: 5px;
        }
    }
}

.goals-footer {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: none;

    tbody {
        td {
            &:nth-child(1) {
                width: 42%;
            }

            &:nth-child(2),
            &:nth-child(3) {
                width: 29%;

                text-align: center;

                font-size: $text-md;
                font-weight: bold;

                color: var(--highlight-color);;
            }
        }

        h3 {
            font-size: $text-lg;
            font-weight: bold;
            margin: 0;
        }
    }
}
</style>