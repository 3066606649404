<template>
	<router-link v-if="to !== null" :to="to" class="standard-button" :class="{'active':isActive,'light':isDisabled,'error':isError}"><slot></slot></router-link>
	<button v-else type="button" class="standard-button" :class="{'active':isActive,'light':isLight,'error':isError}" @click.prevent="clickButton">
		<slot></slot>
	</button>
</template>

<script>
export default {
	name: "StandardButton",
	props:{
		fieldName: {
			type: String,
			default: null
		},
		fieldValue: {
			type: String,
			default: null
		},
		isActive: {
			type: Boolean,
			default: false
		},
		isLight: {
			type: Boolean,
			default: false
		},
		isError: {
			type: Boolean,
			default: false
		},
		to: {
			type: String,
			default: null
		}
	},
	data() {
		return {}
	},
	mounted() {

	},
	methods: {
		clickButton(e){
			this.$emit("click", e, this.fieldValue);
		}
	}
}
</script>
<style lang="scss" scoped>
.standard-button{
	color: $color-background;
	background-color: #479dea;
	border: none;
	border-radius: 20em;
	font-size: 14px;
	padding: 8px 25px;
	cursor: pointer;
	text-decoration: none;
	font-family: $font-primary;
	letter-spacing: 0.08em;
	font-weight: 500;
	height: 36px;

	&.active{
		color: $color-background;
		background-color: $color-button-active;
		border: 2px solid $color-button-active;
	}
	&:hover{
		color: $color-background;
		background: linear-gradient(90deg, #479dea 31.5%, #de97ff);
	}
	&.light{
		color: $color-link;
		border: 2px solid #479dea;
		background-color: $color-background;
		position: relative;
		z-index: 1;

		&:hover{
			background: none;

			&:after{
				background: linear-gradient(90deg, #479dea 31.5%, #de97ff);
				border-radius: 20em;
				content: "";
				display: block;
				height: calc(100% + 4px);
				position: absolute;
				width: calc(100% + 4px);
				z-index: -1;
				top: -2px;
				left: -2px;
			}
		}
	}
}
</style>