<template>
  <plan-card>
    <div v-if="isSplitFunding" class="split-scale">
      <div>
        <h3>Allocation Percentage Breakdown</h3>
      </div>

      <div class="allocation-row">
        <div class="allocation-row-label">
          <span>{{ selected.client.allocation.weight | percent }}</span>
          <span>Company Allocation</span>
        </div>
        <div>
          <div class="allocation-bar" ref="company"></div>
        </div>
      </div>

      <div v-for="unit in orgUnits" :key="unit.id" class="allocation-row">
        <div class="allocation-row-label">
          <span>{{ unit.allocation.weight | percent }}</span>
          <span>{{ unit.label }} Allocation</span>
        </div>
        <div>
          <div class="allocation-bar" :ref="`unit_${unit.id}`"></div>
        </div>
      </div>

      <div v-if="employee.performance.mode === 'as-component'" class="allocation-row">
        <div class="allocation-row-label">
          <span>{{ employee.allocation.weight | percent }}</span>
          <span>Individual Allocation</span>
        </div>
        <div>
          <div class="allocation-bar" ref="employee"></div>
        </div>
      </div>

      <div class="allocation-row">
        <div class="allocation-row-label">
          <span>100%</span>
          <span>Total Allocation</span>
        </div>
        <div>
          <div class="allocation-total"></div>
        </div>
      </div>
    </div>
    <forecast-scale v-else
        :tiers="selected.tiers" :forecast="selected.forecast" :client="selected.client"
        :distributions="selected.distributions" :isEos="selected.plan.isEos"
    />
  </plan-card>
</template>

<script>
import PlanCard from "@/components/plans/global/PlanCard.vue";
import ForecastScale from "@/components/plans/short-term/scale/ForecastScale.vue";
import UtilsMixin from "@/mixins/UtilsMixin";
import ColorsMixin from "@/mixins/ColorsMixin";
// import ForecastCompanyDetails from "@/components/plans/short-term/scale/ForecastCompanyDetails.vue";

export default {
  name: "PlanScale",

  mixins: [ UtilsMixin, ColorsMixin ],

  components: {
    PlanCard,
    ForecastScale,
  },

  props: {
    selected: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    isSplitFunding() {
      return this.selected?.plan?.funding_mode === 'split';
    },

    orgUnits() {
      return Object.values(this.selected.organization.units);
    },

    employee() {
      return this.selected.employee;
    },
  },

  methods: {
    updateClientColors() {
      // TODO: rework this once a state management system is in place
      let highlight = this.getHighlightColor();

      this.$el.style.setProperty('--highlight-color', highlight)

      let tint = 0.8

      let brandColor = this.getBrandColor();
      let brandColorTint = this.getBrandTintColor(tint);

       this.$el.style.setProperty('--brand-color', brandColor)
       this.$el.style.setProperty('--brand-color-tint', brandColorTint)

      let foregroundColor = this.getForegroundColor();

       this.$el.style.setProperty('--foreground-color', foregroundColor)
    },

    updateWidth() {
      this.$refs.company.style.width = `${this.selected.client.allocation.weight / 100000}%`;

      this.orgUnits.forEach((unit) => {
        this.$refs[`unit_${unit.id}`][0].style.width = `${unit.allocation.weight / 100000}%`;
      })

      this.$refs.employee.style.width = `${this.employee.allocation.weight / 100000}%`;
    },
  },

  watch: {
    clientStyles: function() {
      this.updateClientColors()
    }
  },

  mounted() {
    this.updateClientColors()
    this.updateWidth()
  },

  created() {},
}
</script>

<style lang="scss" scoped>
.company-details {
  border-top: thin solid $color-card-border;
}

.split-scale {
  width:100%;
  padding: 1rem 2rem;
}

.allocation-row {
  width:100%;

  display:grid;
  grid-template-columns:1fr 5fr;
  column-gap:0.5em;

  margin-bottom:0.5em;
}

.allocation-row-label {
  display:flex;
  flex-direction:column;
  grid-column:span 1;
  text-align:left;
}

.allocation-bar {
  background-color: var(--brand-color);

  height: 100%;

  border-top-right-radius: 0.1875em;
  border-bottom-right-radius: 0.1875em;
}

.allocation-total {
  background-color: var(--highlight-color);

  width: 100%;
  height: 100%;

  border-top-right-radius: 0.1875em;
  border-bottom-right-radius: 0.1875em;
}
</style>