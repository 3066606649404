<template>
  <div class="badge-wrapper">
    <slot name="default"></slot>
    <button v-if="id" :id="id" class="question" :class="[{classname: classname}]" :style="wrappingStyle">?</button>
    <button v-else class="question" :class="[{classname: classname}]" :style="wrappingStyle">?</button>
  </div>
</template>

<script>

export default {
  name: "QuestionBadge",

  props: {
    id: {
      type: String,
      required: false
    },
    classname: { // may want to add this if there will be multiple QuestionBadges on the page where you want to provide the same Pendo response
      type: String,
      required: false
    },
    top: {
      type: Number,
      default: 2
    },
    right: {
      type: Number,
      default: -25
    }
  },

  computed: {
    slotEmpty() {
      return this.$slots.default === undefined
    },
    wrappingStyle() {
      return (this.slotEmpty ? '' : `position: absolute; top: ${this.top}px; right: ${this.right}px;`);
    }
  },

  methods: {},

  created() {},

  mounted() {}
}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

.badge-wrapper {
  display: inline-block;
  position: relative;
}

.question {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $charcoal;
  color: $pure-white;

  position: absolute;
  top: -0.25rem;
  right: -1.25rem;

  font-family: $font-primary;
  font-size: 0.75rem;

  height: 1rem;
  width: 1rem;

  border-radius: 15rem;

  &:hover {
    cursor: pointer;
  }
}
</style>
