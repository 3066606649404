<template>
    <div class="chart-wrapper">
        <div class="line-chart">
            <div>
                <g-chart type="LineChart" :data="chartData" :options="chartOptions"/>
            </div>
            <div class="gridlines">
                <span class="superior"></span>
                <span class="target"></span>
                <span class="base"></span>
            </div>
        </div>
        <div class="y-axis">
            <span class="superior">{{ tiers.superior.label }}</span>
            <span class="target">{{ tiers.target.label }}</span>
            <span class="base">{{ tiers.base.label }}</span>
        </div>
        <div class="x-axis">
            <span class="q0">START</span>
            <span class="q1">Q1</span>
            <span class="q2">Q2</span>
            <span class="q3">Q3</span>
            <span class="q4">END OF YEAR</span>
        </div>
    </div>
</template>

<script>
import {GChart} from 'vue-google-charts/legacy'
import Moment from 'moment';

export default {
    name: "LineChart",

    components: {
        GChart,
    },

    props: {
        dates: {
            type: Object,
            required: true,
        },
        history: {
            type: Array,
            required: true,
        },
        tiers: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            chartData: [
                ['date', 'value', {'type': 'string', 'role': 'tooltip'}, {type: 'string', role: 'style'}],
            ],

            chartOptions: {
                backgroundColor: {fill: 'transparent'},
                chartArea: {left: 6, top: 15, bottom: 15, width: `100%`, height: '100%'},

                interpolateNulls: true,

                legend: {position: 'none'},

                lineWidth: 20,

                colors: ['#071820'],

                allowContainerBoundaryTextCutoff: true,

                series: {
                    0: {targetAxisIndex: 1, lineDashStyle: [12, 4], pointSize: 10, lineWidth: 3},
                },

                vAxes: {
                    0: {},
                },

                hAxis: {
                    ticks: [],
                    gridlines: {
                        color: 'transparent'
                    },
                    viewWindow: {
                        min: Moment(this.dates.start, 'YYYY-MM-DD').toDate(),
                        max: Moment(this.dates.end, 'YYYY-MM-DD').toDate(),
                    },
                },

                vAxis: {
                    ticks: [
                        {v: 2, f: 'Base'},
                        {v: 4, f: 'Target'},
                        {v: 6, f: 'Superior'},
                    ],
                    gridlines: {
                        color: 'transparent'
                    },
                    viewWindow: {
                        min: 1,
                        max: 7,
                    },
                    baselineColor: 'transparent'
                },

            },
        }
    },

    computed: {
        clientStyles() {
            if (this.$dataDirectory?.userData?.client_styles) return JSON.parse(this.$dataDirectory.userData.client_styles)
            else return null
        },
        planYear() {
            return Moment(this.dates.start, 'YYYY-MM-DD').toDate().getFullYear()
        },
        pointColor() {
            if(this.clientStyles) {
                let r = this.clientStyles.heading_background_red
                let g = this.clientStyles.heading_background_green
                let b = this.clientStyles.heading_background_blue

                let heading_color = this.RGBToHex(r, g, b)

                return `point { fill-color: ${heading_color}; }`
            }
            return `point { fill-color: #ff7d01; }`
        },
    },

    methods: {
        buildChartData() {
            let start_date = Moment(this.dates.start, 'YYYY-MM-DD').toDate()
            this.chartData.push([start_date, 4, `Date: ${start_date.toLocaleDateString()}`, this.pointColor])

            this.history.forEach((item) => {
                let date = Moment(item.date, 'YYYY-MM-DD').toDate()
                let tooltip = `Date: ${date.toLocaleDateString()}` // \nForecast: $${item.forecast}`

                this.chartData.push([date, item.zone, tooltip, this.pointColor])
            })
        },

        // TODO: move this to a helper or service class
        RGBToHex(r, g, b) {
            let hex_r = Math.round(r).toString(16).padStart(2, '0')
            let hex_g = Math.round(g).toString(16).padStart(2, '0')
            let hex_b = Math.round(b).toString(16).padStart(2, '0')

            return `#${hex_r}${hex_g}${hex_b}`
        },
    },

    mounted() {
        this.buildChartData()
    },

    watch: {
        clientStyles: {
            handler: function () {
                this.buildChartData()
            },
            deep: true
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables";

.superior,
.target,
.base {
    text-transform: capitalize;
}

.superior {
    top: 17%;
}

.target {
    top: 45%;
}

.base {
    top: 73%
}

.chart-wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    width: 100%;

    .line-chart {
        grid-column: span 11;

        position: relative;
        z-index: 10;

        min-height: 170px;

        .gridlines {
            position: absolute;
            z-index: -10;

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            .superior, .target, .base {
                position: absolute;

                left: 0;

                height: 20px;
                width: 100%;

                border-radius: 0 5px 5px 0;

                background-color: $charcoal-tint-20;
            }
        }
    }

    .y-axis {
        grid-column: span 1;

        position: relative;

        width: 80px;

        margin-left: 30px;

        .superior, .target, .base {
            position: absolute;

            left: 0;
        }
    }

    .x-axis {
        grid-column: span 11;

        position: relative;

        .q0, .q1, .q2, .q3, .q4, {
            position: absolute;
            top: 0;
        }

        .q0 {
            left: 0;
        }

        .q1 {
            left: 25%;
            transform: translateX(-50%);
        }

        .q2 {
            left: 50%;
            transform: translateX(-50%);
        }

        .q3 {
            left: 75%;
            transform: translateX(-50%);
        }

        .q4 {
            right: 0;
        }
    }
}
</style>