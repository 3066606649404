<template>
	<div v-if="plan" class="plan">
		<svg class="plan-control" :class="{expanded:expanded}" @click="toggle" xmlns="http://www.w3.org/2000/svg" width="12" height="7.41" viewBox="0 0 12 7.41">
			<path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M7.41,7.84,12,12.42l4.59-4.58L18,9.25l-6,6-6-6Z" transform="translate(-6 -7.84)" fill="#1f3a4d"/>
		</svg>
		<h3 class="plan-name" @click="toggle">{{ plan.planName }}</h3>
		<h4 class="plan-title">{{ plan.title }}</h4>
		<div class="plan-values">
			<div>
				<h4>Base</h4>
				<br-dollars>{{ $accounting.formatCents(plan.base) }}</br-dollars>
			</div>
			<div>
				<h4>Target</h4>
				<br-dollars>{{ $accounting.formatCents(plan.target) }}</br-dollars>
			</div>
			<div>
				<h4>Superior</h4>
				<br-dollars>{{ $accounting.formatCents(plan.superior) }}</br-dollars>
			</div>
			<div class="plan-values-paid" :class="{green:greenTotal}">
				<h4>{{ plan.isCurrent ? 'Forecasted Award' : 'Paid' }}</h4>
				<br-dollars>{{ $accounting.formatCents(plan.payout) }}</br-dollars>
			</div>
		</div>
		<div ref="details" class="plan-details">
			<!--p class="uk-margin-medium-bottom uk-margin-medium-top">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
				dolore magna aliqua. Ut enim ad minim veniamquis nostrud exercitation ullamco laboris.</p-->
			<div class="plan-details-summaries">
				<div>
					<h4>Plan Summary</h4>
					<p>{{ plan.summary }}</p>
					<a class="plan-details-summaries-link" :href="plan.summaryLink" target="_blank">View My Plan Summary</a>
				</div>
				<div>
					<h4>My Plan Statement</h4>
					<p>{{ plan.statement }}</p>
					<a class="plan-details-summaries-link" :href="plan.statementLink" target="_blank">View My Plan Statement</a>
					<old-statements style="float: right;" :link-list="plan.olderStatements"></old-statements>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BrDollars from "@/components/elements/BrDollars";
import OldStatements from "./OldStatements";
export default {
	name: "ShortPlanBox",
	components:{
		BrDollars,
		OldStatements
	},
	props:{
		plan:{
			type:Object,
			default: null
		},
		greenTotal:{
			type:Boolean,
			default: true
		},
		startOpen:{
			type:Boolean,
			default: false
		}
	},
	data() {
		return {
			example:{
				planName:"2020 Value-Sharing Plan",
				title:"Outline",
				isCurrent:true,
				base:10000,
				target:15000,
				superior:20000,
				payout:18000,
				summaryLink:"https://upload.wikimedia.org/wikipedia/commons/a/a4/Placeholder.pdf",
				statementLink:"https://upload.wikimedia.org/wikipedia/commons/a/a4/Placeholder.pdf",
				summary:"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
				statement:"This Summary provides certain details related to your bonus opportunity at 3Sixty Integrated. The plan allows you to earn cash awards based on the achievement of specific plan goals.  If an award is earned, it will likely fall within the ranges shown below."
			},
			expanded: false,
			planHeight: 0
		}
	},
	mounted() {
		setTimeout(() => this.dataSet(),5);
	},
	methods: {
		toggle(){
			this.expanded = !this.expanded;
			if(this.expanded){
				this.$refs.details.style.height = this.planHeight + "px";
			} else {
				this.$refs.details.style.height = 0 + "px";
			}
		},
		dataSet(){
			if(!this.$refs.details){
				return;
			}
			this.planHeight = this.$refs.details.getBoundingClientRect().height;
			this.$refs.details.style.height = "0px";
			this.$refs.details.style.transition = "0.5s";
			if(this.startOpen){
				this.toggle();
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.plan{
	background-color: $color-background;
	border: thin solid $color-divider;
	border-radius: 8px;
	margin-bottom: 15px;
	padding: 13px 16px;
	position: relative;

	&-name{
		cursor: pointer;
	}

	&-control{
		cursor: pointer;
		position: absolute;
		right: 16px;
		top: 13px;
		transition: 0.5s;

		&.expanded{
			transform: rotate(180deg);
		}
	}

	&-values{
		display: grid;
		grid-template-columns: 20% 20% 20% 20%;
		grid-column-gap: 5%;
		h4{
			color: $color-text-secondary;
		}
		&>div{
			padding: 13px 1.5vw;
			&:first-child{
				padding-left: 0;
			}
		}
		&-paid{
			&.green{
				background-color: rgba(134, 198, 6, 0.16);
			}
			background-color: rgba(222, 222, 222, 0.25);
		}
	}

	&-details{
		overflow: hidden;
		&-summaries{
			border-top: thin solid $color-divider;
			display: grid;
			grid-template-columns: 49% 49%;
			grid-column-gap: 2%;
			padding-top: 20px;
			&-link{
				display: inline-block;
				margin-top: 20px;
			}
		}
	}
}
</style>
