<template>
	<span class="dollar-field">
		<span class="dollar-field-currency">$</span><slot></slot>
	</span>
</template>

<script>
export default {
	name: "BrDollars",
	data() {
		return {}
	},
	mounted() {

	},
	methods: {}
}
</script>
<style lang="scss" scoped>
	.dollar-field{
		color: $color-header-primary;
		font-size: 32px;
		font-family: $font-secondary;
		font-weight: 800;
		
		transition: 0.5s;

		&-currency{
			font-size: .5em;
			position: relative;
			top: -0.7em;
		}
		@media screen and (max-width: 1100px){
			font-size: 24px;
		}
	}
</style>
