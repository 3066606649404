<template>
    <div class="summary-wrap">
        <slot name="details">
            <plan-details :summary="summary" :plan="plan"/>
        </slot>
        <slot name="metrics">
            <plan-metrics v-if="hasIndividualPerformance || hasOrganizationMetrics" :organization="organization" :individual="individual" :plan="plan" :distributions="distributions"/>
        </slot>
    </div>
</template>

<script>
import PlanDetails from "@/components/plans/short-term/summary/PlanDetails.vue";
import PlanMetrics from "@/components/plans/short-term/summary/PlanMetrics.vue";

export default {
    name: "PlanSummary",

    components: {
        PlanDetails,
        PlanMetrics,
    },

    props: {
        summary: {
            type: Object,
            required: true,
        },
        individual: {
            type: [Object, null],
            default: null
        },
        organization: {
            type: [Object, null],
            default: null
        },
        plan: {
            type: Object,
            required: false,
        },
        distributions: {
            type: Array,
            required: false,
        },
    },

    computed: {
        hasIndividualPerformance() {
            return !!this.individual?.performance?.goals || !!this.individual?.performance?.rating
        },
        hasOrganizationMetrics() {
            if(!this.organization?.units) return false
            return Object.values(this.organization?.units).length > 0
        },
    },
}
</script>

<style lang="scss" scoped>
.summary-wrap {
    display: grid;

    grid-template-columns: repeat(2, 1fr);

    grid-column-gap: 2.1334rem;

    margin-top: 80px;

    .details {
        width: 100%;
    }

    .metrics {
        width: 100%;
    }
}
</style>