<template>
	<div id="app">
		<router-view ref="header" name="header" />
		<div class="main-body">
			<router-view ref="navigation" name="navigation" />
			<router-view ref="page" class="page" />
		</div>
	</div>
</template>

<script>

import UtilsMixin from "@/mixins/UtilsMixin";
import ColorsMixin from "@/mixins/ColorsMixin";

export default {
	name: 'App',

  mixins: [ UtilsMixin, ColorsMixin ],

	components: {},

  computed: {
    clientStyles() {
      if (this.userData?.client_styles) return JSON.parse(this.userData.client_styles)
      else return null
    },
  },

  methods: {
    updateClientColors() {
      // TODO: rework this once a state management system is in place
      let highlight = this.getHighlightColor();

      let root_element = document.querySelector(':root')
      root_element.style.setProperty('--highlight-color', highlight)

      let tint = 0.8

      let brandColor = this.getBrandColor();
      let brandColorTint = this.getBrandTintColor(tint);

      root_element.style.setProperty('--brand-color', brandColor)
      root_element.style.setProperty('--brand-color-tint', brandColorTint)

      let foregroundColor = this.getForegroundColor();

      root_element.style.setProperty('--foreground-color', foregroundColor)
    },
  },

  watch: {
    clientStyles: {
      handler: function () {
        this.updateClientColors()
      },
      deep: true
    }
  },

  mounted() {
    this.updateClientColors()
  },
}
</script>

<style lang="scss">
@import "scss/_variables.scss";

*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
#app {
	font-family: $font-secondary;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $color-text;
	background-color: $color-background;

	.uk-modal-dialog{
		border-radius: 8px;
	}
}
.main-body{
	display: grid;
	grid-template-columns: 20% 80%;

	.page{
		padding: 4vh 2vw;
		background-color: $color-background-primary;
	}

	h1, h2, h3, h4, h5, h6{
		margin: 0;
		font-weight: 500 !important;
		font-family: $font-primary;
		color: $color-header-primary;
		text-transform: none;
	}
	p, ul, ol, dl, pre, address, fieldset, figure {
		margin: 0;
	}
	h1{
		color: $color-header-primary;
		margin-bottom: 3vw;
		font-size: 32px;
		&.muted{
			color: $color-text-secondary;
		}
	}
	h2{
		color: $color-header-primary;
		margin-bottom: 0.5vw;
		font-size: 20px;
		&.muted{
			color: $color-text-secondary;
		}
	}
	h3{
		color: $color-header-primary;
		font-size: 14px;
		margin-bottom: 0.5vw;
		&.muted{
			color: $color-text-secondary;
		}
	}
	h4{
		color: $color-header-primary;
		font-size: 13px;
		margin-bottom: 0.5vw;
		&.muted{
			color: $color-text-secondary;
		}
	}
	h5{
		color: $color-header-primary;
		font-size: 13px;
		margin-bottom: 0.5vw;
		&.muted{
			color: $color-text-secondary;
		}
	}

	a,.link{
		color: $color-link;
		background: none;
		border: none;
		text-decoration: underline;
		cursor: pointer;
		font-size: 12px;
		font-family: $font-primary;

		&:hover{
			color: $color-link-hover;
		}
	}
}

</style>
